function convWashReq_(e, i, now) {
  e.dispNum = i + 1

  e.pickupChangeBtText = '変更'
  e.deliveryChangeBtText = '変更'

  const pickupDt = this.dateStrToDate_(e.sched_pickup_dt)
  const deliveryDt = this.dateStrToDate_(e.sched_delivery_dt)
  const pickupHourDiff = (pickupDt.valueOf() - now.valueOf()) / 1000 / 3600
  const deliveryHourDiff = (deliveryDt.valueOf() - now.valueOf()) / 1000 / 3600

  // Business Rule No.21 変更/キャンセルは3時間前まで可能　-> 2時間に変更,キャンセル期限無し-> 変更もキャンセルも2時間に変更
  // Business Rule No.24 集荷済みのときはキャンセルボタンを消すまたは押せないようにする
  e.canCancel = !e.result_pickup_dt && pickupHourDiff > 2
  e.canChangePickupDt = !e.result_pickup_dt && pickupHourDiff > 2
  e.canChangeDeliveryDt = !e.result_delivery_dt && deliveryHourDiff > 2
  if (e.result_pickup_dt) {
    e.pickupChangeBtText = '集荷済'
  }

  // 単発プランの場合変更、キャンセルボタンは押せない
  if (e.plan_id.split('_')[0] == 'tx') {
    e.canCancel = false
    e.canChangePickupDt = false
    e.canChangeDeliveryDt = false
  }

  userApi.getMyCurrentPlan().then(({ data }) => {
    // 月途中にプラン変更（通常・集配同時）した場合に、
    // 現在プランが最終変更プランとなってしまうため、
    // 集配同時プランかどうかの判断は一時的にこっちを使用する。後で見直す
    // 集配同時の場合、3番目のIDをつけている
    const washReqDate = new Date(e.created_at)
    const washReqMonth = washReqDate.getMonth() + 1
    const now = new Date()
    const month = now.getMonth() + 1
    if(washReqMonth === month) {
      e.plan_id = data.plan_id
    }
  })

  return e
}

function getWashRequestCurrents(orig) {
  const now = new Date()
  return orig.filter(e => {
    // まだ配達が終わってないやつ
    return !e.result_delivery_dt

  }).sort((a, b) => {
    const aVal1 = a.sched_pickup_dt
    const bVal1 = b.sched_pickup_dt
    if (aVal1 > bVal1) {
      return -1;
    } else if (aVal1 < bVal1) {
      return 1;
    } else {
      const aVal2 = a.sched_delivery_dt
      const bVal2 = b.sched_delivery_dt
      return aVal2 > bVal2 ? -1 :
        aVal2 < bVal2 ? 1 : 0;
    }
  }).map((e, i) => {
    return this.convWashReq_(e, i, now)
  })
}

import Vue from 'vue'
import userApi from '@/api/user'
export default {
  created() {
    this.dateStrToDate_ = Vue.filter('dateStrToDate')
  },
  methods: {
    convWashReq_,
    getWashRequestCurrents,
  }
}
