<template>
<div class="contents">

  <div class="list-box">
    <div class="err-msg-area">
      <p><span class="font_orange" v-for="e in serverErrors">
        {{e}}
      </span></p>
    </div>
    <table class="tbl">
      <colgroup>
        <col style="width:8%;">
        <col style="width:5%;">
        <col style="width:5%;">
        <col style="width:5%;">
        <col style="width:10%;">
        <col style="width:10%;">
        <col style="width:10%;">
        <col style="width:10%;">
        <col style="width:7%;">
      </colgroup>
      <tr>
        <th>操作</th>
        <th>月額</th>
        <th>単発</th>
        <th>郵便番号</th>
        <th>都道府県</th>
        <th>市区町村</th>
        <th>集配時間（開始）</th>
        <th>集配時間（終了）</th>
        <th>備考</th>
      </tr>
      <tr>
        <td>
          <div v-show="!newAreaInfo.input_mode">
            <button class="btn btn_sm bg_green"
              @click="switchToInputMode(newAreaInfo)">
              追加
            </button>
          </div>
          <div v-show="newAreaInfo.input_mode">
            <div style="margin:4px 0 0 0;">
              <button class="btn btn_sm bg_danger"
                  @click="update(newAreaInfo)">
                確定
              </button>
              <button class="btn btn_sm bg_blue"
                  @click="switchToNonInputMode(newAreaInfo)">
                キャンセル
              </button>
            </div>
          </div>
        </td>
        <td>
          <div v-show="!newAreaInfo.input_mode"></div>
          <div v-show="newAreaInfo.input_mode">
            <select v-model="newAreaInfo.input_mode_info.is_valid_monthly" style="width:70px;">
              <option v-for="(elem, i) in validInfo" v-bind:value="i">
                {{elem}}
              </option>
            </select>
          </div>
        </td>
        <td>
          <div v-show="!newAreaInfo.input_mode"></div>
          <div v-show="newAreaInfo.input_mode">
            <select v-model="newAreaInfo.input_mode_info.is_valid_onetime" style="width:70px;">
              <option v-for="(elem, i) in validInfo" v-bind:value="i">
                {{elem}}
              </option>
            </select>
          </div>
        </td>
        <td>
          <div v-show="!newAreaInfo.input_mode"></div>
          <div v-show="newAreaInfo.input_mode">
            <input type="text" class="form-control-sm" size="5" maxlength="5"
              v-model="newAreaInfo.input_mode_info.zipcode">
          </div>
        </td>
        <td>
          <div v-show="!newAreaInfo.input_mode"></div>
          <div v-show="newAreaInfo.input_mode">
            <input type="text" class="form-control-sm" size="15"
              v-model="newAreaInfo.input_mode_info.prefecture">
          </div>
        </td>
        <td>
          <div v-show="!newAreaInfo.input_mode"></div>
          <div v-show="newAreaInfo.input_mode">
            <input type="text" class="form-control-sm" size="15"
              v-model="newAreaInfo.input_mode_info.address">
          </div>
        </td>
        <td>
          <div v-show="!newAreaInfo.input_mode"></div>
          <div v-show="newAreaInfo.input_mode">
            <select v-model="newAreaInfo.input_mode_info.start_time_hour" @change="setStartMinutes(newAreaInfo)" style="width:60px;">
              <option v-for="elem in startHours" v-bind:value="elem.val">
                {{elem.disp}}
              </option>
            </select>
            ：
            <select v-model="newAreaInfo.input_mode_info.start_time_minute" style="width:60px;">
              <option v-for="elem in startMinutes" v-bind:value="elem.val">
                {{elem.disp}}
              </option>
            </select>
          </div>
        </td>
        <td>
          <div v-show="!newAreaInfo.input_mode"></div>
          <div v-show="newAreaInfo.input_mode">
            <select v-model="newAreaInfo.input_mode_info.end_time_hour" @change="setDeliveryMinutes(newAreaInfo)" style="width:60px;">
              <option v-for="elem in deliveryHours" v-bind:value="elem.val">
                {{elem.disp}}
              </option>
            </select>
            ：
            <select v-model="newAreaInfo.input_mode_info.end_time_minute" style="width:60px;">
              <option v-for="elem in deliveryMinutes" v-bind:value="elem.val">
                {{elem.disp}}
              </option>
            </select>
          </div>
        </td>
        <td>
          <span>無し</span>
          <button v-if="newAreaInfo.input_mode" class="btn btn_sm bg_blue fr"
            @click="openManagementNotesModal(newAreaInfo, false)">
              入力
          </button>
        </td>
      </tr>
      <tr v-for="(areaInfo, idx) in areaInfos">
        <td>
          <div v-show="!areaInfo.input_mode">
            <button class="btn btn_sm bg_danger"
              @click="deleteInfo(areaInfo)">
              削除
            </button>
            <button class="btn btn_sm bg_blue"
              @click="switchToInputMode(areaInfo)">
              編集
            </button>
          </div>
          <div v-show="areaInfo.input_mode">
            <div style="margin:4px 0 0 0;">
              <button class="btn btn_sm bg_danger"
                  @click="update(areaInfo)">
                確定
              </button>
              <button class="btn btn_sm bg_blue"
                  @click="switchToNonInputMode(areaInfo)">
                キャンセル
              </button>
            </div>
          </div>
        </td>
        <td>
          <div v-show="!areaInfo.input_mode"><span>{{areaInfo.is_valid_monthly == 1 ? 'あり' : 'なし' }}</span></div>
          <div v-show="areaInfo.input_mode">
            <select v-model="areaInfo.input_mode_info.is_valid_monthly" style="width:70px;">
              <option v-for="(elem, i) in validInfo" v-bind:value="i">
                {{elem}}
              </option>
            </select>
          </div>
        </td>
        <td>
          <div v-show="!areaInfo.input_mode"><span>{{areaInfo.is_valid_onetime == 1 ? 'あり' : 'なし' }}</span></div>
          <div v-show="areaInfo.input_mode">
            <select v-model="areaInfo.input_mode_info.is_valid_onetime" style="width:70px;">
              <option v-for="(elem, i) in validInfo" v-bind:value="i">
                {{elem}}
              </option>
            </select>
          </div>
        </td>
        <td>
          <div v-show="!areaInfo.input_mode"><span>{{areaInfo.zipcode}}</span></div>
          <div v-show="areaInfo.input_mode">
            <input type="text" class="form-control-sm" size="5" maxlength="5"
              v-model="areaInfo.input_mode_info.zipcode">
          </div>
        </td>
        <td>
          <div v-show="!areaInfo.input_mode"><span>{{areaInfo.prefecture}}</span></div>
          <div v-show="areaInfo.input_mode">
            <input type="text" class="form-control-sm" size="15"
              v-model="areaInfo.input_mode_info.prefecture">
          </div>
        </td>
        <td>
          <div v-show="!areaInfo.input_mode"><span>{{areaInfo.address}}</span></div>
          <div v-show="areaInfo.input_mode">
            <input type="text" class="form-control-sm" size="15"
              v-model="areaInfo.input_mode_info.address">
          </div>
        </td>
        <td>
          <div v-show="!areaInfo.input_mode"><span>{{areaInfo.start_time}}</span></div>
          <div v-show="areaInfo.input_mode">
            <select v-model="areaInfo.input_mode_info.start_time_hour" @change="setStartMinutes(areaInfo)" style="width:60px;">
              <option v-for="elem in startHours" v-bind:value="elem.val">
                {{elem.disp}}
              </option>
            </select>
            ：
            <select v-model="areaInfo.input_mode_info.start_time_minute" style="width:60px;">
              <option v-for="elem in startMinutes" v-bind:value="elem.val">
                {{elem.disp}}
              </option>
            </select>
          </div>
        </td>
        <td>
          <div v-show="!areaInfo.input_mode"><span>{{areaInfo.end_time}}</span></div>
          <div v-show="areaInfo.input_mode">
            <select v-model="areaInfo.input_mode_info.end_time_hour" @change="setDeliveryMinutes(areaInfo)" style="width:60px;">
              <option v-for="elem in deliveryHours" v-bind:value="elem.val">
                {{elem.disp}}
              </option>
            </select>
            ：
            <select v-model="areaInfo.input_mode_info.end_time_minute" style="width:60px;">
              <option v-for="elem in deliveryMinutes" v-bind:value="elem.val">
                {{elem.disp}}
              </option>
            </select>
          </div>
        </td>
        <td>
          <span v-if="areaInfo.bikou">有り</span>
          <span v-else>無し</span>
          <button v-if="areaInfo.input_mode" class="btn btn_sm bg_blue fr"
            @click="openManagementNotesModal(areaInfo, false)">
              入力
          </button>
          <button v-if="!areaInfo.input_mode && areaInfo.bikou" class="btn btn_sm bg_blue fr"
            @click="openManagementNotesModal(areaInfo)">
              確認
          </button>
        </td>
      </tr>
    </table>
  </div>

  <modal
    val="oneAreaInfo"
    v-if="showManagementNotesModalView"
    title="備考"
    bottomDismissButtonLabel="閉じる"
    :showBottomCloseButton=false
    @dismiss="cancelManagementNotesView()">
    <div slot="body">
      <p class="nl2br">{{oneAreaInfo.bikou}}</p>
    </div>
  </modal>
  <modal
    val="oneAreaInfo"
    v-if="showManagementNotesModal"
    title="備考"
    bottomCloseButtonLabel="OK"
    bottomDismissButtonLabel="リセット"
    @close="saveManagementNotes()"
    @dismiss="resetManagementNotes()">
    <div slot="body">
      <p><textarea type="text" class="form_basic" v-model="oneAreaInfo.input_mode_info.bikou"></textarea></p>
    </div>
  </modal>

</div>
</template>

<script>
import Vue from 'vue'
import masterApi from '@/api/master'
import adminAreaInfoApi from '@/api/adminAreaInfo'

export default {
  name: 'admin-area-infos',
  props: {
    adminType: {
      type: String,
      default: 'admin'
    }
  },
  data() {
    return {
      areaInfos: [],

      startHours: window.VueHourOptions,
      startMinutes: this.setMinuteOption([0,30]),
      deliveryHours: window.VueHourOptions,
      deliveryMinutes: this.setMinuteOption([0,30]),
      hours: window.VueHourOptions,
      minutes: this.setMinuteOption([0,30]),
      minutes2: this.setMinuteOption([0]),

      showManagementNotesModal: false,
      showManagementNotesModalView: false,
      inputMode: false,
      oneAreaInfo: {},
      newAreaInfo: {
        zipcode: null,
        prefecture: null,
        address: null,
        start_time: null,
        start_time_hour: 10,
        start_time_minute: 0,
        end_time_hour: 10,
        end_time_minute: 0,
        end_time: null,
        is_valid_monthly: 1,
        is_valid_onetime: 1,
        bikou: null,
        input_mode: false,
        input_mode_info: {},
        is_new: true,
      },

      validInfo: [
        'なし', 'あり'
      ],
      roleType: 0,
      serverErrors: [],
    }
  },
  mounted() {
    window.master.$promise.then(mst => {
    this.$store.dispatch('user/getMe').then(me => {
      this.roleType = me.role_type;
    })

      // メンテナンス中は管理者とマネージャ以外アクセス不可
      masterApi.maintenanceIndex().then(({ data }) => {
        const maint_flg = data.maint_flg
        if (maint_flg === 1 && this.adminType === 'staff' && this.roleType !== 1) {
          this.$router.push({name: 'maintenance'})
        }
      })
      // 管理者とマネージャー以外アクセス不可
      if (this.adminType === 'staff' && this.roleType !== 1) {
        this.$router.push({ name: 'staff-top' })
      }
      this.getAreaInfos()
      this.resetNewInput()
    })
  },
  methods: {
    setMinuteOption(key) {
      if (!key) {
        return window.VueMinuteOptions
      }
      let filteredOptions = window.VueMinuteOptions.filter(option => key.includes(option.val));
      return filteredOptions
    },
    convAreaInfo(areaInfo) {
      const ret = Object.assign({}, areaInfo)

      ret.start_time = areaInfo.start_time.slice(0, 5)
      let sTimes = ret.start_time.split(':')
      areaInfo.start_time_hour = sTimes[0]
      areaInfo.start_time_minute = sTimes[1] == 0 ? 0 : sTimes[1]
      ret['start_time_hour'] = sTimes[0]
      ret['start_time_minute'] = sTimes[1] == 0 ? 0 : sTimes[1]

      ret.end_time = areaInfo.end_time.slice(0, 5)
      let dTimes = ret.end_time.split(':')
      areaInfo.end_time_hour = dTimes[0]
      areaInfo.end_time_minute = dTimes[1] == 0 ? 0 : dTimes[1]
      ret['end_time_hour'] = dTimes[0]
      ret['end_time_minute'] = dTimes[1] == 0 ? 0 : dTimes[1]

      ret['input_mode'] = false
      ret['input_mode_info'] = areaInfo
      ret['is_new'] = false

      return ret
    },
    getAreaInfos() {
      adminAreaInfoApi.index().then(({ data }) => {
        this.areaInfos = data
          .map(e => this.convAreaInfo(e))
      })
    },
    openManagementNotesModal(areaInfo, view = true) {
      if (view) {
        if (this.inputMode) {
          return
        }
        this.oneAreaInfo = areaInfo
        this.showManagementNotesModalView = true
      } else {
        this.showManagementNotesModal = true
      }
    },
    saveManagementNotes() {
      this.showManagementNotesModal = false
    },
    resetManagementNotes() {
      this.showManagementNotesModal = false
      if (this.oneAreaInfo.is_new) {
        this.oneAreaInfo.input_mode_info.bikou = null
      } else {
        this.oneAreaInfo.input_mode_info.bikou = this.oneAreaInfo.bikou
      }
    },
    cancelManagementNotesView() {
      this.oneAreaInfo = {}
      this.showManagementNotesModalView = false
    },
    switchToInputMode(areaInfo) {
      if (this.inputMode) {
        return
      }
      if (areaInfo.is_new) {
        areaInfo.input_mode_info = areaInfo
      }
      areaInfo.input_mode = true
      this.inputMode = true
      this.oneAreaInfo = areaInfo
      this.setStartMinutes(areaInfo)
      this.setDeliveryMinutes(areaInfo)
    },
    switchToNonInputMode(areaInfo) {
      areaInfo.input_mode = false
      areaInfo.input_mode_info = areaInfo
      this.inputMode = false
      this.oneAreaInfo = {}
    },
    setStartMinutes(areaInfo) {
      if (this.hours[this.hours.length - 1].val === areaInfo.input_mode_info.start_time_hour) {
        // 22時を選択した場合、00分まで選択可能とする
        areaInfo.input_mode_info.start_time_minute = this.setMinute(areaInfo.input_mode_info.start_time_minute)
        this.startMinutes = this.minutes2
      } else {
        this.startMinutes = this.minutes
      }
    },
    setDeliveryMinutes(areaInfo) {
      if (this.hours[this.hours.length - 1].val == areaInfo.input_mode_info.end_time_hour) {
        // 22時を選択した場合、00分まで選択可能とする
        areaInfo.input_mode_info.end_time_minute = this.setMinute(areaInfo.input_mode_info.end_time_minute)
        this.deliveryMinutes = this.minutes2
      } else {
        this.deliveryMinutes = this.minutes
      }
    },
    setMinute(minute) {
      return this.minutes2[this.minutes2.length - 1].val < minute ? 0 : minute
    },
    update(areaInfo) {
      this.serverErrors = []

      let sHour = ('00' + areaInfo.input_mode_info.start_time_hour).slice(-2)
      let sMinute = ('00' + areaInfo.input_mode_info.start_time_minute).slice(-2)
      let eHour = ('00' + areaInfo.input_mode_info.end_time_hour).slice(-2)
      let eMinute = ('00' + areaInfo.input_mode_info.end_time_minute).slice(-2)

      let now = new Date()
      let startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), sHour, sMinute, 0)
      let endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), eHour, eMinute, 0)
      if (startDate > endDate) {
        this.serverErrors.push('集配時間（開始）と集配時間（終了）の値が不正です。')
      }

      if (!areaInfo.input_mode_info.zipcode.match(/^[0-9]+$/)) {
        this.serverErrors.push('郵便番号は半角数字で指定してください。')
      }

      if (this.serverErrors.length > 0) {
        return
      }

      const obj = {
        ['zipcode']: areaInfo.input_mode_info.zipcode,
        ['prefecture']: areaInfo.input_mode_info.prefecture,
        ['address']: areaInfo.input_mode_info.address,
        ['start_time']: sHour + ":" + sMinute,
        ['end_time']: eHour + ":" + eMinute,
        ['is_valid_monthly']: areaInfo.input_mode_info.is_valid_monthly,
        ['is_valid_onetime']: areaInfo.input_mode_info.is_valid_onetime,
        ['bikou']: areaInfo.input_mode_info.bikou,
      }

      if (areaInfo.is_new) {
        adminAreaInfoApi.create(obj).then(() => {
          areaInfo.input_mode = false
          areaInfo.input_mode_info = areaInfo
          this.inputMode = false
          this.oneAreaInfo = {}
          this.getAreaInfos()
          this.resetNewInput()
        }).catch(e => {
          const errRes = e.response.data.errors;
          let serverErrors = []
          Object.keys(errRes).forEach(k => {
            serverErrors = serverErrors.concat(errRes[k])
          })
          this.serverErrors = serverErrors;
        })
      } else {
        adminAreaInfoApi.update(areaInfo.id, obj).then(() => {
          areaInfo.input_mode = false
          areaInfo.input_mode_info = areaInfo
          this.inputMode = false
          this.oneAreaInfo = {}
          this.getAreaInfos()
        }).catch(e => {
          const errRes = e.response.data.errors;
          let serverErrors = []
          Object.keys(errRes).forEach(k => {
            serverErrors = serverErrors.concat(errRes[k])
          })
          this.serverErrors = serverErrors;
        })
      }
    },
    deleteInfo(areaInfo) {
      if (this.inputMode) {
        return
      }
      if (!confirm(`下記提供エリアを削除します\n[郵便番号:${areaInfo.zipcode}]`)) {
        return
      }
      this.serverErrors = []
      adminAreaInfoApi.cancel(areaInfo.id).then(() => {
        this.getAreaInfos()
      }).catch(e => {
        const errRes = e.response.data.errors;
        let serverErrors = []
        Object.keys(errRes).forEach(k => {
          serverErrors = serverErrors.concat(errRes[k])
        })
        this.serverErrors = serverErrors;
      })
    },
    resetNewInput() {
      this.newAreaInfo = {
        zipcode: null,
        prefecture: null,
        address: null,
        start_time: null,
        start_time_hour: 10,
        start_time_minute: 0,
        end_time_hour: 10,
        end_time_minute: 0,
        end_time: null,
        is_valid_monthly: 1,
        is_valid_onetime: 1,
        bikou: null,
        input_mode: false,
        input_mode_info: {},
        is_new: true,
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.contents {
  width: 95%;
  margin: 20px auto 0;
  overflow-y: scroll;
  min-height: 400px;
}
.form-control {
  height: 24px;
  font-size: 12px;
  width: 96%;
}
.form-control-sm {
  font-size: 12px;
}
.btn {
  font-size: 16px;
  border-radius: 4px;
  background-color: #00a9ca;
  color: #ffffff;
  padding: 6px 12px;
  cursor: pointer;
}
.btn_sm {
  font-size: 12px;
  padding: 2px 6px;
}
.btn.bg_white {
  color: #555555;
  background-color: #ffffff;
  border: 1px solid #aaaaaa;
}
.btn.bg_green {
  background-color: #47b673;
}
.btn.bg_danger {
  background-color: #d80a0a;
}
.fr {
  float: right;
}
.list-box {
  margin: 20px 0 0 0;

  .tbl {
    table-layout: fixed;
    font-size: 14px;
    // white-space: nowrap;
    border-collapse: collapse;
    vertical-align: middle;
    width: 100%;
      span {
        word-break: break-all;
      }
  }
  th, td {
    border: 1px solid #aaa;
    padding: 2px 4px;
  }
}
.nl2br  {
  white-space: pre-wrap;
}
// .err-msg-area {
//   margin: 30px auto 0;
//   text-align: center;
//   width: 90%;
// }
</style>