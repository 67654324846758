const localStorageName = 'awash_web'
const SET_LOCALSTORAGE = 'SET_LOCALSTORAGE'

// the definition of initial state is required.
const state = {
  serverToken: '',
}

try {
  const config = JSON.parse(localStorage.getItem(localStorageName))
  Object.keys(config).forEach(k => {
    state[k] = config[k]
  })
} catch (e) {}

const getters = {
  serverToken: state => state.serverToken,
}

const actions = {
  get({ commit, state, getters }, { key }) {
    return Promise.resolve(state[key])
  },
  set({ commit, state, getters }, { key, val }) {
    const data = { [key]: val }
    commit(SET_LOCALSTORAGE, { data })
  },
}

const mutations = {
  [SET_LOCALSTORAGE](state, { data }) {
    Object.keys(data).forEach(k => {
      state[k] = data[k]
    })
    localStorage.setItem(localStorageName, JSON.stringify(state))
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
