var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"list"},[(_vm.isTypeA)?_c('li',[_c('p',[_vm._v("申込みプランの個数以上にご利用いただく場合は「追加で購入する」ボタンを押すと追加購入ができます")])]):_vm._e(),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v("宅配ボックスをご利用の場合は解除用のパスワードを記入ください")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v("集配時間の3時間前に確認メールが届きます")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v("集配時間の変更は集配予定時間の2時間前まで可能です")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v("集配時間の変更は後ろ倒しのみ可能です。前倒しはできません")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v("最短で集荷時間から休業日を除く48時間後に配達可能です")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v("集配同時プランの当日の集配依頼は11時までです")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v("集配時間は最大で2時間ほど前後する可能性があります")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v("不在の場合はメールまたは電話にて再集配の時間を調整")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v("集荷の時間変更や不在により集荷予定日を含む2営業日以内に集荷が完了しない場合は自動的にキャンセルとなり、1個分としてカウントいたします")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v("集配依頼個数に対し、残個数が不足している場合は自動で追加購入が実行されます")])])
}]

export { render, staticRenderFns }