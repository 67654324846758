<template>
<ul class="list">
  <li v-if="isTypeA"><p>申込みプランの個数以上にご利用いただく場合は「追加で購入する」ボタンを押すと追加購入ができます</p></li>
  <li><p>宅配ボックスをご利用の場合は解除用のパスワードを記入ください</p></li>
  <li><p>集配時間の3時間前に確認メールが届きます</p></li>
  <li><p>集配時間の変更は集配予定時間の2時間前まで可能です</p></li>
  <li><p>集配時間の変更は後ろ倒しのみ可能です。前倒しはできません</p></li>
  <li><p>最短で集荷時間から休業日を除く48時間後に配達可能です</p></li>
  <li><p>集配同時プランの当日の集配依頼は11時までです</p></li>
  <li><p>集配時間は最大で2時間ほど前後する可能性があります</p></li>
  <li><p>不在の場合はメールまたは電話にて再集配の時間を調整</p></li>
  <li><p>集荷の時間変更や不在により集荷予定日を含む2営業日以内に集荷が完了しない場合は自動的にキャンセルとなり、1個分としてカウントいたします</p></li>
  <li><p>集配依頼個数に対し、残個数が不足している場合は自動で追加購入が実行されます</p></li>
</ul>
</template>

<script>
export default {
  name: 'wash-request-notice-messages',
  props: {
    mongonType: {
      type: String
    }
  },
  data() {
    return {}
  },
  computed: {
    isTypeA() {
      return this.mongonType === 'A'
    }
    ,isTypeB() {
      return this.mongonType === 'B'
    }
  },
}
</script>