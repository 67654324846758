<template>
<ul class="list">
  <li><p>メンテナンス時間は目安なので設定通りにメンテナンスが開始、終了するということではありません。</p></li>
  <li><p>「内容」にはメンテナンス理由やリリース内容を記入してください。</p></li>
</ul>
</template>

<script>
export default {
  name: 'maintenance-messages',
  data() {
    return {}
  },
}
</script>