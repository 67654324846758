<template src="./template.html"></template>
<style lang="scss" src="./style.scss" scoped></style>

<script>
import userApi from '@/api/user'
import masterApi from '@/api/master'
import areaInfoApi from '@/api/areaInfo'
import userMixin from '@/mixin/userMixin'
import SameTimeDeliveryNoteModal from "@/components/Register/SameTimeDeliveryNoteModal.vue";

export default {
  name: 'register',
  components: { SameTimeDeliveryNoteModal },
  data() {
    return {
      currentStep: 1,
      maxStep: 2,
      isStep1: true,
      isStep2: false,

      lovs: {},
      plans: [],
      planMap: {},
      lovsMap: {},
      sizeDisplay: {},
      months: window.VueMonthOptions,
      dates: window.VueDateOptions,
      hours: window.VueHourOptions,
      minutes: window.VueMinuteOptions,
      counts: [
        1,2,3,4,5
      ],

      planPart1: '',
      planPart2: '',
      bagSize: '',
      planId: '',
      showBagSizeSlct: true,
      isOnetime: false,
      showPdSameTime: false,
      pdSameTime: '',
      showSameTimeDeliveryNoteModal: false,
      count: 1,
      price: '',
      unitPrice: '',
      payment_method_disp: '',
      planParts1: [],
      paymentMethods: [],

      pickupMonth: 1,
      pickupDate: 1,
      pickupHour: 12,
      pickupMinute: 0,
      deliveryMonth: 1,
      deliveryDate: 1,
      deliveryHour: 12,
      deliveryMinute: 0,

      user: {
        name1: '',
        name2: '',
        kana1: '',
        kana2: '',
        company_name: '',
        bill_addr_zipcode: '',
        bill_addr_detail1: '',
        bill_addr_detail2: '',
        bill_addr_detail3: '',
        phone_number: '',
        email: '',
        email_confirm: '',
        email2: '',
        email2_confirm: '',
        password: '',
        password_confirm: '',
        bikou1: '',
        payment_method_id: 1,
      },

      agreeToTerms: false,

      isBillAddrZipcodeSearchError: false,
      isSendAddrZipcodeSearchError: false,

      paymentInfo: {
        url: '',
        formParams: [],
        retUrl: '',
      },

      SizeForHanding: '',

      areaInfos: [],

      errors: {},
      serverErrors: [],
    }
  },
  mounted() {
    window.master.$promise.then(data => {
      this.lovs = data.lovs
      this.plans = data.plans
      this.planMap = data.planMap
      this.lovsMap = data.lovsMap
      this.other = data.other

      this.sizeDisplay = this.lovs.size.reduce((acc, e) => {
        e = { ...e }
        if (e.key == 1) {
          e.display = e.val + "（35L）"
        } else if(e.key == 2){
          e.display = e.val + "（80L）"
        } else if(e.key == 3){
          e.display = e.val + "（140L）"
        } else if(e.key == 4){
          e.display = e.val + "（250L）"
        }
        acc[e.key] = e;

        return acc
      }, {})
      if (this.lovs.plan_part) {
        const planParts1 = this.lovs.plan_part.filter(p => {
          // お試しプランは選択不可
          return p['key'] !== 't0'
        })
        this.planParts1 =  planParts1
      }
      if (this.lovs.payment_method) {
        const paymentMethods = this.lovs.payment_method.filter(pm => {
          return pm['key'] !== '3'
        })
        this.paymentMethods =  paymentMethods
      }
    })

    areaInfoApi.index().then(({ data }) => {
      this.areaInfos = data
    })

  },
  watch: {
    bagSize: function(newBagSize, oldBagSize) {
        const planParts1 = this.lovs.plan_part.filter(p => {
          // お試しプランは選択不可
          if (p['key'] === 't0') {
            return false
          // bagSizeがS,Mの場合は40、48、56個を選択できないようにする
          } else if ((newBagSize == 1 || newBagSize == 2) && (p['key'] === 't40' || p['key'] === 't48' || p['key'] === 't56')) {
            return false
          }
          return true
        })
        this.planParts1 =  planParts1

        // 40、48、56個を選択した状態で、bagSizeをS,Mに変更した場合は個数をリセットする
        if ((newBagSize == 1 || newBagSize == 2) && (this.planPart1 === 't40' || this.planPart1 === 't48' || this.planPart1 === 't56')) {
          this.planPart1 = ''
        }
    }
  },
  computed: {
    billAddrZipcodeConv() {
      const zc = this.user.bill_addr_zipcode || ''
      return zc.replace('-', '')
    },
    isBillAddrZipcodeValid() {
      const zc = this.billAddrZipcodeConv
      return zc.length === 7 && zc.match(/^\d+$/)
    },
    planPart1Disp() {
      const plan = this.planMap[this.planId]
      if (!plan) { return '' }
      return plan.name
    },
    planPart2Disp() {
      const pdType = this.lovs.pd_type
      if (!pdType) { return '' }
      let ret = ''
      pdType.forEach(e => {
        if (e.key === this.planPart2) {
          ret = e.val
        }
      })
      return ret
    },
    hasError() {
      return Object.keys(this.errors).length > 0
    },
    numBags() {
      // 集配同時は2個
      return this.pdSameTime === '1' ? 2 : 1
    },
    bagSizeAlphabetValue() {
      const obj = this.sizeDisplay[this.bagSize]
      if (!obj) { return '' }
      return this.sizeDisplay[this.bagSize].val
    },
    bagPrice() {
      const obj = (this.lovsMap.price_for_add_bag || {})[this.bagSize]
      if (!obj) { return 0 }
      return obj.val
    },
  },
  mixins: [userMixin],
  methods: {
    nextStep() {
      if (!this.checkCurrentStep()) { return }
      this.currentStep = Math.min(this.currentStep + 1, this.maxStep)
      this.updateStep()
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 0)
    },
    prevStep() {
      this.currentStep = Math.max(this.currentStep - 1, 1)
      this.updateStep()
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 0)
    },
    updateStep() {
      for (let i = 1; i <= this.maxStep; i++) {
        const flag = i === this.currentStep
        this[`isStep${i}`] = flag
      }
      this.payment_method_disp = this.lovsMap.payment_method[this.user.payment_method_id].val
    },
    checkCurrentStep() {
      return this[`checkStep${this.currentStep}`]()
    },
    checkStep1() {
      this.errors = {}
      let result = true

      if (!this.planPart1) {
        this.errors['planPart1'] = true
        this.errors['planPart1_required'] = true
        result = false
      }
      if (!this.planPart2) {
        this.errors['planPart2'] = true
        this.errors['planPart2_required'] = true
        result = false
      }

      if (!this.bagSize) {
        this.errors['bagSize'] = true
        this.errors['bagSize_required'] = true
        result = false
      }

      let user_plan = {
        'plan_id': this.planId
      }
      this.user['user_plan'] = user_plan
      if (!this.checkUserParams(this.user, this.areaInfos)) {
        result = false
      }

      if (!this.user.company_name && this.user.payment_method_id === '2') {
        this.errors['payment_method_unselectable'] = true
        result = false
      }

      if (this.planPart1 === 'tx'  && this.user.payment_method_id === '2') {
        this.errors['payment_method_onetime'] = true
        result = false
      }

      if ((this.planPart1 === 't2' || this.planPart1 === 't4') && this.bagSize === '1' && this.user.payment_method_id === '2') {
        this.errors['payment_method_plans'] = true
        result = false
      }

      if (this.planPart1 === 't2' && this.bagSize === '2' && this.user.payment_method_id === '2') {
        this.errors['payment_method_plans'] = true
        result = false
      }

      return result
    },
    checkStep2() {
      return true
    },
    onPlanChange() {
      if (this.planPart1 == 'tx') {
        this.bagSize = this.lovs.size[0].key
        this.SizeForHanding = '単発（45L）'
        this.showBagSizeSlct = false
        this.isOnetime = true
      } else {
        this.isOnetime = false
        this.showBagSizeSlct = true
      }

      if (!this.planPart1 || !this.planPart2 || !this.bagSize || !this.count) {
        if (this.planPart1 && this.planPart2) {
          this.showPdSameTime = true
        }
        this.price = ''
        this.unitPrice = ''
        return
      }
      // 直接受け渡す以外は不在受け渡しとする
      const planIdSuffix = this.planPart2 === '1' ? '1' : '2'

      //Sサイズ以外はplanIdにサイズを追記する
      let bagSizeSuffix = ''
      const sizeMap = this.lovsMap.size
      if (this.bagSize != 1) {
        bagSizeSuffix = '_' + sizeMap[this.bagSize].val
      }

      // 単発プランの場合、初回費用固定
      if (this.planPart1 === 'tx') {
        this.planId = this.planPart1 + '_' + planIdSuffix
        this.price = ''
        this.unitPrice = ''
        this.showPdSameTime = false
        this.pdSameTime = ''
        return
      }
      // お試しプランの場合、初回費用固定
      if (this.planPart1 === 't0') {
        this.planId = this.planPart1 + '_' + planIdSuffix
        this.price = this.other['INITIAL_PRICE_T0']
        this.unitPrice = ''
        this.showPdSameTime = false
        this.pdSameTime = ''
        return
      }
      // 月２個プランの場合
      if (this.planPart1 === 't2') {
        this.planId = this.planPart1 + '_' + planIdSuffix + bagSizeSuffix
        this.price = this.planMap[this.planId].price * this.count
        this.unitPrice = this.price / this.planMap[this.planId].wash_count
        this.showPdSameTime = false
        this.pdSameTime = ''
        return
      }
      // 配達と次回の集配の集配を同時にする場合、末尾に付与
      this.showPdSameTime = true
      const planIdSuffix2 = this.pdSameTime === '1' ? '_1' : ''
      this.planId = this.planPart1 + '_' + planIdSuffix + planIdSuffix2 + bagSizeSuffix
      if (!this.planMap[this.planId]) {
        this.price = ''
        this.unitPrice = ''
        return
      }

      this.price = this.planMap[this.planId].price * this.count
      this.unitPrice = this.price / this.planMap[this.planId].wash_count
    },
    doRegister() {
      this.serverErrors = [];
      const payment_method_id = this.user.payment_method_id
      if (!this.user.company_name) {
        this.user.email2 = ''
        this.user.email2_confirm = ''
      }

      const reqData = { ...this.user }
      reqData.plan_id = this.planId
      reqData.pickup_delivery_type = this.planPart2
      reqData.role = 900
      reqData.payment_method_id = payment_method_id
      userApi.register(reqData)
        .then(({ data }) => {
          if (payment_method_id === 1 && this.planPart1 !== 'tx') {
            // クレジット決済(単発以外)
            this.doA8sales(data)
            this.updatePaymentFormValues(data.payment)

            setTimeout(() => {
              const form = document.querySelector('#payment-form')
              form.submit()
            }, 0)
          } else {
            // 請求書、単発
            this.$store.dispatch('localStorage/set', {
              key: 'serverToken',
              val: data.token.access_token,
            })
            // window.location = '/'
            let name = 'register-complete'
            if (this.planPart1 === 'tx') {
              name = 'onetime-register-complete'
            }
            this.$router.push({
              name: name,
              params: {
                id: data.user.id,
                payment_method_id: data.user.payment_method_id
              }
            })
          }
        })
        .catch(e => {
          const errRes = e.response.data.errors;
          let serverErrors = []
          Object.keys(errRes).forEach(k => {
            serverErrors = serverErrors.concat(errRes[k])
          })
          this.serverErrors = serverErrors;
        })
    },
    updatePaymentFormValues(obj) {
      this.paymentInfo.url = obj.url
      this.paymentInfo.formParams = obj.form_params
      this.paymentInfo.retUrl = `${location.protocol}//${location.host}/${obj.ret_url_path}`
    },
    doBillAddrZipcodeSearch() {
      if (!this.isBillAddrZipcodeValid) { return }
      this.isBillAddrZipcodeSearchError = false
      const zc = this.billAddrZipcodeConv
      masterApi.searchZipcode(zc).then(data => {
        if (!data || data.length === 0) {
          this.isBillAddrZipcodeSearchError = true
          return;
        }
        this.user.bill_addr_detail1 =
          data.prefecture_name +
            data.address1 + data.address2 + data.address3
        this.user.bill_addr_detail2 = ''
        this.user.bill_addr_detail3 = ''
      })
    },
    doA8sales(obj) {
      const t_no = obj.payment.form_params.find(item => item.name === 'S_TORIHIKI_NO')
      // const user_id = obj.user.id
      const order_number = t_no.value //+ '_' + user_id

      a8sales({
        "pid": "s00000020811001",
        "order_number": order_number,
        "currency": "JPY",
        "items": [
          {
            "code": "a8",
            "price": 1000,
            "quantity": 1
          },
        ],
        "total_price": 1000
      })
    },
  }
}
</script>
