<template>
<div v-show="role === 100" class="contents_855">
  <div>
    <router-link :to="{name:'admin-progress-management'}">
      進捗管理
    </router-link>
  </div>
  <div>
    <router-link :to="{name:'admin-users'}">
      ユーザー一覧
    </router-link>
  </div>
  <div>
    <router-link :to="{name:'admin-onetime-users'}">
      単発ユーザー一覧
    </router-link>
  </div>
  <div>
    <router-link :to="{name:'admin-wash-requests'}">
      集配一覧
    </router-link>
  </div>
  <div>
    <router-link :to="{name:'admin-business-holiday-setting'}">
      休業日管理
    </router-link>
  </div>
  <div>
    <router-link :to="{name:'admin-manual-payments'}">
      手動決済管理
    </router-link>
  </div>
  <div>
    <router-link :to="{name:'admin-maintenance'}">
    メンテナンス
    </router-link>
  </div>
  <div>
    <router-link :to="{name:'admin-operation-histories'}">
      操作履歴
    </router-link>
  </div>
  <div>
    <router-link :to="{name:'admin-area-infos'}">
      提供エリア
    </router-link>
  </div>
  <div>
    <router-link :to="{name:'admin-onetime-payments'}">
      単発料金管理
    </router-link>
  </div>
  <div>
    <router-link :to="{name:'admin-members'}">
      運営アカウント管理
    </router-link>
  </div>
  <div>
   <a href="#" @click="logout">
      ログアウト
    </a>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'admin-top',
  data() {
    return {
      role: 0,
      roleType: 0
    }
  },
  computed: {
    ...mapGetters('user', [
      'isLoggedIn'
    ])
  },
  mounted() {
    this.$store.dispatch('user/getMe').then(me => {
      if (this.isLoggedIn) {
        this.role = me.role;
        this.roleType = me.role_type;
      }
    })
  },
  methods: {
    logout() {
      this.$store.dispatch('user/logout')
        .then(() => {
          this.$store.dispatch('localStorage/set', {
            key: 'serverToken',
            val: null,
          })
          location.href = '/login'
        })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
