import axios from 'axios'
import * as utils from './api-utils'

export default {
  index() {
    return axios.get(utils.getApiUrl('/admin/onetimePayments'))
  },
  update(id, data) {
    return axios.post(utils.getApiUrl(`/admin/onetimePayment/update/${id}`), data)
  },
  delete(id, data) {
    return axios.post(utils.getApiUrl(`/admin/onetimePayment/delete/${id}`), data)
  },
}