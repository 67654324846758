<template>
<div>
  <no-login-header></no-login-header>

  <h1 class="pagetitle">パスワード再設定</h1>

  <div v-show="isStep1">
    <div class="contents_login">
      <p class="left mg10">新しいパスワードをご入力ください。</p>

      <p class="item">新しいパスワード</p>
      <p>
        <input type="password" size="40" class="form_basic"
          v-model="params.password" :class="{error_outline: this.errors.password}">
      </p>
      <p class="err-wrap">
        <span class="iferror" v-show="errors.password_required">
          ▲ 必須項目です
        </span>
      </p>
      <p class="err-wrap">
        <span class="iferror" v-show="errors.password_invalid">
          ▲ 半角英数8文字以上で入力してください
        </span>
      </p>


      <p class="item">パスワード確認</p>
      <p>
        <input type="password" size="40" class="form_basic"
          v-model="params.password_confirm" :class="{error_outline: this.errors.password_confirm}"/>
      </p>
      <p class="err-wrap">
        <span class="iferror" v-show="errors.password_confirm_required">
          ▲ 必須項目です
        </span>
      </p>
      <p class="err-wrap">
        <span class="iferror" v-show="errors.password_confirm_unmatch">
          ▲ 確認のため、パスワードと同じ内容を入力してください
        </span>
      </p>

      <div class="err-msg-area">
        <p><span class="font_orange" v-for="e in serverErrors">
          {{e}}
        </span></p>
      </div>

      <p class="submit_bx1">
        <button @click="resetPassword()" class="submit1 bg_blue">
          送信する
        </button>
      </p>
    </div>
  </div>

  <div v-show="isStep2">
    <div class="contents_notes">
      <p class="font_orange mg_bt40">
        パスワードを再設定いたしました。<br>
      </p>
      <p class="submit_bx1 mg_bt40">
        <button @click="goToMypage()" class="bt_big bg_blue">
          マイページへ
        </button>
      </p>
    </div>
  </div>

  <no-login-footer></no-login-footer>
</div>
</template>

<script>
import userApi from '@/api/user'
import userMixin from '@/mixin/userMixin'

export default {
  name: 'password-reset',
  data() {
    return {
      currentStep: 1,
      maxStep: 2,
      isStep1: true,
      isStep2: false,

      params: {
        token: this.$route.params.token,
        password: '',
        password_confirm: '',
      },

      errors: {},
      serverErrors: [],
    }
  },
  mixins: [userMixin],
  methods: {
    nextStep() {
      this.currentStep = Math.min(this.currentStep + 1, this.maxStep)
      this.updateStep()
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 0)
    },
    prevStep() {
      this.currentStep = Math.max(this.currentStep - 1, 1)
      this.updateStep()
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 0)
    },
    updateStep() {
      for (let i = 1; i <= this.maxStep; i++) {
        const flag = i === this.currentStep
        this[`isStep${i}`] = flag
      }
    },
    checkPassword() {
      this.errors = {}
      let result = true

      const requiredParams = [
        'password',
        'password_confirm',
      ]
      const confirmableParams = [
        'password',
      ]
      requiredParams.forEach(prop => {
        if (!this.params[prop]) {
          this.errors[prop] = true
          this.errors[`${prop}_required`] = true
          result = false
        }
      })
      confirmableParams.forEach(prop => {
        const val1 = this.params[prop]
        const val2 = this.params[`${prop}_confirm`]
        if (val1 !== val2) {
          this.errors[`${prop}_confirm`] = true
          this.errors[`${prop}_confirm_unmatch`] = true
          result = false
        }
      })

      let errObj = this.checkValidPassword(this.params.password)
      if (Object.keys(errObj).length > 0) {
        Object.keys(errObj).forEach(k => {
          this.errors[k] = errObj[k]
        })
        result = false
      }

      return result
    },
    resetPassword() {
      if (!this.checkPassword()) { return }

      this.serverErrors = []

      userApi.resetMyPassword(this.params)
        .then(({ data }) => {
          this.$store.dispatch('localStorage/set', {
            key: 'serverToken',
            val: data['token']['access_token'],
          })
          this.nextStep()
        })
        .catch(e => {
          if (e.response.status === 404) {
            this.serverErrors = [
              '無効なURLです。再度パスワード再設定を行ってください。'
            ]
          } else {
            const errRes = e.response.data.errors;
            let serverErrors = []
            Object.keys(errRes).forEach(k => {
              serverErrors = serverErrors.concat(errRes[k])
            })
            this.serverErrors = serverErrors;
          }
        })
    },
    goToMypage() {
      // サーバー側でログイン済
      // window.location = window.location.host
      window.location = '/'
    },
  }
}
</script>

<style lang="scss" scoped>
.disabled {
  filter: alpha(opacity=5);
  -moz-opacity:0.5;
  opacity:0.5;
}
.err-msg-area {
  margin: 30px auto 0;
  text-align: center;
  width: 90%;
}
.submit_bx1 {
  margin: 12px auto;
}
.err-wrap {
  text-align: left;
  padding: 0 4px;
}
</style>
