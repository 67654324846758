import axios from 'axios'
import * as utils from './api-utils'

export default {
  index() {
    return axios.get(utils.getApiUrl('/areaInfos'))
  },
  getAreaInfo(obj) {
    return axios.get(utils.getApiUrl('/areaInfo'), {
      params: obj || {}
    })
  },
}