<template>
<div class="contents_855">
  <div v-show="isStep1">
    <h1 class="title2">ユーザーの削除</h1>
    <div class="contents_box mg20">
      <p class="mypage_notes mg30 mg_bt30 font_orange">
        下記のユーザーを本当に削除しますか。
      </p>
      <table class="form_tab form_line_top">
        <tr>
          <th><p class="item2">お名前（かな）</p></th>
          <td><p>
            <span>{{user.name1}} {{user.name2}} ({{user.kana1}} {{user.kana2}})</span>
          </p></td>
        </tr>
        <tr>
          <th><p class="item2">現在プラン（開始日）</p></th>
           <td><p>
             <span>{{user.planPart1Disp}} ({{user.pStartDisp | dtFormat('yyyy/mm/dd')}}~)</span>
           </p></td>
         </tr>
         <tr>
          <th><p class="item2">会社/店舗名</p></th>
          <td><p>{{user.company_name}}</p></td>
         </tr>
         <tr>
           <th><p class="item2">集配先ご住所</p></th>
           <td>
            <p>〒{{user.bill_addr_zipcode}}</p>
            <p>{{user.bill_addr_detail1}}{{user.bill_addr_detail2}}</p>
            <p>{{user.bill_addr_detail3}}</p>
           </td>
         </tr>
         <tr>
           <th><p class="item2">その他の住所</p></th>
          <td>
            <p>〒{{user.send_addr_zipcode}}</p>
            <p>{{user.send_addr_detail1}}{{user.send_addr_detail2}}</p>
            <p>{{user.send_addr_detail3}}</p>
          </td>
        </tr>
        <tr>
          <th><p class="item2">電話番号</p></th>
          <td><p>{{user.phone_number}}</p></td>
         </tr>
        <tr>
          <th><p class="item2">メールアドレス①</p></th>
          <td><p>{{user.email}}</p></td>
        </tr>
        <tr>
          <th class="form_line_l"><p class="item2">備考</p></th>
          <td class="form_line_r"><p v-html="$options.filters.lineBreakToBR(user.bikou1)"></p></td>
        </tr>
      </table>
      <p class="submit_bx_l">
        <router-link :to="{name:this.adminType === 'admin' ? 'admin-users' : 'staff-users'}" class="submit1 bg_gray">
          戻る
        </router-link>
      </p>
      <p class="submit_bx_r">
        <button class="submit1 bg_green"
          @click="deleteUser()">
          確定
        </button>
      </p>
    </div>
  </div>

  <div v-show="isStep2">
    <h1 class="title2">ユーザーの削除</h1>
    <div class="contents_box mg20">
      <p class="mypage_notes mg30 mg_bt30 font_orange">
        ユーザーを削除しました
      </p>
    </div>
  </div>

</div>
</template>

<script>
import Vue from 'vue'
import adminUserApi from '@/api/adminUser'

export default {
  name: 'admin-user-list-delete',
  props: {
    id: {
      type: [String, Number],
      default: -1
    },
    adminType: {
      type: String,
      default: 'admin'
    }
  },
  data() {
    return {
      currentStep: 1,
      maxStep: 2,
      isStep1: true,
      isStep2: false,

      planMap: {},
      user: {},

      errors: {},
      serverErrors: [],
    }
  },
  computed: {
    hasError() {
      return Object.keys(this.errors).length > 0
    }
  },
  mounted() {
    window.master.$promise.then(mst => {
      this.planMap = mst.planMap
      adminUserApi.index().then(({ data }) => {
        data.map(e => this.convUser(e))
          .forEach(e => {
          if (parseInt(e.id) === parseInt(this.id)) {
            this.user = e
          }
        })
      })
    })
  },
  methods: {
    convUser(user) {
      const ret = Object.assign({}, user)

      ret.planPart1Disp = this.convPlanPart1(user.plan_id)

      // 現在プランの開始日を月初に変更
      const pStartDisp = new Date(user.p_start)
      pStartDisp.setDate(1)
      ret.pStartDisp = pStartDisp

      return ret
    },
    convPlanPart1(planId) {
      let planPart1Disp = ''
      const plan = this.planMap[planId]
      if (plan) { planPart1Disp = plan.name }
      return planPart1Disp
    },
    nextStep() {
      if (!this.checkCurrentStep()) { return }
      this.currentStep = Math.min(this.currentStep + 1, this.maxStep)
      this.updateStep()
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 0)
    },
    prevStep() {
      this.currentStep = Math.max(this.currentStep - 1, 1)
      this.updateStep()
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 0)
    },
    updateStep() {
      for (let i = 1; i <= this.maxStep; i++) {
        const flag = i === this.currentStep
        this[`isStep${i}`] = flag
      }
    },
    checkCurrentStep() {
      return this[`checkStep${this.currentStep}`]()
    },
    checkStep1() {
      return true
    },
    deleteUser() {
      this.serverErrors = []

      adminUserApi.delete(this.user.id).then(({ data }) => {
        this.nextStep()
      }).catch(e => {
        const errRes = e.response.data.errors || []
        let serverErrors = []
        Object.keys(errRes).forEach(k => {
          serverErrors = serverErrors.concat(errRes[k])
        })
        this.serverErrors = serverErrors;
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.err-msg-area {
  margin: 30px auto 0;
  text-align: center;
  width: 90%;
}
.submit_bx_l, .submit_bx_r {
  margin: 12px auto;
}
</style>
