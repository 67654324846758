import { render, staticRenderFns } from "./MyCalendar.vue?vue&type=template&id=0b6a2b58"
import script from "./MyCalendar.vue?vue&type=script&lang=js"
export * from "./MyCalendar.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports