import axios from 'axios'
import * as utils from './api-utils'

export default {
  index(obj) {
    return axios.get(utils.getApiUrl('/admin/operationHistories'), {
      params: obj || {}
    })
  },
  updatebikous(id, data) {
    return axios.post(utils.getApiUrl(`/admin/operationHistories/${id}/bikous`), data)
  },
}