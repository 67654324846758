<template>
<div class="contents_855">
  <div>
    <div class="contents_box">
      <table>
        <tr>
          <th><p class="item2">日付</p></th>
          <td>
            <my-calendar
              v-model="search.searchDtFrom"
              width="70px"
              :readonly="true"
            ></my-calendar>
            <span class="cal-tilda">〜</span>
            <my-calendar
              v-model="search.searchDtTo"
              width="70px"
              :readonly="true"
            ></my-calendar>
          </td>
        </tr>
        <tr>
          <th><p class="item2">担当者</p></th>
          <td>
            <p>
              <select v-model="search.staffId" style="width:150px;">
                <option value="">全て</option>
                <option v-for="staff in search.staffs" v-bind:value="staff.id">
                  {{staff.name1}} {{staff.name2}}
                </option>
              </select>
            </p>
          </td>
        </tr>
        <tr>
          <th><p class="item2">ステータス</p></th>
          <td>
            <div>
              <select v-model="search.pdStatusType" style="width:100px;">
                <option v-for="elem in lovs.pd_status_type" :value="elem.key">
                  {{elem.val}}
                </option>
              </select>
            </div>
          </td>
        </tr>
        <tr>
          <th><p class="item2">ID:お客様</p></th>
          <td>
            <div>
              <input type="text" v-model="search.user" class="form-control">
            </div>
          </td>
        </tr>
      </table>
      <p class="submit_bx1">
        <button class="submit1 bg_blue" @click="searchWashRequests()">
          <i class="fa fa-search"></i>
          検索
        </button>
      </p>
    </div>
  </div>

  <div class="list-box">
    <p class="count-box">該当件数：{{wreqs.length}}件</p>
    <table class="tbl">
      <tr>
        <th>日時</th>
        <th>担当者</th>
        <th>ステータス</th>
        <th>ID:お客様</th>
      </tr>
      <tr v-for="(wreq, idx) in wreqs">
        <td>{{wreq.target_dt | dtFormat('mm/dd HH:MM')}}</td>
        <td>{{wreq.staff_name1}}{{wreq.staff_name2}}</td>
        <td>{{wreq.status}}</td>
        <td>{{wreq.user_id}}：{{wreq.name1}}{{wreq.name2}}</td>
      </tr>
    </table>
  </div>

</div>
</template>

<script>
import Vue from 'vue'
import masterApi from '@/api/master'
import adminUserApi from '@/api/adminUser'
import adminWashRequestApi from '@/api/adminWashRequest'

export default {
  name: 'admin-progress-management',
  props: {
    adminType: {
      type: String,
      default: 'admin'
    }
  },
  data() {
    return {
      search: {
        staffId: '',
        staffs: [],
        searchDtFrom: '',
        searchDtTo: '',
        user: '',
        pdStatusType: '',
      },

      lovs: {},
      pdStatusTypeMap: {},
      staffMap: {},
      wreqs: [],
      roleType: 0,
    }
  },
  computed: {
    wreqsItemCount() {
      return this.wreqs.length
    },
  },
  async mounted() {
    await this.$store.dispatch('user/getMe').then(me => {
      this.roleType = me.role_type;
    })
    // メンテナンス中は管理者とマネージャ以外アクセス不可
    masterApi.maintenanceIndex().then(({ data }) => {
      const maint_flg = data.maint_flg
      if (maint_flg === 1 && this.adminType === 'staff' && this.roleType !== 1) {
        this.$router.push({name: 'maintenance'})
      }
    })
    // 管理者とマネージャー以外アクセス不可
    if (this.adminType === 'staff' && this.roleType !== 1) {
      this.$router.push({ name: 'staff-top' })
    }
    const mst = await window.master.$promise
    this.lovs = mst.lovs
    this.planMap = mst.planMap
    this.pdStatusTypeMap = mst.lovsMap.pd_status_type

    const { data: staffs } = await adminUserApi.myOfficeStaffs()
    this.search.staffs = staffs
    this.staffMap = staffs.reduce((acc, e) => {
      acc[e.id] = e; return acc
    }, {})

    if (this.search.searchDtTo === '' || this.search.searchDtFrom === '') {
      // 当日日付をデフォルトとしておく
      const dt = new Date()
      const dtFormat = Vue.filter('dtFormat')
      this.search.searchDtTo = dtFormat(dt, 'yyyy/mm/dd')
      this.search.searchDtFrom = dtFormat(dt, 'yyyy/mm/dd')
    }

    // 洗濯済をデフォルトとしておく
    this.search.pdStatusType = '2'

    this.searchWashRequests()
  },
  methods: {
    convWashRequest(wreq) {
      const ret = Object.assign({}, wreq)

      ret.staff_name1 = ''
      ret.staff_name2 = ''
      const staff = this.staffMap[wreq.staff_id]
      if (staff) {
        ret.staff_name1 = staff.name1
        ret.staff_name2 = staff.name2
      }

      ret.status = this.pdStatusTypeMap[this.search.pdStatusType].val

      // 未集荷
      if (this.search.pdStatusType === '0') { ret.target_dt = wreq.created_at }
      // 集荷済
      if (this.search.pdStatusType === '1') { ret.target_dt = wreq.result_pickup_dt }
      // 洗濯済
      if (this.search.pdStatusType === '2') { ret.target_dt = wreq.result_wash_dt }
      // たたみ済
      if (this.search.pdStatusType === '4') { ret.target_dt = wreq.result_fold_dt }
      // 配達済
      if (this.search.pdStatusType === '3') { ret.target_dt = wreq.result_delivery_dt }

      return ret
    },
    filterBySearchParams1(wreq) {
      const search = this.search
      // 担当者割り当て
      if (search.staffId) {
        if (wreq.staff_id != search.staffId) { return false }
      }
      return true
    },
    filterBySearchParams2(wreq) {
      const search = this.search
      const dtFrom = new Date(this.search.searchDtFrom.replace(/\//g, '-') + 'T00:00:00')
      const dtTo = new Date(this.search.searchDtTo.replace(/\//g, '-') + 'T23:59:59')

      // 未集荷
      if (search.pdStatusType === '0') {
        const targetDt = new Date(wreq.created_at)
        if (dtFrom > targetDt || targetDt > dtTo) { return false }
      }
      // 集荷済
      if (search.pdStatusType === '1') {
        const targetDt = new Date(wreq.result_pickup_dt)
        if (dtFrom > targetDt || targetDt > dtTo) { return false }
      }
      // 洗濯済
      if (search.pdStatusType === '2') {
        const targetDt = new Date(wreq.result_wash_dt)
        if (dtFrom > targetDt || targetDt > dtTo) { return false }
      }
      // たたみ済
      if (search.pdStatusType === '4') {
        const targetDt = new Date(wreq.result_fold_dt)
        if (dtFrom > targetDt || targetDt > dtTo) { return false }
      }
      // 配達済
      if (search.pdStatusType === '3') {
        const targetDt = new Date(wreq.result_delivery_dt)
        if (dtFrom > targetDt || targetDt > dtTo) { return false }
      }
      return true
    },
    filterBySearchParams3(wreq) {
      const search = this.search
      if (search.user) {
        if (isFinite(search.user)) {
          if (wreq.user_id.toString() !== search.user) { return false }
        } else {
          const nameStr = `${wreq.name1} ${wreq.name2}`
          const reg = new RegExp(search.user)
          if (!nameStr.match(reg)) { return false }
        }
      }
      return true
    },
    searchWashRequests() {
      const obj = {
        from: this.search.searchDtFrom.replace(/\//g, '-') + 'T00:00:00',
        to: this.search.searchDtTo.replace(/\//g, '-') + 'T23:59:59',
      }
      adminWashRequestApi.getProgress(obj).then(({ data }) => {
        this.wreqs = data
          .map(e => this.convWashRequest(e))
          .filter(e => this.filterBySearchParams1(e))
          .filter(e => this.filterBySearchParams2(e))
          .filter(e => this.filterBySearchParams3(e))
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.contents_855 {
  min-height: 400px;
}
.form-control {
  height: 24px;
  font-size: 12px;
  width: 96%;
}
.count-box{
  margin: 10px 10px 10px 10px;
}
.list-box {
  margin: 10px 10px 10px 10px;

  .tbl {
    table-layout: auto;
    font-size: 12px;
    white-space: nowrap;
    width: 100%;
    border-collapse: collapse;
  }
  th, td {
    border: 1px solid #aaa;
    padding: 2px 4px;
  }
}

</style>
