<template>
<div class="contents_855">
  <header-bar active="profile"></header-bar>

  <div v-show="isStep1">
    <h1 class="title2">お客様情報の変更</h1>
    <div class="contents_box mg20">
      <table class="form_tab form_line_top">
        <tr>
          <th><p class="item3">お名前<span class="font_orange">*</span></p></th>
          <td>
            <p class="pc_br_none tab_br_none sp_br_yes">
              <span class="diblk">姓</span>
              <input type="text" v-model="user.name1" size="15" class="form_basic"
                :class="{error_outline: this.errors.name}">　 <br><span class="diblk">名</span>
              <input type="text" v-model="user.name2" size="15" class="form_basic"
                :class="{error_outline: this.errors.name}">
            </p>
            <p>
              <span class="iferror" v-show="errors.name_required">
                ▲ 必須項目です
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <th><p class="item3">おなまえ（ひらがな）<span class="font_orange">*</span></p></th>
          <td>
            <p class="pc_br_none tab_br_none sp_br_yes">
              <span class="diblk">姓</span>
              <input type="text" v-model="user.kana1" size="15" class="form_basic"
                :class="{error_outline: this.errors.kana}">　 <br><span class="diblk">名</span>
              <input type="text" v-model="user.kana2" size="15" class="form_basic"
                :class="{error_outline: this.errors.kana}">
            </p>
            <p>
              <span class="iferror" v-show="errors.kana_required">
                ▲ 必須項目です
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <th><p class="item3">会社/店舗名</p></th>
          <td>
            <p>
              <input type="text" v-model="user.company_name" class="form_basic form_70p" />
            </p>
          </td>
        </tr>
        <tr>
          <th><p class="item2">集配先住所<span class="font_orange">*</span></p></th>
          <td>
            <p class="font_blue mg_bt5">
              郵便番号<span class="font_orange">　※ハイフンは不要 例:1234567</span>
            </p>
            <p>
              <input type="text" v-model="user.bill_addr_zipcode" size="10" class="form_basic fl_left" :class="{error_outline: this.errors.bill_addr_zipcode}">
              <a class="zipcode-search form_address radius4" @click="doBillAddrZipcodeSearch()" :class="{disabled: !isBillAddrZipcodeValid}">住所検索</a>
              <span class="biblk ml8 font_orange zipcode-search-err-msg"
                  v-show="isBillAddrZipcodeSearchError">
                郵便番号がみつかりませんでした
              </span>
            </p>
            <p>
              <span class="iferror" v-show="errors.bill_addr_zipcode_required">
                ▲ 必須項目です
              </span>
              <span class="iferror" v-show="errors.bill_addr_zipcode_invalid">
                ▲ 正しい郵便番号を入力してください
              </span>
              <span class="iferror" v-show="errors.bill_addr_zipcode_notarea">
                ▲ 提供エリア外のためご利用いただけません
              </span>
            </p>

            <p class="font_blue pd10 clear_b">都県/区市/町</p>
            <p><input type="text" v-model="user.bill_addr_detail1" class="form_basic form_100p" :class="{error_outline: this.errors.bill_addr_detail1}"></p>
            <p>
              <span class="iferror" v-show="errors.bill_addr_detail1_required">
                <span class="font_orange">*</span>▲ 必須項目です
              </span>
            </p>

            <p class="font_blue pd10 clear_b">番地</p>
            <p><input type="text" v-model="user.bill_addr_detail2" class="form_basic form_70p" :class="{error_outline: this.errors.bill_addr_detail2}"></p>
            <p>
              <span class="iferror" v-show="errors.bill_addr_detail2_required">
                ▲ 必須項目です
              </span>
            </p>

            <p class="font_blue pd10 clear_b">建物名および部屋番号</p>
            <p><input type="text" v-model="user.bill_addr_detail3" class="form_basic form_70p" /></p>
          </td>
        </tr>
        <tr>
          <th><p class="item3">電話番号<span class="font_orange">*</span></p></th>
          <td>
            <p>
              <input type="text" v-model="user.phone_number" size="20" class="form_basic" :class="{error_outline: this.errors.phone_number}">
              <span class="font_orange">　※ハイフンは不要 例:09012345678</span>
            </p>
            <p>
              <span class="iferror" v-show="errors.phone_number_required">
                ▲ 必須項目です
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <th><p class="item3">メールアドレス①<span class="font_orange">*</span></p></th>
          <td>
            <p>
              <input type="text" v-model="user.email" class="form_basic form_70p" :class="{error_outline: this.errors.email}">
            </p>
            <p>
              <span class="iferror" v-show="errors.email_required">
                ▲ 必須項目です
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <th class="form_line_l"><p class="item3">メールアドレス①（確認）</p></th>
          <td class="form_line_r">
            <p>
              <input type="text" class="form_basic form_70p" v-model="user.email_confirm" :class="{error_outline: this.errors.email_confirm}">
            </p>
            <p>
              <span class="iferror" v-show="errors.email_confirm_required">
                ▲ 必須項目です
              </span>
            </p>
            <p>
              <span class="iferror" v-show="errors.email_confirm_unmatch">
                ▲ 確認のため、メールアドレス①と同じ内容を入力してください
              </span>
            </p>
          </td>
        </tr>
        <tr v-if="user.company_name">
          <th><p class="item3">メールアドレス②</p></th>
          <td>
            <p>
              <input type="text" v-model="user.email2" class="form_basic form_70p" :class="{error_outline: this.errors.emai2}">
              <p class="font_orange">※請求書(PDF)送付先がメールアドレス①と異なる場合は入力してください</p>
            </p>
          </td>
        </tr>
        <tr v-if="user.company_name">
          <th class="form_line_l"><p class="item3">メールアドレス②（確認）</p></th>
          <td class="form_line_r">
            <p>
              <input type="text" class="form_basic form_70p" v-model="user.email2_confirm" :class="{error_outline: this.errors.email2_confirm}">
            </p>
            <p>
              <span class="iferror" v-show="errors.email2_confirm_unmatch">
                ▲ 確認のため、メールアドレス②と同じ内容を入力してください
              </span>
            </p>
          </td>
        </tr>
          <th class="form_line_l"><p class="item3">備考</p></th>
          <td class="form_line_r">
            <p><textarea type="text" class="form_basic" v-model="user.bikou1"></textarea></p>
          </td>
        </tr>
      </table>

      <div class="err-msg-area" v-show="hasError">
        <span class="font_orange">
          入力エラーがあります
        </span>
      </div>

      <p class="submit_bx1">
        <button @click="nextStep()" class="submit1 bg_green">
          確認画面
        </button>
      </p>
    </div>
  </div>

  <div v-show="isStep2">
    <h1 class="title2">お客様情報の変更</h1>
    <div class="contents_box mg20">
      <p class="mypage_notes mg30 mg_bt30 font_orange">
        下記の情報でよろしければ送信してください。
      </p>
      <table class="form_tab form_line_top">
        <tr>
          <th><p class="item2">お名前</p></th>
          <td><p>
            <span>{{user.name1}}</span>
            <span>{{user.name2}}</span>
          </p></td>
        </tr>
        <tr>
          <th><p class="item2">おなまえ（ひらがな）</p></th>
          <td><p>
            <span>{{user.kana1}}</span>
            <span>{{user.kana2}}</span>
          </p></td>
        </tr>
        <tr>
          <th><p class="item2">会社/店舗名</p></th>
          <td><p>{{user.company_name}}</p></td>
        </tr>
        <tr>
          <th><p class="item2">集配先住所</p></th>
          <td>
            <p>〒{{user.bill_addr_zipcode}}</p>
            <p>{{user.bill_addr_detail1}}{{user.bill_addr_detail2}}</p>
            <p>{{user.bill_addr_detail3}}</p>
          </td>
        </tr>
        <tr>
          <th><p class="item2">電話番号</p></th>
          <td><p>{{user.phone_number}}</p></td>
        </tr>
        <tr>
          <th><p class="item2">メールアドレス①</p></th>
          <td><p>{{user.email}}</p></td>
        </tr>
        <tr v-if="user.company_name">
          <th><p class="item2">メールアドレス②</p></th>
          <td><p>{{user.email2}}</p></td>
        </tr>
        <tr>
          <th class="form_line_l"><p class="item2">備考</p></th>
          <td class="form_line_r"><p v-html="$options.filters.lineBreakToBR(user.bikou1)"></p></td>
        </tr>
      </table>

      <div class="err-msg-area">
        <p><span class="font_orange" v-for="e in serverErrors">
          {{e}}
        </span></p>
        <p><span class="font_orange" v-show="isStatusWithdrawn">
          このアカウントは退会済みです
        </span></p>
      </div>

      <p class="submit_bx_l">
        <button @click="prevStep()" class="submit1 bg_gray">
          戻る
        </button>
      </p>
      <p class="submit_bx_r">
        <button @click="updateProfile()" class="submit1 bg_green">
          OK
        </button>
      </p>
    </div>
  </div>

  <div v-show="isStep3">
    <h1 class="title2">お客様情報の変更</h1>
    <div class="contents_box mg20">
      <p class="mypage_notes mg30 mg_bt30 font_orange">
        お客様情報を変更しました
      </p>
      <!--
      <p class="submit_bx1">
        <router-link :to="{name:'profile'}" class="glnavi_btn_on">
          お客様情報へ
        </router-link>
      </p>
      -->
    </div>
  </div>

</div>
</template>

<script>
import userApi from '@/api/user'
import masterApi from '@/api/master'
import areaInfoApi from '@/api/areaInfo'
import userMixin from '@/mixin/userMixin'

export default {
  name: 'profile',
  data() {
    const user = {}
    Object.entries(this.$store.state.user)
      .forEach(([k, v]) => {
        user[k] = v
      })
    user.email_confirm = user.email
    user.email2_confirm = user.email2

    return {
      currentStep: 1,
      maxStep: 3,
      isStep1: true,
      isStep2: false,
      isStep3: false,
      isBillAddrZipcodeSearchError: false,
      user: user,

      areaInfos: [],

      isStatusWithdrawn: false,
      errors: {},
      serverErrors: [],
    }
  },
  computed: {
    billAddrZipcodeConv() {
      const zc = this.user.bill_addr_zipcode || ''
      return zc.replace('-', '')
    },
    isBillAddrZipcodeValid() {
      const zc = this.billAddrZipcodeConv
      return zc.length === 7 && zc.match(/^\d+$/)
    },
    hasError() {
      return Object.keys(this.errors).length > 0
    }
  },
  mounted() {
    this.$store.dispatch('user/getMe')
      .then(data => {
        Object.entries(data)
          .forEach(([k, v]) => {
            this.user[k] = v
          })
      })

    areaInfoApi.index().then(({ data }) => {
      this.areaInfos = data
    })
  },
  mixins: [userMixin],
  methods: {
    nextStep() {
      if (!this.checkCurrentStep()) { return }
      this.currentStep = Math.min(this.currentStep + 1, this.maxStep)
      this.updateStep()
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 0)
    },
    prevStep() {
      this.currentStep = Math.max(this.currentStep - 1, 1)
      this.updateStep()
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 0)
    },
    updateStep() {
      for (let i = 1; i <= this.maxStep; i++) {
        const flag = i === this.currentStep
        this[`isStep${i}`] = flag
      }
    },
    checkCurrentStep() {
      return this[`checkStep${this.currentStep}`]()
    },
    checkStep1() {
      this.errors = {}
      let result = true

      if (!this.checkUserParams(this.user, this.areaInfos, {noPasswordCheck: true})) {
        result = false
      }
      return result
    },
    checkStep2() {
      return true
    },
    updateProfile() {
      this.isStatusWithdrawn = false
      this.serverErrors = []
      if (!this.user.company_name) {
        this.user.email2 = ''
        this.user.email2_confirm = ''
      }

      userApi.updateMe(this.user).then(({ data }) => {
        this.nextStep()
        this.$store.dispatch(
          'user/getMe',
          {forceRefresh: true}
        )
      }).catch(e => {
        if (e.response.data.reason === 'status_withdrawn') {
          this.isStatusWithdrawn = true
          this.$store.dispatch('localStorage/set', {
            key: 'serverToken',
            val: null,
          })
          return
        }
        const errRes = e.response.data.errors;
        let serverErrors = []
        Object.keys(errRes).forEach(k => {
          serverErrors = serverErrors.concat(errRes[k])
        })
        this.serverErrors = serverErrors;
      })
    },
    doBillAddrZipcodeSearch() {
      if (!this.isBillAddrZipcodeValid) { return }
      this.isBillAddrZipcodeSearchError = false
      const zc = this.billAddrZipcodeConv
      masterApi.searchZipcode(zc).then(data => {
        if (!data || data.length === 0) {
          this.isBillAddrZipcodeSearchError = true
          return;
        }
        this.user.bill_addr_detail1 =
          data.prefecture_name +
            data.address1 + data.address2 + data.address3
        this.user.bill_addr_detail2 = ''
        this.user.bill_addr_detail3 = ''
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.zipcode-search {
  cursor: pointer;
}
.zipcode-search-err-msg {
  line-height: 2.5;
}
.disabled {
  filter: alpha(opacity=5);
  -moz-opacity:0.5;
  opacity:0.5;
}
.err-msg-area {
  margin: 30px auto 0;
  text-align: center;
  width: 90%;
}
</style>
