<template>
<div class="contents">
  <admin adminType="staff" :searchPrm="searchPrm"/>
</div>
</template>
<script>
import Admin from '../Admin/AdminOnetimeUserList.vue'
export default {
  name: 'staff-onetime-users',
  props: {
    searchPrm: {
      type: Object,
      'default': () => ({
        registerDtFrom: '',
        registerDtTo: '',
        userId: '',
        name: '',
        kana: '',
        billAddrZipcode: '',
        phoneNumber: '',
        email: '',
        status: '',
      })
    }
  },
  components: {
    Admin
  }
}
</script>