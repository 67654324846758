<template>
<div class="contents">
  <admin adminType="staff"/>
</div>
</template>
<script>
import Admin from './../Admin/AdminBusinessHolidaySetting.vue'
export default {
  name: 'staff-business-holiday-setting',
  components: {
    Admin
  }
}
</script>