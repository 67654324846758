<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import masterApi from '@/api/master'
import adminUserApi from '@/api/adminUser'
import adminWashRequestApi from '@/api/adminWashRequest'
import VueJsonToCsv from "vue-json-to-csv";
import Encoding from 'encoding-japanese';
import _orderBy from "lodash.orderby";
import adminWashRequestAddBagModal from '@/components/Admin/AdminWashRequestAddBagModal.vue'
import adminWashRequestWorkReportModal from '@/components/Admin/AdminWashRequestWorkReportModal.vue'
import { bagDeliveryBikouStr } from "@/consts/wash_request";

export default {
  components: {
    VueJsonToCsv,
    adminWashRequestAddBagModal,
    adminWashRequestWorkReportModal,
  },
  name: 'admin-wash-request-list',
  props: {
    searchPrm: {
      type: Object,
      'default': () => ({
        staffId: '',
        createdDtFrom: '',
        createdDtTo: '',
        filterWreqCreated: false,
        filterPickedUp: false,
        filterWashed: false,
        filterFolded: false,
        filterDelivered: false,
        userId: '',
        bagSize:'',
      })
    },
    adminType: {
      type: String,
      default: 'admin'
    }
  },
  data() {
    return {
      search: {
        staffId: '',
        staffs: [],
        createdDtFrom: '',
        createdDtTo: '',
        filterWreqCreated: false,
        filterPickedUp: false,
        filterWashed: false,
        filterFolded: false,
        filterDelivered: false,
        userId: '',
        bagSize: '',
      },

      staffId: '',
      washRequestIds: [],
      lovs: {},
      planMap: {},
      pdStatusTypeMap: {},
      sizeMap:{},
      paymentMethodMap:{},
      staffMap: {},
      addBagReasonMap: {},
      operationTypeMap: {},
      optionMap: {},
      wreqs: [],
      selectCount: [1,2,3,4,5,6,7,8,9,10],
      showStaffModal: false,
      showAddBagModal: false,
      showWorkReportModal: false,
      haveCsvData: true,

      jsonData1: [],
      jsonData2: [],
      csvData: [],
      csvLabels1: {},
      csvLabels2: {},
      csvTitle1: '集荷済みデータ',
      csvTitle2: '集配リスト作成データ',
      readySetCsv: true,

      bagDeliveryBikouStr: bagDeliveryBikouStr,

      roleType: 0,
      errors: {},
      serverErrors: [],
    }
  },
  computed: {
    ...mapState('user', {
      userId: state => state.id,
    }),
    isAdminPage() {
      return this.adminType === 'admin'
    },
    isAdminAndManagerAndLeader() {
      return this.adminType === 'admin' || (this.adminType === 'staff' && [1,2].includes(this.roleType))
    },
    isAdminAndLeaderAndWashAndFold() {
      return this.adminType === 'admin' || (this.adminType === 'staff' && [2,3].includes(this.roleType))
    },
    isAdminAndLeaderAndDelivery() {
      return this.adminType === 'admin' || (this.adminType === 'staff' && [2,4].includes(this.roleType))
    },
    isExcPickUpAndDelivery() {
      return this.adminType === 'admin' || (this.adminType === 'staff' && this.roleType !== 4)
    },
    isWashAndFold() {
      return this.adminType === 'staff' && this.roleType === 3
    },
    wreqsItemCount() {
      return this.wreqs.length
    },
    sortedWreqs: function() {
      const wreqs = this.wreqs
      return _orderBy(wreqs,
                    ["sched_dt", "sizeKey", "count"],
                    ["asc", "desc", "desc"])
    },
    columnClass() {
      return (wreq) => {
        let str = ''
        if (wreq.result_delivery_dt) {
          // 配達済
          // do nothing
        } else if (wreq.result_fold_dt) {
          // たたみ済
          str = wreq.stopped_user_status ? 'back_red' : wreq.direct_delivery ? 'back_yellow' : ''
        } else if (wreq.result_wash_dt) {
          // 洗濯済
          str = wreq.have_fold_type_setting ? 'back_green' : wreq.have_wash_type_setting ? 'back_blue' : ''
        } else if (wreq.result_pickup_dt) {
          // 集荷済
          str = wreq.have_wash_type_setting ? 'back_blue' : ''
        } else {
          // 未集荷
          str = wreq.stopped_user_status ? 'back_red' : wreq.direct_delivery ? 'back_yellow' : ''
        }

        return str
      }
    }
  },
  async mounted() {
    await this.$store.dispatch('user/getMe').then(me => {
      this.roleType = me.role_type;
    })
    // メンテナンス中は管理者とマネージャ以外アクセス不可
    masterApi.maintenanceIndex().then(({ data }) => {
      const maint_flg = data.maint_flg
      if (maint_flg === 1 && this.adminType === 'staff' && this.roleType !== 1) {
        this.$router.push({name: 'maintenance'})
      }
    })
    // 種別なしのスタッフはアクセス不可
    if (this.adminType === 'staff' && this.roleType === 0) {
      this.$router.push({ name: 'staff-top' })
    }

    const mst = await window.master.$promise
    this.lovs = mst.lovs
    this.planMap = mst.planMap
    this.pdStatusTypeMap = mst.lovsMap.pd_status_type
    this.sizeMap = mst.lovsMap.size
    this.paymentMethodMap = mst.lovsMap.payment_method
    this.addBagReasonMap = mst.lovsMap.add_bag_reason
    this.operationTypeMap = this.lovs.operation_type.reduce((acc, e) => {
      if (['36', '37'].includes(e.key)) acc[e.key] = e
      return acc
    }, {})
    this.optionMap = mst.lovsMap.user_plan_option

    this.setSearchPrm()
    const { data: staffs } = await adminUserApi.myOfficeStaffs()
    this.search.staffs = staffs
    this.staffs = staffs
    this.staffMap = staffs.reduce((acc, e) => {
      acc[e.id] = e; return acc
    }, {})
    if (this.search.createdDtTo === '' || this.search.createdDtFrom === '') {
      // 当日日付をデフォルトとしておく
      const dt = new Date()
      const dtFormat = Vue.filter('dtFormat')
      this.search.createdDtTo = dtFormat(dt, 'yyyy/mm/dd')
      this.search.createdDtFrom = dtFormat(dt, 'yyyy/mm/dd')
    }
    this.searchWashRequests()
  },
  created() {
    this.dateStrToDate_ = Vue.filter('dateStrToDate')
  },
  methods: {
    updateCount(idx, wreq) {
      const count = wreq.input_count_str || ''
      const pattern = /^[1-9]\d?$|^0$|^100$/
      if (!pattern.test(count)) { return }

      const obj = {
        ['count']: count
      }
      adminWashRequestApi.updateCount(wreq.id, obj).then(({data}) => {
        wreq.count = count
        // Vue.set(this.wreqs, idx, wreq)
        this.switchToNonInputMode(wreq)
      })
    },

    washInitialization() {
      this.staffId = '',
      this.washRequestIds = [];
    },

    check() {
      this.errors = {}
      let result = true

      if (!this.staffId) {
        this.errors['staffId_required'] = true
        result = false
      }

      return result
    },

    assignStaffs() {
      if (!this.check()) { return }

      const obj = {
        staff_id: this.staffId,
        wash_request_id: this.washRequestIds
      }
      adminWashRequestApi.assignStaffs(obj).then(({ data }) => {
        for(let i = 0; i < this.wreqs.length; i++){
          for(let j = 0; j < this.washRequestIds.length; j++){
            if(this.wreqs[i]["id"] === this.washRequestIds[j]){
              this.wreqs[i].staff_name1 = data.name1;
              this.wreqs[i].staff_name2 = data.name2;
            }
          }
        }
        this.washInitialization()
        this.showStaffModal = false
      }).catch(e => {
        const errRes = e.response.data.errors || []
        let serverErrors = []
        Object.keys(errRes).forEach(k => {
          serverErrors = serverErrors.concat(errRes[k])
        })
        this.serverErrors = serverErrors;
      })
    },
    optionDisp(option) {
      const obj = this.optionMap[option]
      return obj ? '/' + obj.val : ''
    },
    convWashRequest(wreq) {
      const ret = Object.assign({}, wreq)

      ret.staff_name1 = ''
      ret.staff_name2 = ''
      const staff = this.staffMap[wreq.staff_id]
      if (staff) {
        ret.staff_name1 = staff.name1
        ret.staff_name2 = staff.name2
      }

      let planPart1Disp = ''
      const plan = this.planMap[wreq.plan_id]
      if (plan) { planPart1Disp = plan.name + this.optionDisp(wreq.user_plan_option) }
      ret.planPart1Disp = planPart1Disp

      ret.status = this.pdStatusTypeMap[0].val
      ret.sched_dt = wreq.sched_pickup_dt
      if (wreq.result_pickup_dt) {
        ret.status = this.pdStatusTypeMap[1].val
        ret.sched_dt = wreq.sched_delivery_dt
      }
      if (wreq.result_wash_dt) {
        ret.status = this.pdStatusTypeMap[2].val
      }
      if (wreq.result_fold_dt) {
        ret.status = this.pdStatusTypeMap[4].val
      }
      if (wreq.result_delivery_dt) {
        ret.status = this.pdStatusTypeMap[3].val
      }

      ret.stopped_user_status = false
      if (wreq.status == 2) {
        ret.stopped_user_status = true
      }

      ret.have_wash_type_setting = false
      if (wreq.wash_type_setting == 1) {
        ret.have_wash_type_setting = true
      }

      ret.have_fold_type_setting = false
      if (wreq.fold_type_setting == 1) {
        ret.have_fold_type_setting = true
      }

      ret['input_count'] = false
      ret['input_count_str'] = null

      ret.sizeKey = this.sizeMap[this.planMap[wreq.plan_id].size].key
      ret.size = this.sizeMap[this.planMap[wreq.plan_id].size].val

      ret.canChange = !wreq.result_delivery_dt
      ret.canCancel = !wreq.result_pickup_dt
      ret.canUpdate = true

      const planInfos = wreq.plan_id.split('_')
      if (planInfos.length > 1 && planInfos[1] == 1) {
        ret.direct_delivery = true
      } else {
        ret.direct_delivery = false
      }
      ret.plan_info = planInfos[0]
      if (ret.plan_info == 'tx') {
        ret.canChange = false
        ret.canCancel = false
        ret.canUpdate = false
        ret.size = '単発（45L）'
      }

      //TODO 時間あったら順序設定する
      // adminUserApi.getUserCurrentPlan(wreq.user_id).then(({ data }) => {
      //plan_idを_で区切って３つ目の値が1なら集配同時
      //（M~LLサイズの集配別は３つ目にサイズ情報が入る）
      // const planInfos = data.plan_id.split('_')
      // if (planInfos.length > 2 && planInfos[2] == 1) {
      //   this.pdSameTime = true
      // } else {
      //   this.pdSameTime = false
      // }
      //   const now = new Date()
      //   const pickupDt = this.dateStrToDate_(wreq.sched_pickup_dt)
      //   const deliveryDt = this.dateStrToDate_(wreq.sched_delivery_dt)
      //   if (pdSameTime) {
      //     ret.canChange = !wreq.result_delivery_dt && now < pickupDt
      //   } else {
      //     ret.canChange = !wreq.result_delivery_dt && now < deliveryDt
      //   }
      // })

      return ret
    },
    switchToInputMode(wreq) {
      wreq.input_count = true
      wreq.input_count_str = wreq.count
    },
    switchToNonInputMode(wreq) {
      wreq.input_count = false
      wreq.input_count_str = null
    },
    changePickupDt(wreq) {
      let name = 'admin-wash-request-date-change'
      if (this.adminType === 'staff') {
        name = 'staff-wash-request-date-change'
      }
      this.$router.push({
        name: name,
        params: {
          id: wreq.id,
          searchPrm: this.search,
        }
      })
    },
    //type1:キャンセル、type2:破棄
    cancelWashRequest(wreq, type) {
      let name = 'admin-wash-request-cancel'
      if (this.adminType === 'staff') {
        name = 'staff-wash-request-cancel'
      }
      this.$router.push({
        name: name,
        params: {
          id: wreq.id,
          type: type,
          searchPrm: this.search,
        }
      })
    },
    filterBySearchParams1(wreq) {
      const search = this.search

      // 担当者割り当て
      if (search.staffId) {
        if (search.staffId === "0") {
          if (wreq.staff_id) { return false }
        }
        else {
          if (wreq.staff_id != search.staffId) { return false }
        }
      }

      return true
    },
    filterBySearchParams2(wreq) {
      const search = this.search

      // 申込完了(未集荷)
      if (search.filterWreqCreated) {
        if (!wreq.result_pickup_dt) {
          return true
        }
      }
      // 集荷完了
      if (search.filterPickedUp) {
        if (
          wreq.result_pickup_dt &&
          !wreq.result_wash_dt
        ) {
          return true
        }
      }
      // 洗濯完了
      if (search.filterWashed) {
        if (
          wreq.result_wash_dt &&
          !wreq.result_fold_dt &&
          !wreq.result_delivery_dt
        ) {
          return true
        }
      }
      // たたみ完了
      if (search.filterFolded) {
        if (
          wreq.result_fold_dt &&
          !wreq.result_delivery_dt
        ) {
          return true
        }
      }
      // 配達完了
      if (search.filterDelivered) {
        if (wreq.result_delivery_dt) {
          return true
        }
      }

      return false
    },
    filterBySearchParams3(wreq) {
      const search = this.search

      // ユーザーID検索
      if (search.userId) {
        if (wreq.user_id.toString() !== search.userId) { return false }
      }

      return true
    },
    filterBySearchParams4(wreq) {
      if (this.roleType === 4 && wreq.staff_id !== this.userId) {
        return false
      }

      return true
    },
    filterBySearchParams5(wreq) {
      const search = this.search;

      if (search.bagSize) {
        switch (search.bagSize) {
          case 'S_M':
            if (wreq.size !== 'S' && wreq.size !== 'M' && wreq.size !== '単発（45L）') return false;
            break;
          case 'L_LL':
            if (wreq.size !== 'L' && wreq.size !== 'LL') return false;
            break;
          case 'S':
            if (wreq.size !== 'S' && wreq.size !== '単発（45L）') return false;
            break;
          default:
            if (wreq.size !== search.bagSize) return false;
            break;
        }
      }

      return true;
    },
    setSearchPrm() {
      this.search = this.searchPrm
    },
    searchWashRequests() {
      this.haveCsvData = true
      this.washRequestIds = []
      const obj = {
        from: this.search.createdDtFrom.replace(/\//g, '-') + 'T00:00:00',
        to: this.search.createdDtTo.replace(/\//g, '-') + 'T23:59:59',
      }
      adminWashRequestApi.index(obj).then(({ data }) => {
        this.wreqs = data
          .map(e => this.convWashRequest(e))
          .filter(e => this.filterBySearchParams1(e))
          .filter(e => this.filterBySearchParams2(e))
          .filter(e => this.filterBySearchParams3(e))
          .filter(e => this.filterBySearchParams4(e))
          .filter(e => this.filterBySearchParams5(e))
      })
    },
    downloadCsv1() {
      this.csvData = []
      const csvSearchObj = {
        from: this.search.createdDtFrom.replace(/\//g, '-') + 'T00:00:00',
        to: this.search.createdDtTo.replace(/\//g, '-') + 'T23:59:59',
      }

      adminWashRequestApi.downloadCsv1(csvSearchObj).then(({ data }) => {
        if (data.length == 0) {
          this.haveCsvData = false
          return
        } else {
          this.haveCsvData = true
        }
        const dtFormat = Vue.filter('dtFormat')
        let dateDiff = new Date(csvSearchObj.to).getTime() - new Date(csvSearchObj.from).getTime();
        dateDiff = Math.ceil(dateDiff / (1000 * 3600 * 24));
        let oneDate = new Date(csvSearchObj.from);
        this.csvLabels1 = {
          id: { title: 'ユーザーID' },
          name: { title: '名前' },
          plan: { title: 'プラン' },
          payment_method: { title: '支払い方法' },
        }
        for (let i = 0; i < dateDiff; i++) {
          this.csvLabels1[dtFormat(oneDate, 'yyyy/mm/dd')] = {title: dtFormat(oneDate, 'yyyy/mm/dd')}
          oneDate.setDate(oneDate.getDate() + 1);
        }
        Object.keys(data).forEach(e => {
          let record = data[e]
          let pushData = {}
          Object.keys(this.csvLabels1).forEach(lk => {
            pushData[lk] = ''
          })
          Object.keys(record).forEach(e2 => {
            if (e2 == 'payment_method') {
              pushData[e2] = this.paymentMethodMap[record[e2]].val
            } else if (e2 == 'plan') {
              pushData[e2] = this.planMap[record[e2]].name
            } else {
              pushData[e2] = record[e2];
            }
          })
          this.csvData.push(pushData);
          pushData = null
        });

        //ダウンロード
        this.jsonData1 = this.csvData
        setTimeout(() => {
        document.getElementById('csvDownloadbtn1').click();
      }, 1000)
      })
    },
    downloadCsv2() {
      this.csvData = []
      const csvSearchObj = {
        from: this.search.createdDtFrom.replace(/\//g, '-') + 'T00:00:00'
      }

      adminWashRequestApi.downloadCsv2(csvSearchObj).then(({ data }) => {
        if (data.length == 0) {
          this.haveCsvData = false
          return
        } else {
          this.haveCsvData = true
        }
        this.csvLabels2 = {
          case_code: { title: '1:案件コード' },
          case_name: { title: '2:案件名称' },
          case_detail: { title: '3:案件詳細' },
          user_id: { title: '4:訪問先コード' },
          arrival_dt: { title: '5:到着希望日' },
          arrival_time: { title: '6:到着希望時刻' },
          arrival_time_list: { title: '7:到着希望時間帯リスト' },
          work_time: { title: '8:作業時間（分）' },
          amount: { title: '9:荷物量' },
          remarks: { title: '10:関連情報' },
          update_mode: { title: '11:更新モード' },
          error: { title: 'エラー事由' },
        }
        const dtFormat = Vue.filter('dtFormat')
        let count = 1
        Object.keys(data).forEach(e => {
          let record = data[e]
          //1つ目：ユーザーID、２つ目：到着希望日
          let keys = e.split('.')
          let case_code_index = ( '0000' + count ).slice( -4 );
          let pushData = {}
          Object.keys(this.csvLabels2).forEach(lk => {
            if (lk == 'case_code') {
              pushData[lk] = keys[1].slice( -6 ) + case_code_index
            } else if (lk == 'case_name') {
              pushData[lk] = record[lk]
            } else if (lk == 'case_detail') {
              let detail = ''
              if (record[lk]['all'].length == 1) {
                detail = record[lk]['all'][0]
              } else {
                if ('delivery' in record[lk]) {
                  record[lk]['delivery'].forEach(e => {
                    if (detail.length == 0) {
                      detail = e
                    } else {
                      detail = detail + '/' + e
                    }
                  })
                }
                if ('pickup' in record[lk]) {
                  record[lk]['pickup'].forEach(e => {
                    if (detail.length == 0) {
                      detail = e
                    } else {
                      detail = detail + '/' + e
                    }
                  })
                }
              }
              pushData[lk] = detail
            } else if (lk == 'user_id') {
              pushData[lk] = keys[0]
            } else if (lk == 'arrival_time_list') {
              let dt = new Date(record['arrival_dt_time'])
              let ts = dt.getTime()
              let time_minus2 = null
              let time_plus2 = null
              // 単発集荷のみ、または同日に複数依頼があるうちの一番早い依頼が単発集荷の場合、入力値〜90分後を表示
              if (record['is_onetime'] && (record['arrival_type'] == 1 || record['arrival_type'] == 4)) {
                time_minus2 = new Date(ts - (1000 * 60 * 90))
                time_plus2 = dt
              } else {
                time_minus2 = new Date(ts - (1000 * 60 * 120))
                time_plus2 = new Date(ts + (1000 * 60 * 120))
              }
              if (dtFormat(dt, 'yyyymmdd') != dtFormat(time_plus2, 'yyyymmdd')) {
                time_plus2 = new Date(record['arrival_dt_time'])
                time_plus2.setHours(23, 59)
              }
              let arrival_dt_time = dtFormat(time_minus2, 'yyyymmddHHMM') + '-' + dtFormat(time_plus2, 'yyyymmddHHMM')
              pushData[lk] = arrival_dt_time
            } else if (lk == 'work_time') {
              pushData[lk] = 5
            } else if (lk == 'update_mode') {
              pushData[lk] = 0
            } else {
              pushData[lk] = ''
            }
          })
          this.csvData.push(pushData);
          count++
          pushData = null
        });

        //ダウンロード
        this.jsonData2 = this.csvData
        setTimeout(() => {
        document.getElementById('csvDownloadbtn2').click();
      }, 1000)
      })
    },
    uploadCsv(e) {
      let file = e.target.files[0]
      let reader = new FileReader()
      reader.onload = (e) => {
        var u8_arr = new Uint8Array(e.target.result)
        let assign_staff_infos = Encoding.convert(u8_arr, {
          to: 'UNICODE',
          from: 'AUTO',
          type: 'string'
        })

        const obj = {
          assign_staff_infos: assign_staff_infos
        }

        adminWashRequestApi.uploadCsv(obj).then(({ data }) => {
          if (data.length > 0) {
            alert('(' + data + ')行目の登録に失敗しました。')
          }
          this.searchWashRequests()
          this.readySetCsv = false
          this.$nextTick(function () {
            this.readySetCsv = true
          })
        })
      }
      reader.readAsArrayBuffer( file )
    },
    addBag(bag) {
      adminWashRequestApi.addBag(bag)
        .then(() => {
          this.closeModal(1)
        }).catch(e => {
          const errRes = e.response.data.errors || [];
          let serverErrors = []
          Object.keys(errRes).forEach(k => {
            serverErrors = serverErrors.concat(errRes[k])
          })
          this.serverErrors = serverErrors;
        })
    },
    workReport(report) {
      adminWashRequestApi.workReport(report)
        .then(() => {
          this.closeModal(2)
        }).catch(e => {
          const errRes = e.response.data.errors || [];
          let serverErrors = []
          Object.keys(errRes).forEach(k => {
            serverErrors = serverErrors.concat(errRes[k])
          })
          this.serverErrors = serverErrors;
        })
    },
    select(wreq) {
      let name = 'admin-wash-requests-change'
      if (this.adminType === 'staff') {
        name = 'staff-wash-requests-change'
      }
      this.$router.push({
        name: name,
        params: {
          id: wreq.id,
          searchPrm: this.search,
        }
      })
    },
    openModal(modal) {
      if (modal == 1) {
        this.showAddBagModal = true
      } else if (modal == 2) {
        this.showWorkReportModal = true
      }
      document.body.style.overflow = 'hidden';
    },
    closeModal(modal) {
      if (modal == 1) {
        this.showAddBagModal = false
      } else if (modal == 2) {
        this.showWorkReportModal = false
      }
      document.body.style.overflow = '';
    }
  }
}
</script>

<template src="./template.html"></template>
<style lang="scss" src="./style.scss" scoped></style>
