<template>
<div class="contents_855">

  <div>
    <h1 class="title2">プラン変更完了</h1>
    <div class="contents_box mg20">
      <p class="mypage_notes mg30 mg_bt30 font_orange">
        プラン変更が完了しました。
      </p>
    </div>
  </div>

</div>
</template>

<script>
export default {
  name: 'admin-plan-edit-complete',
  props: {
    adminType: {
      type: String,
      default: 'admin'
    }
  }
}
</script>
