<template>
<transition name="loading">
<div class="loading-container">
  <div class="loading-backdrop"></div>
  <div class="loading">
    <div class="loading-dialog">
      <div class="loading-content">
        <h2 class="title">{{title}}</h2>
        <div class="loading-title"></div>
      </div>
    </div>
  </div>
</div>
</transition>
</template>

<script>
export default {
  name: 'loading',
  props: {
    title: {
      type: String,
      default: 'Loading...',
    },
  },
}
</script>

<style lang="scss" scoped>
  .loading-container {
    font-size: 18px;
  }
  .loading-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    background-color: #000000;
    opacity: 0.3;
  }
  .loading {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
  }
  .loading-dialog {
    background-color: #ffffff;
    margin: 15rem auto;
    max-width: 200px;
    border-radius: 4px;
    overflow: hidden;
  }
  .loading-body {
    padding: 16px 24px;
  }
  .loading-enter {
    opacity: 0;
  }
  .loading-enter-active {
    transition: opacity .0s ease;
  }
  .loading-enter-to {
    opacity: 1.0;
  }
  .loading-leave-active {
    opacity: 0;
  }
</style>