<template>
<div class="contents">

  <div class="list-box">
    <div class="err-msg-area">
      <p><span class="font_orange" v-for="e in serverErrors">
        {{e}}
      </span></p>
    </div>
    <p class="delivery-type-area"><span class="font_orange">翌日</span></p>
    <table class="tbl">
      <colgroup>
        <col style="width:10%;">
        <col style="width:10%;">
        <col style="width:10%;">
        <col style="width:10%;">
        <col style="width:10%;">
        <col style="width:10%;">
        <col style="width:10%;">
      </colgroup>
      <tr>
        <th>申込時刻</th>
        <th>月曜日</th>
        <th>火曜日</th>
        <th>水曜日</th>
        <th>木曜日</th>
        <th>金曜日</th>
        <th>土曜日</th>
      </tr>
      <tr v-for="(onetimePayment, idx) in onetimePayments">
        <template v-if="onetimePayment.delivery_type == 1">
          <td>
            <div><span>{{onetimePayment.wreq_time}}</span></div>
          </td>
          <td v-for="weekIdx in weekList">
            <div v-show="!getInputMode(onetimePayment, weekIdx)">
              <span>{{onetimePayment['week' + weekIdx + '_disp']}}</span>
              <button class="btn btn_sm bg_danger fr"
                  v-if="onetimePayment['week' + weekIdx]"
                  @click="switchToInputMode(onetimePayment, weekIdx, true)">
                不可
              </button>
              <button class="btn btn_sm bg_blue fr"
                  @click="switchToInputMode(onetimePayment, weekIdx, false)">
                更新
              </button>
            </div>
            <div v-show="getInputMode(onetimePayment, weekIdx)">
              <div v-show="onetimePayment['delete_flg' + weekIdx]">
                <span>提供不可</span>
              </div>
              <div v-show="!onetimePayment['delete_flg' + weekIdx]">
                <input type="text" class="form-control-sm" size="4"
                  v-model="onetimePayment['input_price_str' + weekIdx]">
              </div>
              <div style="margin:4px 0 0 0;">
                <button class="btn btn_sm bg_danger"
                    @click="updatePrice(idx, onetimePayment, weekIdx)">
                  確定
                </button>
                <button class="btn btn_sm bg_blue"
                    @click="switchToNonInputMode(onetimePayment, weekIdx)">
                  キャンセル
                </button>
              </div>
            </div>
          </td>
        </template>
      </tr>
    </table>
    <p class="delivery-type-area"><span class="font_orange">翌々日</span></p>
    <table class="tbl">
      <colgroup>
        <col style="width:10%;">
        <col style="width:10%;">
        <col style="width:10%;">
        <col style="width:10%;">
        <col style="width:10%;">
        <col style="width:10%;">
        <col style="width:10%;">
      </colgroup>
      <tr>
        <th>申込時刻</th>
        <th>月曜日</th>
        <th>火曜日</th>
        <th>水曜日</th>
        <th>木曜日</th>
        <th>金曜日</th>
        <th>土曜日</th>
      </tr>
      <tr v-for="(onetimePayment, idx) in onetimePayments">
        <template v-if="onetimePayment.delivery_type == 2">
          <td>
            <div><span>{{onetimePayment.wreq_time}}</span></div>
          </td>
          <td v-for="weekIdx in weekList">
            <div v-show="!getInputMode(onetimePayment, weekIdx)">
              <span>{{onetimePayment['week' + weekIdx + '_disp']}}</span>
              <button class="btn btn_sm bg_danger fr"
                  v-if="onetimePayment['week' + weekIdx]"
                  @click="switchToInputMode(onetimePayment, weekIdx, true)">
                不可
              </button>
              <button class="btn btn_sm bg_blue fr"
                  @click="switchToInputMode(onetimePayment, weekIdx, false)">
                更新
              </button>
            </div>
            <div v-show="getInputMode(onetimePayment, weekIdx)">
              <div v-show="onetimePayment['delete_flg' + weekIdx]">
                <span>提供不可</span>
              </div>
              <div v-show="!onetimePayment['delete_flg' + weekIdx]">
                <input type="text" class="form-control-sm" size="4"
                  v-model="onetimePayment['input_price_str' + weekIdx]">
              </div>
              <div style="margin:4px 0 0 0;">
                <button class="btn btn_sm bg_danger"
                    @click="updatePrice(idx, onetimePayment, weekIdx)">
                  確定
                </button>
                <button class="btn btn_sm bg_blue"
                    @click="switchToNonInputMode(onetimePayment, weekIdx)">
                  キャンセル
                </button>
              </div>
            </div>
          </td>
        </template>
      </tr>
    </table>
  </div>
</div>
</template>

<script>
import Vue from 'vue'
import masterApi from '@/api/master'
import adminOnetimePaymentApi from '@/api/adminOnetimePayment'

export default {
  name: 'admin-onetime-payments',
  props: {
    adminType: {
      type: String,
      default: 'admin'
    }
  },
  data() {
    return {
      onetimePayments: [],
      weekList: [1,2,3,4,5,6],
      roleType: 0,
      serverErrors: [],
    }
  },
  mounted() {
    window.master.$promise.then(mst => {
      // 権限種別取得
      this.$store.dispatch('user/getMe').then(me => {
        this.roleType = me.role_type;
      })

      // メンテナンス中は管理者とマネージャ以外アクセス不可
      masterApi.maintenanceIndex().then(({ data }) => {
        const maint_flg = data.maint_flg
        if (maint_flg === 1 && this.adminType === 'staff' && this.roleType !== 1) {
          this.$router.push({name: 'maintenance'})
        }
      })
      // 管理者とマネージャー以外アクセス不可
      if (this.adminType === 'staff' && this.roleType !== 1) {
        this.$router.push({ name: 'staff-top' })
      }
      this.getOnetimePayments()
    })
  },
  methods: {
    convOnetimePayment(onetimePayment) {
      const ret = Object.assign({}, onetimePayment)

      // 時間表示：時分のみ
      ret.wreq_time = ret.wreq_time.slice(0, 5)

      for (let i = 1; i < 7; i++) {
        // 価格表示：修正モード
        ret['week' + i + '_disp'] = !ret['week' + i] ? '提供不可' : ret['week' + i]
        // 価格表示：修正モード
        ret['input_price' + i] = false
        // 価格表示：入力値
        ret['input_price_str' + i] = null
        // 価格削除：削除フラグ
        ret['delete_flg' + i] = false
      }

      return ret
    },
    getOnetimePayments() {
      adminOnetimePaymentApi.index().then(({ data }) => {
        this.onetimePayments = data
          .map(e => this.convOnetimePayment(e))
      })
    },
    getInputMode(onetimePayment, week) {
      let prop = 'input_price' + week
      return onetimePayment[prop]
    },
    switchToInputMode(onetimePayment, week, deleteFlg) {
      onetimePayment['input_price' + week] = true
      onetimePayment['input_price_str' + week] = onetimePayment['week' + week]
      onetimePayment['delete_flg' + week] = deleteFlg
    },
    switchToNonInputMode(onetimePayment, week) {
      onetimePayment['input_price' + week] = false
      onetimePayment['input_price_str' + week] = null
      onetimePayment['week' + week + '_disp'] = !onetimePayment['week' + week] ? '提供不可' : onetimePayment['week' + week]
    },
    updatePrice(idx, onetimePayment, week) {
      const price = onetimePayment['input_price_str' + week]
      const flg = onetimePayment['delete_flg' + week]
      let obj = {}

      // 提供不可設定
      if (flg) {
         // 更新確認
        if (!confirm('対象の日時を提供不可にします。')) {
          return
        }

        obj = {
          ['week']: week
        }
        adminOnetimePaymentApi.delete(onetimePayment.id, obj).then(() => {
          onetimePayment['week' + week] = null
          Vue.set(this.onetimePayments, idx, onetimePayment)
          this.switchToNonInputMode(onetimePayment, week)
          this.serverErrors = []
        }).catch(e => {
          const errRes = e.response.data.errors;
          let serverErrors = []
          Object.keys(errRes).forEach(k => {
            serverErrors = serverErrors.concat(errRes[k])
          })
          this.serverErrors = serverErrors
        })
      // 料金設定
      } else {
        obj = {
          ['week' + week]: price
        }
        adminOnetimePaymentApi.update(onetimePayment.id, obj).then(() => {
          onetimePayment['week' + week] = price
          Vue.set(this.onetimePayments, idx, onetimePayment)
          this.switchToNonInputMode(onetimePayment, week)
          this.serverErrors = []
        }).catch(e => {
          const errRes = e.response.data.errors;
          let serverErrors = []
          Object.keys(errRes).forEach(k => {
            serverErrors = serverErrors.concat(errRes[k])
          })
          this.serverErrors = serverErrors
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.contents {
  width: 95%;
  margin: 20px auto 0;
  overflow-y: scroll;
  min-height: 400px;
}
.form-control {
  height: 24px;
  font-size: 12px;
  width: 96%;
}
.form-control-sm {
  font-size: 12px;
}
.btn {
  font-size: 16px;
  border-radius: 4px;
  background-color: #00a9ca;
  color: #ffffff;
  padding: 6px 12px;
  cursor: pointer;
}
.btn_sm {
  font-size: 12px;
  padding: 2px 6px;
}
.btn.bg_danger {
  background-color: #d80a0a;
}
.fr {
  float: right;
}
.list-box {
  margin: 20px 0 0 0;

  .tbl {
    table-layout: fixed;
    font-size: 14px;
    // white-space: nowrap;
    border-collapse: collapse;
    vertical-align: middle;
    width: 100%;
      span {
        word-break: break-all;
      }
  }
  th, td {
    border: 1px solid #aaa;
    padding: 2px 4px;
  }
}
.delivery-type-area {
  background: #00a9ca;
  padding: 5px;
  margin-top: 15px;
}
.delivery-type-area span {
  color: white;
  padding: 5px;
}
</style>