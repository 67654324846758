<template>
<div class="footer-box" v-if="!hideFooter">
  <ul class="glnavi_foot pc_none">
    <li>
      <a href='/wash-request' class="navi_btn">
        集配依頼
      </a>
    </li>
    <li>
      <router-link :to="{name:'wash-request-currents'}" class="navi_btn">
        変更/キャンセル
      </router-link>
    </li>
    <li>
      <router-link :to="{name:'wash-history'}" class="navi_btn">
        ご利用履歴
      </router-link>
    </li>
    <li>
      <router-link :to="{name:'profile'}" class="navi_btn">
        お客様情報
      </router-link>
    </li>
    <li>
      <router-link :to="{name:'plan'}" class="navi_btn">
        プラン変更
      </router-link>
    </li>
    <li>
      <router-link :to="{name:'contact'}" class="navi_btn">
        お問い合わせ
      </router-link>
    </li>
    <li>
      <router-link :to="{name:'notice'}" class="navi_btn">
        集配に関する注意事項
      </router-link>
    </li>
    <li>
      <a href="https://awesome-wash.com" target="_blank"
        class="navi_btn">AWSOME WASH ホーム</a>
    </li>
  </ul>

  <footer class="foot_wrap">
    <div class="contents_wrap">
      <div class="foot_sitemap tab_none sp_none">｜　<a href="https://awesome-wash.com/" target="_blank" class="link_gray">ホーム</a>　｜　<a href="https://awesome-wash.com/company/" target="_blank" class="link_gray">会社概要</a>　｜　<a href="https://awesome-wash.com/agreement/" target="_blank" class="link_gray">ご利用規約</a>　｜　<a href="https://awesome-wash.com/privacypolicy/" target="_blank" class="link_gray">プライバシーポリシー</a>　｜　<router-link :to="{name:'contact'}" class="link_gray">お問い合わせ</router-link>　｜</div>
      <p class="foot_logo"><a href="https://awesome-wash.com"><img src="/static/images/logo_hd.gif" alt="AWESOME WASH"></a></p>

      <p class="foot_info pc_br_none"><span class="bold">洗濯代行サービス <span class="pc_br_none tab_br_none sp_br_yes"><br></span>AWESOME WASH オーサムウォッシュ</span>　<br>HIPSTER株式会社　<br>東京都新宿区市谷田町3-25 <span class="pc_br_none tab_br_none sp_br_yes"><br></span>パームハウスB2号室　<span class="pc_br_none tab_br_none sp_br_yes"><br></span>TEL:050-1807-1227</p>
      </div>
    <p class="copyright">Copyright HIPSTER Co., Ltd. All rights reserved.</p>
    <transition name="fade">
    <div class="pagetop" v-show="showPageTopButton">
      <a @click="scrollToTop()"><br>▲<br>PAGE<br>TOP</a>
    </div>
    </transition>
  </footer>

</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import routeMixin from '@/mixin/routeMixin'

export default {
  name: 'global-footer',
  data() {
    return {
      scrollTop: document.documentElement.scrollTop || document.body.scrollTop,
      scrollHandler: null,
    }
  },
  computed: {
    ...mapGetters('user', [
      'isLoggedIn'
    ]),
    showPageTopButton() {
      return this.scrollTop > 700
    },
    hideFooter() {
      return !this.isLoggedIn || this.isGuestPage() || this.isAdminPage() || this.isStaffPage()
    },
  },
  mixins: [routeMixin],
  methods: {
    handleScroll() {
      this.scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    },
    scrollToTop() {
      // 仕方なくjquery
      $('body, html').animate({ scrollTop: 0 }, 500);
    },
  },
  mounted() {
    this.scrollHandler = () => {
      this.handleScroll()
    }
    window.addEventListener('scroll', this.scrollHandler)
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollHandler)
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-active {
  opacity: 0;
}
</style>
