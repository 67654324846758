<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import adminUserApi from '@/api/adminUser'
import adminWashRequestApi from '@/api/adminWashRequest'
import adminWashRequestAddStaffModal from '@/components/Admin/AdminWashRequestAddStaffModal.vue'

export default {
  components: {
    adminWashRequestAddStaffModal
  },
  name: 'admin-wash-requests-change',
  props: {
    id: {
      type: [String, Number],
      default: -1
    },
    searchPrm: {
      type: Object,
      'default': () => ({
        staffId: '',
        createdDtFrom: '',
        createdDtTo: '',
        filterWreqCreated: false,
        filterPickedUp: false,
        filterWashed: false,
        filterFolded: false,
        filterDelivered: false,
        userId: '',
        bagSize:'',
      })
    },
    adminType: {
      type: String,
      default: 'admin'
    }
  },
  data() {
    return {
      pdTypeMap: {},
      pdStatusTypeMap: {},

      users: [],
      loginUser: {},
      wreq: {},
      staffId: '',
      staffs: [],
      pdType: 0,
      isSendAbsenceMail: false,
      number: '',
      code: '',
      count: '',
      selectCount: [1,2,3,4,5,6,7,8,9,10],
      isOnetime: false,

      showStaffModal: false,
      showPrevStatusBtn: true,
      showNextStatusBtn: true,
      showPrevStatusModal: false,
      showNextStatusModal: false,
      showAddStaffModal: false,
      showUpdateCountModal: false,

      canAddStaff: false,

      planMap: {},
      sizeMap: {},
      optionMap: {},

      errors: {},
      serverErrors: [],
    }
  },
  beforeRouteLeave (to, from, next) {
    // ブラウザバック時にパラメータがないため追加
    to.params.searchPrm = this.searchPrm
    next()
  },
  computed: {
    ...mapState('user', {
      userId: state => state.id,
      roleType: state => state.role_type
    }),
    isAdmin() {
      return this.adminType === 'admin'
    },
    isAdminAndManagerAndLeader() {
      return this.adminType === 'admin' || (this.adminType === 'staff' && [1,2].includes(this.roleType))
    },
    isAdminAndLeaderAndDelivery() {
      return this.adminType === 'admin' || (this.adminType === 'staff' && [2,4].includes(this.roleType))
    },
    isExcPickUpAndDelivery() {
      return this.adminType === 'admin' || (this.adminType === 'staff' && this.roleType !== 4)
    },
    isExcWashAndFold() {
      return this.adminType === 'admin' || (this.adminType === 'staff' && this.roleType !== 3)
    },
    hasError() {
      return Object.keys(this.errors).length > 0
    },
    pickupDeliveryTypeDisp() {
      const obj = this.pdTypeMap[this.pdType]
      return obj ? obj.val : ''
    },
  },
  async mounted() {
    await this.$store.dispatch('user/getMe').then(me => {
      this.loginUser.id = me.id
      this.loginUser.name = me.name1 + ' ' + me.name2
    })
    window.master.$promise.then(mst => {
      this.planMap = mst.planMap
      this.sizeMap = mst.lovsMap.size
      this.pdTypeMap = mst.lovsMap.pd_type
      this.pdStatusTypeMap = mst.lovsMap.pd_status_type
      this.optionMap = mst.lovsMap.user_plan_option

      this.getWashRequest()
      adminUserApi.myOfficeStaffs().then(({ data }) => {
        this.staffs = data
      })
    })
  },
  methods: {
    filterUserData(data) {
      // 対象ユーザー情報を取得
      if (data.id === this.wreq.user_id) { return true }
      return false
    },
    getWashRequest() {
      adminWashRequestApi.getWashRequest(this.id).then(({ data }) => {
        this.wreq = this.convWashRequest(data)
        this.staffId = data.staff_id

        this.canAddStaff = this.wreq.status_key == 2 && this.wreq.count >= 2 && ["3","4"].includes(this.wreq.size_key)

        adminUserApi.index().then(({ data }) => {
          this.users = data
            .filter(e => this.filterUserData(e))
          this.pdType = this.users[0].pickup_delivery_type
        })
      })
    },
    convWashRequest(wreq) {
      const ret = Object.assign({}, wreq)

      ret.staff_name1 = ''
      ret.staff_name2 = ''

      adminUserApi.myOfficeStaffs().then(({ data }) => {
        const staffs = data
          .filter(e => wreq.staff_id === e.id)

        if (Object.keys(staffs).length === 0) return ret
        ret.staff_name1 = staffs[0].name1
        ret.staff_name2 = staffs[0].name2
      })
      let planInfo = wreq.plan_id.split('_')[0]
      if (planInfo == 'tx') {
        ret.size = '単発（45L）'
        this.isOnetime = true
      } else {
        ret.size = this.sizeMap[this.planMap[wreq.plan_id].size].val + 'サイズ'
      }
      ret.size_key = this.planMap[wreq.plan_id].size
      let option = this.optionMap[wreq.user_plan_option]
      ret.option = option ? option.val : 'なし'

      // 未集荷
      this.showPrevStatusBtn = false;
      if (this.isExcWashAndFold) {
        this.showNextStatusBtn = true;
      } else {
        this.showNextStatusBtn = false;
      }
      ret.prev_status = this.pdStatusTypeMap[0].val
      ret.status = this.pdStatusTypeMap[0].val
      ret.status_key = 0
      ret.next_status = this.pdStatusTypeMap[1].val
      this.prevResultDtProp = ''
      this.currentResultDtProp = 'result_pickup_dt'

      if (wreq.result_pickup_dt) {
        // 集荷済
        if (this.isExcPickUpAndDelivery) {
          this.showPrevStatusBtn = true;
        } else {
          this.showPrevStatusBtn = false;
        }
        if (this.isAdminAndManagerAndLeader || (this.roleType === 3 && (wreq.staff_id === this.userId || wreq.staff_id === null))) {
          this.showNextStatusBtn = true;
        } else {
          this.showNextStatusBtn = false;
        }
        ret.prev_status = this.pdStatusTypeMap[0].val
        ret.status = this.pdStatusTypeMap[1].val
        ret.status_key = 1
        ret.next_status = this.pdStatusTypeMap[2].val
        this.prevResultDtProp = 'result_pickup_dt'
        this.currentResultDtProp = 'result_wash_dt'
      }
      if (wreq.result_wash_dt) {
        // 洗濯済
        if (this.isExcPickUpAndDelivery) {
          this.showPrevStatusBtn = true;
        } else {
          this.showPrevStatusBtn = false;
        }
        if (this.isAdminAndManagerAndLeader || (this.roleType === 3 && (wreq.staff_id === this.userId || wreq.staff_id === null))) {
          this.showNextStatusBtn = true;
        } else {
          this.showNextStatusBtn = false;
        }
        ret.prev_status = this.pdStatusTypeMap[1].val
        ret.status = this.pdStatusTypeMap[2].val
        ret.status_key = 2
        ret.next_status = this.pdStatusTypeMap[4].val
        this.prevResultDtProp = 'result_wash_dt'
        this.currentResultDtProp = 'result_fold_dt'
      }
      if (wreq.result_fold_dt) {
        // たたみ済
        if (this.isExcPickUpAndDelivery) {
          this.showPrevStatusBtn = true;
        } else {
          this.showPrevStatusBtn = false;
        }
        if (this.isExcWashAndFold) {
          this.showNextStatusBtn = true;
        } else {
          this.showNextStatusBtn = false;
        }
        ret.prev_status = this.pdStatusTypeMap[2].val
        ret.status = this.pdStatusTypeMap[4].val
        ret.status_key = 4
        ret.next_status = this.pdStatusTypeMap[3].val
        this.prevResultDtProp = 'result_fold_dt'
        this.currentResultDtProp = 'result_delivery_dt'
      }
      if (wreq.result_delivery_dt) {
        // 配達済
        if (this.isExcPickUpAndDelivery) {
          this.showPrevStatusBtn = true;
        } else {
          this.showPrevStatusBtn = false;
        }
        this.showNextStatusBtn = false;
        ret.prev_status = this.pdStatusTypeMap[4].val
        ret.status = this.pdStatusTypeMap[3].val
        ret.status_key = 3
        ret.next_status = this.pdStatusTypeMap[3].val
        this.prevResultDtProp = 'result_delivery_dt'
      }

      if (ret.absence_mail_histories) {
        this.isSendAbsenceMail = true;
      }

      return ret
    },
    showPrevStatus() {
      this.showPrevStatusModal = true
    },
    showNextStatus() {
      if (this.canAddStaff) {
        this.showAddStaffModal = true
        document.body.style.overflow = 'hidden';
      } else {
        console.log("test1")
        this.showNextStatusModal = true
        const dtFormat = Vue.filter('dtFormat')
        this.dtStr = dtFormat(new Date(), 'yyyy-mm-ddTHH:MM')
      }
    },
    sendAbsenceMail(type) {
      if (this.isSendAbsenceMail) {
        if (!confirm('不在メールを再送信しますか？')) {
          return
        }
      }

      const obj = {
        type: type
      }
      adminWashRequestApi.sendAbsenceMail(this.id, obj).then(() => {
        this.isSendAbsenceMail = true
        alert('不在メールを送信しました。')
      })
    },
    setDate(staffList = [], dtStr = this.dtStr) {
      const dtArr = dtStr.replace('T', ' ').split(/[- :\+]/)
      const dt = new Date(dtArr[0], dtArr[1] - 1, dtArr[2], dtArr[3], dtArr[4])
      const dtFormat = Vue.filter('dtFormat')
      const obj = {
        [this.currentResultDtProp]: dtFormat(dt, 'yyyy-mm-dd HH:MM:SS'),
        'number': this.number,
        'code': this.code,
      }
      if (staffList) {
        obj.staff_list = staffList
      }
      adminWashRequestApi.setDate(this.id, obj).then(() => {
        this.getWashRequest()
                console.log("test2")
        this.showNextStatusModal = false
        if (staffList) {
          document.body.style.overflow = ''
        }
        this.backToWreqList()
      })
    },
    delDate() {
      const obj = {
        [this.prevResultDtProp]: 1,
      }
      adminWashRequestApi.delDate(this.id, obj).then(() => {
        this.getWashRequest()
        this.showPrevStatusModal = false
        this.backToWreqList()
      })
    },
    assignStaff() {
      if (!this.check(1)) { return }

      const obj = {
        staff_id: this.staffId
      }
      adminWashRequestApi.assignStaff(this.id, obj).then(({ data }) => {
        this.getWashRequest()
        this.showStaffModal = false
      }).catch(e => {
        const errRes = e.response.data.errors || []
        let serverErrors = []
        Object.keys(errRes).forEach(k => {
          serverErrors = serverErrors.concat(errRes[k])
        })
        this.serverErrors = serverErrors;
      })
    },
    showUpdateCount() {
      this.showUpdateCountModal = true
      this.count = this.wreq.count
    },
    updateCount() {
      if (!this.check(2)) { return }

      const pattern = /^[1-9]\d?$|^0$|^100$/
      if (!pattern.test(this.count)) { return }

      const obj = {
        ['count']: this.count
      }
      adminWashRequestApi.updateCount(this.id, obj).then(({ data }) => {
        this.getWashRequest()
        this.showUpdateCountModal = false
      }).catch(e => {
        const errRes = e.response.data.errors || []
        let serverErrors = []
        Object.keys(errRes).forEach(k => {
          serverErrors = serverErrors.concat(errRes[k])
        })
        this.serverErrors = serverErrors;
      })
    },
    check(type) {
      this.errors = {}
      let result = true

      if (type == 1 && !this.staffId) {
        this.errors['staffId_required'] = true
        result = false
      }

      if (type == 2 && !this.count) {
        this.errors['count_required'] = true
        result = false
      }

      return result
    },
    backToWreqList() {
      let name = 'admin-wash-requests'
      if (this.adminType === 'staff') {
        name = 'staff-wash-requests'
      }
      this.$router.push({
        name: name,
        params: {
          id: this.id,
          searchPrm: this.searchPrm,
        }
      })
    },
    closeModal() {
      this.showAddStaffModal = false
      document.body.style.overflow = '';
    }
  }
}
</script>

<template src="./template.html"></template>
<style lang="scss" src="./style.scss" scoped></style>
