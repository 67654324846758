<template>
<transition name="modal">
<div class="modal-container">
  <div class="modal-backdrop"></div>
  <div class="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="title">バッグ購入</h2>
        </div>
        <div class="modal-body" ref="modal">
          <div v-show="isStep1">
            <table class="form_tab">
              <tr>
                <th><p class="item2">操作名<span class="font_orange">*</span></p></th>
                <td>
                  <p>
                    <select v-model="bag.operation_type" class="w200"
                        :class="{error_outline: this.errors.operation_type}">
                      <option value="">選択してください</option>
                      <option v-for="elem in operationTypeMap" v-bind:value="elem.key">
                        {{elem.val}}
                      </option>
                    </select>
                  </p>
                  <p>
                    <span class="iferror" v-show="errors.operation_type_required">
                      ▲ 必須項目です
                    </span>
                  </p>
                </td>
              </tr>
              <tr>
                <th><p class="item2">ユーザーID<span class="font_orange">*</span></p></th>
                <td>
                  <p>
                    <input type="number" v-model="bag.user_id" class="form_basic form_70p" :class="{error_outline: this.errors.user_id}">
                  </p>
                  <p>
                    <span class="iferror" v-show="errors.user_id_required">
                      ▲ 必須項目です
                    </span>
                    <span class="iferror" v-show="haveUserError">
                      ▲ ユーザーIDと登録名が一致しません。
                    </span>
                  </p>
                </td>
              </tr>
              <tr>
                <th><p class="item2">登録名<span class="font_orange">*</span></p></th>
                <td>
                  <p>
                    <input type="text" v-model="bag.user_name" class="form_basic form_70p" :class="{error_outline: this.errors.user_name}">
                  </p>
                  <p>
                    <span class="iferror" v-show="errors.user_name_required">
                      ▲ 必須項目です
                    </span>
                  </p>
                </td>
              </tr>
              <tr>
                <th><p class="item2">サイズ<span class="font_orange">*</span></p></th>
                <td>
                  <p>
                    <select v-model="bag.size" class="w200"
                        :class="{error_outline: this.errors.size}">
                      <option value="">選択してください</option>
                      <option v-for="elem in sizeMap" v-bind:value="elem.key">
                        {{elem.val}}
                      </option>
                    </select>
                  </p>
                  <p>
                    <span class="iferror" v-show="errors.size_required">
                      ▲ 必須項目です
                    </span>
                  </p>
                </td>
              </tr>
              <tr>
                <th><p class="item2">個数<span class="font_orange">*</span></p></th>
                <td>
                  <p>
                    <select v-model="bag.count" class="w200"
                        :class="{error_outline: this.errors.count}">
                      <option value="">選択してください</option>
                      <option v-for="elem in bagCount" v-bind:value="elem">
                        {{elem}}
                      </option>
                    </select>
                  </p>
                  <p>
                    <span class="iferror" v-show="errors.count_required">
                      ▲ 必須項目です
                    </span>
                  </p>
                </td>
              </tr>
              <tr>
                <th><p class="item2">備考<span class="font_orange">*</span></p></th>
                <td>
                  <p>
                    <select v-model="bag.bikou" class="w200"
                        :class="{error_outline: this.errors.bikou}">
                      <option value="">選択してください</option>
                      <option v-for="elem in addBagReasonMap" v-bind:value="elem.key">
                        {{elem.val}}
                      </option>
                    </select>
                  </p>
                  <p>
                    <span class="iferror" v-show="errors.bikou_required">
                      ▲ 必須項目です
                    </span>
                  </p>
                </td>
              </tr>
            </table>
            <div class="err-msg-area" v-show="hasError">
              <span class="font_orange">
                入力エラーがあります
              </span>
            </div>
            <div class="modal-footer">
              <button class="submit1 bg_yellow modal-btn btn-primary"
                @click="nextStep()">
                登録する
              </button>
              <button class="submit1 bg_gray modal-btn"
                @click="$emit('dismiss')">
                キャンセル
              </button>
            </div>
          </div>
          <div v-show="isStep2">
            <p class="mypage_notes mg30 mg_bt30 font_orange">
              下記の情報でよろしければ送信してください。
            </p>
            <table class="form_tab form_line_top">
              <tr>
                <th><p class="item2">操作名<span class="font_orange">*</span></p></th>
                <td><p>{{bag.operation_type ? operationTypeMap[bag.operation_type].val : ''}}</p>
                </td>
              </tr>
              <tr>
                <th><p class="item2">ユーザーID<span class="font_orange">*</span></p></th>
                <td><p>{{bag.user_id}}</p>
                </td>
              </tr>
              <tr>
                <th><p class="item2">登録名<span class="font_orange">*</span></p></th>
                <td><p>{{bag.user_name}}</p>
                </td>
              </tr>
              <tr>
                <th><p class="item2">サイズ<span class="font_orange">*</span></p></th>
                <td><p>{{bag.size ? sizeMap[bag.size].val : ''}}</p>
                </td>
              </tr>
              <tr>
                <th><p class="item2">操作名<span class="font_orange">*</span></p></th>
                <td><p>{{bag.count ? bagCount[bag.count -1] : ''}}</p>
                </td>
              </tr>
              <tr>
                <th><p class="item2">備考<span class="font_orange">*</span></p></th>
                <td><p>{{bag.bikou ? addBagReasonMap[bag.bikou].val : ''}}</p>
                </td>
              </tr>
            </table>
            <div class="modal-footer">
              <button class="submit1 bg_yellow modal-btn btn-primary"
                  @click="addBag()">
                OK
              </button>
              <button class="submit1 bg_gray modal-btn"
                @click="prevStep()">
                戻る
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</transition>
</template>

<script>
import adminUser from '@/api/adminUser'
export default {
  name: 'admin-wash-request-add-bag-modal',
  props: {
    sizeMap: {
      type: Object,
      default () {
        return {}
      }
    },
    addBagReasonMap: {
      type: Object,
      default () {
        return {}
      }
    },
    operationTypeMap: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data() {
    return {
      bag: {
        operation_type: '',
        user_id: '',
        user_name: '',
        size: '',
        count: '',
        bikou: '',
      },
      bagCount: [1,2,3,4,5,6,7,8,9,10],

      currentStep: 1,
      maxStep: 2,
      isStep1: true,
      isStep2: false,
      errors: {},
      haveUserError: false,
    }
  },
  async mounted() {

  },
  computed: {
    hasError() {
      return Object.keys(this.errors).length > 0
    }
  },
  methods: {
    async nextStep() {
      let result = await this.checkCurrentStep()
      if (!result) { return }
      this.currentStep = Math.min(this.currentStep + 1, this.maxStep)
      this.updateStep()
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 0)
    },
    prevStep() {
      this.currentStep = Math.max(this.currentStep - 1, 1)
      this.updateStep()
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 0)
    },
    updateStep() {
      this.$refs.modal.scrollTop = 0
      for (let i = 1; i <= this.maxStep; i++) {
        const flag = i === this.currentStep
        this[`isStep${i}`] = flag
      }
    },
    checkCurrentStep() {
      return this[`checkStep${this.currentStep}`]()
    },
    async checkStep1() {
      this.errors = {}
      this.haveUserError = false
      let result = true
      let haveUserError = false

      if (!this.bag.operation_type) {
        this.errors['operation_type'] = true
        this.errors['operation_type_required'] = true
        result = false
      }

      if (!this.bag.user_id) {
        this.errors['user_id'] = true
        this.errors['user_id_required'] = true
        result = false
      }

      if (!this.bag.user_name) {
        this.errors['user_name'] = true
        this.errors['user_name_required'] = true
        result = false
      }

      if (!this.bag.size) {
        this.errors['size'] = true
        this.errors['size_required'] = true
        result = false
      }

      if (!this.bag.count) {
        this.errors['count'] = true
        this.errors['count_required'] = true
        result = false
      }

      if (!this.bag.bikou) {
        this.errors['bikou'] = true
        this.errors['bikou_required'] = true
        result = false
      }

      if (this.bag.user_id && this.bag.user_name) {
        await adminUser.getUser(this.bag.user_id).then(({ data }) => {
          let name = data.name1 + data.name2
          if (data.status == 90 || this.bag.user_name.replace(/[ 　]/g, "") !== name.replace(/[ 　]/g, "")) {
            haveUserError = true
          }
        }).catch(e => {
          haveUserError = true
        }).finally(v => {
          if (haveUserError) {
            this.errors['user_id'] = true
            result = false
            this.haveUserError = true
          }
        })
      }
      return result
    },
    checkStep2() {
      return true
    },
    addBag() {
      this.$emit('addBag', this.bag)
    }
  }
}
</script>

<style lang="scss" scoped>
  .modal-container {
    font-size: 18px;
  }
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    background-color: #000000;
    opacity: 0.3;
  }
  .modal {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
  }
  .modal-dialog {
    background-color: #ffffff;
    margin: 1.75rem auto;
    max-width: 70%;
    border-radius: 4px;
    overflow: hidden;
  }
  .modal-header {
    text-align: center;
    .title {
      padding: 0;
      line-height: 3.4;
      font-size: 1.2rem;
    }
  }
  .modal-body {
    padding: 16px 24px;
  }
  .modal-footer {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 8px;
  }

  .modal-btn {
    width: 160px;
    margin: 0 4px;
    padding: 8px 4px;
    height: 42px;
  }

  .modal-enter {
    opacity: 0;
  }
  .modal-enter-active {
    transition: opacity .0s ease;
  }
  .modal-enter-to {
    opacity: 1.0;
  }
  .modal-leave-active {
    opacity: 0;
  }
  .err-msg-area {
    margin: 30px auto 0;
    text-align: center;
    width: 90%;
  }
</style>
