<template>
<div class="contents_855">
  <header-bar active=""></header-bar>

  <div>
    <h1 class="title2 bg_orange">退会に関する注意事項</h1>
    <div class="contents_box">
      <div class="form_notes_left">
        <withdrawal-notice-messages :planPart1="planPart1"></withdrawal-notice-messages>
      </div>

      <div class="err-msg-area">
        <p><span class="font_orange" v-for="e in serverErrors">
          {{e}}
        </span></p>
        <p><span class="font_orange" v-show="isStatusWithdrawn">
          このアカウントは退会済みです
        </span></p>
      </div>

      <p class="submit_bx_l">
        <router-link :to="{name:'plan'}" class="submit1 bg_gray">
          戻る
        </router-link>
      </p>
      <p class="submit_bx_r">
        <button @click="deleteMe()" class="submit1 bg_orange">
          退会する
        </button>
      </p>

      <form id="payment-form" method="post"
          :action="paymentInfo.url" accept-charset="Shift_JIS">
        <input type="hidden" v-for="elem in paymentInfo.formParams"
          :name="elem.name" :value="elem.value">
        <input type="hidden" name="RETURL" :value="paymentInfo.retUrl">
      </form>
    </div>
  </div>
</div>
</template>

<script>
import userApi from '@/api/user'

export default {
  name: 'withdrawal',
  data() {
    return {
      paymentInfo: {
        url: '',
        formParams: [],
        retUrl: '',
      },
      isStatusWithdrawn: false,
      serverErrors: [],
      planPart1: '',
    }
  },
  mounted() {
    userApi.getMyCurrentPlan().then(({ data }) => {
      this.planPart1 = data.plan_id.split('_')[0]
    })
  },
  methods: {
    deleteMe() {
      this.isStatusWithdrawn = false
      this.serverErrors = []

      // お試しプランの場合、自動継続課金ではないので決済サイトは経由しない
      if (this.planPart1 === 't0') {
        this.withdrawalComplete()
        return
      }

      userApi.deleteMe(this.params)
        .then(({ data }) => {
          if (data.payment) {
            this.updatePaymentFormValues(data.payment)
            setTimeout(() => {
              const form = document.querySelector('#payment-form')
              form.submit()
            }, 0)
          } else {
              this.$router.push({
                name: 'withdrawal-complete'
              })
          }
        })
        .catch(e => {
          if (e.response.data.reason === 'status_withdrawn') {
            this.isStatusWithdrawn = true
            this.$store.dispatch('localStorage/set', {
              key: 'serverToken',
              val: null,
            })
            return
          }
          const errRes = e.response.data.errors;
          let serverErrors = []
          Object.keys(errRes).forEach(k => {
            serverErrors = serverErrors.concat(errRes[k])
          })
          this.serverErrors = serverErrors;
        })
    },
    updatePaymentFormValues(obj) {
      this.paymentInfo.url = obj.url
      this.paymentInfo.formParams = obj.form_params
      this.paymentInfo.retUrl = `${location.protocol}//${location.host}/${obj.ret_url_path}`
    },
    withdrawalComplete() {
      userApi.withdraw(this.params)
        .then(() => {
          this.$router.push({ name: 'withdrawal-complete' })
        })
        .catch(e => {
          const errRes = e.response.data.errors;
          let serverErrors = []
          Object.keys(errRes).forEach(k => {
            serverErrors = serverErrors.concat(errRes[k])
          })
          this.serverErrors = serverErrors;
        })
    },
  }
}
</script>

<style lang="scss" scoped>
.disabled {
  filter: alpha(opacity=5);
  -moz-opacity:0.5;
  opacity:0.5;
}
.err-msg-area {
  margin: 30px auto 0;
  text-align: center;
  width: 90%;
}
</style>
