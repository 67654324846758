<template>
<div>
  <div class="contents_855">
    <header-bar active=""></header-bar>
    <div class="contents_box2">
      <h1 class="title2">集配に関する注意事項</h1>
      <div class="contents_box">
        <div class="form_notes_left">
          <p class="item2">集荷、配達</p>
          <ul class="list mg10">
            <li><p>申込みプランの個数以上にご利用いただく場合は「追加で購入する」ボタンを押すと追加購入ができます</p></li>
            <li><p>宅配ボックスをご利用の場合は解除用のパスワードを記入ください</p></li>
            <li><p>集配時間の3時間前に確認メールが届きます</p></li>
            <li><p>集配時間の変更は集配予定時間の2時間前まで可能です</p></li>
            <li><p>集配時間の変更は後ろ倒しのみ可能です。前倒しはできません</p></li>
            <li><p>最短で集荷時間から休業日を除く48時間後に配達可能です</p></li>
            <li><p>集配同時プランの当日の集配依頼は11時までです</p></li>
            <li><p>集配時間は最大で2時間ほど前後する可能性があります</p></li>
            <li><p>不在の場合はメールまたは電話にて再集配の時間を調整します</p></li>
            <li><p>集荷の時間変更や不在により集荷予定日を含む2営業日以内に集荷が完了しない場合は自動的にキャンセルとなり、1個分としてカウントいたします</p></li>
          </ul>
        </div>
        <div class="form_notes_left">
          <p class="item2">洗濯、乾燥、たたみ</p>
          <ul class="list mg10">
            <li><p>縮み、破れ、型崩れ、色落ちなどが起こりうる衣類はバックに入れないよう注意ください</p></li>
            <li><p>ほつれ、縮み、破れなどがあっても、お客様の承諾が得られたものとして洗濯、乾燥いたします</p></li>
            <li><p>万が一、縮み、破れ、型崩れ、色落ちなどが発生した場合でも、当社は返金や弁償等、一切の責任を負いかねます</p></li>
            <li><p>ウール、革製品、シルク、スーツなどはお預かりできません</p></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'notice',
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
</style>
