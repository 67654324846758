import axios from 'axios'
import * as utils from './api-utils'

export default {
  index() {
    return axios.get(utils.getApiUrl('/admin/areaInfos'))
  },
  create(data) {
    return axios.post(utils.getApiUrl(`/admin/areaInfos/add`), data)
  },
  update(id, data) {
    return axios.post(utils.getApiUrl(`/admin/areaInfos/${id}`), data)
  },
  cancel(id) {
    return axios.delete(utils.getApiUrl(`/admin/areaInfos/${id}`))
  },
  getAreaInfo(obj) {
    return axios.get(utils.getApiUrl('/admin/areaInfo'), {
      params: obj || {}
    })
  },
}