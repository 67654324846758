<template>
<div class="contents">
  <admin adminType="staff" :id="id" :type="type" :searchPrm="searchPrm"/>
</div>
</template>
<script>
import Admin from './../Admin/AdminWashRequestCancel.vue'
export default {
  name: 'staff-wash-request-cancel',
  props: {
    id: {
      type: [String, Number],
      default: -1
    },
    type: {
      type: [String, Number],
      default: -1
    },
    searchPrm: {
      type: Object,
      'default': () => ({
        staffId: '',
        createdDtFrom: '',
        createdDtTo: '',
        filterWreqCreated: false,
        filterPickedUp: false,
        filterWashed: false,
        filterFolded: false,
        filterDelivered: false,
        userId: '',
        bagSize:'',
      })
    }
  },
  components: {
    Admin
  }
}
</script>