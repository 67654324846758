<template>
<ul class="glnavi tab_none sp_none">
  <li v-for="item in links">
    <a v-if="item.name == 'wash-request'" href='/wash-request' class="glnavi_btn" key=""
        :class="{glnavi_btn_on: active === item.name}">
      集配依頼
    </a>
    <router-link v-else :to="{name:item.name}"
        class="glnavi_btn"
        :class="{glnavi_btn_on: active === item.name}">
      {{item.label}}
    </router-link>
  </li>
</ul>
</template>

<script>
import vue from 'vue'
import userapi from '@/api/user'

export default {
  name: 'header-bar',
  props: {
    active: {
      type: String,
      default: 'wash-request'
    },
  },
  data() {
    return {
      links: [
        {
          name: 'wash-request',
          label: '集配依頼',
        },
        {
          name: 'wash-request-currents',
          label: '変更/キャンセル',
        },
        {
          name: 'wash-history',
          label: 'ご利用履歴',
        },
        {
          name: 'profile',
          label: 'お客様情報',
        },
        {
          name: 'plan',
          label: 'プラン変更',
        },
      ]
    }
  },
}
</script>
