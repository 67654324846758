import userApi from '@/api/user'
import * as types from '../mutation-types'

// the definition of initial state is required.
const state = {
  id: -1,
  email: '',
  name1: '',
  name2: '',
  kana1: '',
  kana2: '',
  company_name: '',
  bill_addr_zipcode: '',
  bill_addr_detail1: '',
  bill_addr_detail2: '',
  bill_addr_detail3: '',
  send_addr_zipcode: '',
  send_addr_detail1: '',
  send_addr_detail2: '',
  send_addr_detail3: '',
  phone_number: '',
  bikou1: '',
  available_request_count: 0,
  ac_user_id: '',
  withdrawal_dt: '',
  env: '',
}

const getters = {
  isLoggedIn: state => state.id !== -1
}

let promiseOrNull = null
const getPromiseOfGetMe = () => promiseOrNull
const setPromiseOfGetMe = (/** @type {Promise<any> | null} */ promise) => {
  promiseOrNull = promise
}

const actions = {
  getMe({ commit, state, getters }, { forceRefresh } = {}) {
    if (!forceRefresh && getters.isLoggedIn) {
      return Promise.resolve(Object.assign({}, state))
    }
    if (!getPromiseOfGetMe()) {
      setPromiseOfGetMe(userApi.getMe())
    }
    return getPromiseOfGetMe()
      .then(({ data }) => {
        commit(types.SET_LOGIN_USER, { data })
        return Object.assign({}, state)
      })
  },
  login({ commit, state }, data) {
    return userApi.login(data)
      .then(({ data }) => {
        commit(types.SET_LOGIN_USER, { data })
        return Object.assign({}, state)
      })
  },
  logout({ commit }) {
    return userApi.logout()
      .then(() => {
        commit(types.CLEAR_LOGIN_USER)
      })
  }
}

const mutations = {
  [types.SET_LOGIN_USER](state, { data }) {
    Object.keys(data).forEach(k => {
      state[k] = data[k]
    })
  },
  [types.CLEAR_LOGIN_USER](state) {
    state.id = -1
    state.email = ''
    state.name1 = ''
    state.name2 = ''
    state.kana1 = ''
    state.kana2 = ''
    state.company_name = ''
    state.bill_addr_zipcode = ''
    state.bill_addr_detail1 = ''
    state.bill_addr_detail2 = ''
    state.bill_addr_detail3 = ''
    state.send_addr_zipcode = ''
    state.send_addr_detail1 = ''
    state.send_addr_detail2 = ''
    state.send_addr_detail3 = ''
    state.phone_number = ''
    state.bikou1 = ''
    state.available_request_count = 0
    state.ac_user_id = ''
    state.withdrawal_dt = ''
    state.env = ''
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
