var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contents_855"},[_c('header-bar',{attrs:{"active":"profile"}}),_c('div',[_c('h1',{staticClass:"title2"},[_vm._v("お客様情報")]),_c('div',{staticClass:"contents_box mg20"},[_c('table',{staticClass:"form_tab form_line_top"},[_c('tr',[_vm._m(0),_c('td',[_c('p',[_c('span',[_vm._v(_vm._s(_vm.name1))]),_c('span',[_vm._v(_vm._s(_vm.name2))])])])]),_c('tr',[_vm._m(1),_c('td',[_c('p',[_c('span',[_vm._v(_vm._s(_vm.kana1))]),_c('span',[_vm._v(_vm._s(_vm.kana2))])])])]),_c('tr',[_vm._m(2),_c('td',[_c('p',[_vm._v(_vm._s(_vm.companyName))])])]),_c('tr',[_vm._m(3),_c('td',[_c('p',[_vm._v("〒"+_vm._s(_vm.billAddrZipcodeDisp))]),_c('p',[_vm._v(_vm._s(_vm.billAddrDetail1)+_vm._s(_vm.billAddrDetail2))]),_c('p',[_vm._v(_vm._s(_vm.billAddrDetail3))])])]),_c('tr',[_vm._m(4),_c('td',[_c('p',[_vm._v(_vm._s(_vm.phoneNumber))])])]),_c('tr',[_vm._m(5),_c('td',[_c('p',[_vm._v(_vm._s(_vm.email))])])]),_c('tr',[_vm._m(6),_c('td',[_c('p',[_vm._v(_vm._s(_vm.email2))])])]),_c('tr',[_vm._m(7),_c('td',[_c('p',[_vm._v(_vm._s(_vm.invitation_code))])])]),_c('tr',[_vm._m(8),_c('td',{staticClass:"form_line_r"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$options.filters.lineBreakToBR(_vm.bikou1))}})])])]),_c('p',{staticClass:"submit_bx_l"},[_c('router-link',{staticClass:"submit1 bg_green",attrs:{"to":{name:'profile-edit'}}},[_vm._v(" お客様情報の変更 ")])],1),_c('p',{staticClass:"submit_bx_r"},[_c('router-link',{staticClass:"submit1 bg_green",attrs:{"to":{name:'password-change'}}},[_vm._v(" パスワード変更 ")])],1)])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('p',{staticClass:"item2"},[_vm._v("お名前")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('p',{staticClass:"item2"},[_vm._v("おなまえ（ひらがな）")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('p',{staticClass:"item2"},[_vm._v("会社/店舗名")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('p',{staticClass:"item2"},[_vm._v("集配先住所")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('p',{staticClass:"item2"},[_vm._v("電話番号")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('p',{staticClass:"item2"},[_vm._v("メールアドレス①")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('p',{staticClass:"item2"},[_vm._v("メールアドレス②")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('p',{staticClass:"item2"},[_vm._v("招待コード")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{staticClass:"form_line_l"},[_c('p',{staticClass:"item2"},[_vm._v("備考")])])
}]

export { render, staticRenderFns }