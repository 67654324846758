function checkUserParams(params, areainfos, opts = {}) {
  let result = true
  if (!params.name1 || !params.name2) {
    this.errors['name'] = true
    this.errors['name_required'] = true
    result = false
  }
  if (!params.kana1 || !params.kana2) {
    this.errors['kana'] = true
    this.errors['kana_required'] = true
    result = false
  }

  const requiredParams = [
    'bill_addr_zipcode',
    'bill_addr_detail1',
    'bill_addr_detail2',
    'phone_number',
    'email',
    'email_confirm',
  ]
  if (!opts.noPasswordCheck) {
    requiredParams.push('password', 'password_confirm')
  }

  const confirmableParams = [
    'email',
    'email2',
  ]
  if (!opts.noPasswordCheck) {
    confirmableParams.push('password')
  }

  const zipcodeParams1 = [
    'bill_addr_zipcode',
  ]

  const zipcodeParams2 = [
    'send_addr_zipcode',
  ]

  requiredParams.forEach(prop => {
    if (!params[prop]) {
      this.errors[prop] = true
      this.errors[`${prop}_required`] = true
      result = false
    }
  })

  confirmableParams.forEach(prop => {
    const val1 = params[prop]
    const val2 = params[`${prop}_confirm`]
    if (val1 !== val2) {
      this.errors[`${prop}_confirm`] = true
      this.errors[`${prop}_confirm_unmatch`] = true
      result = false
    }
  })

  if (params['password']) {
    const errObj = checkValidPassword(params['password'])
    if (Object.keys(errObj).length > 0) {
      Object.keys(errObj).forEach(k => {
        this.errors[k] = errObj[k]
      })
      result = false
    }
  }

  zipcodeParams1.forEach(prop => {
    const val = params[prop]
    if (val && !val.match(/\d{7}/)) {
      this.errors[`${prop}`] = true
      this.errors[`${prop}_invalid`] = true
      result = false
    }

    // 東京都の郵便番号は上2桁が10~20で決まっているようなので、それ以外は提供エリア外とする
    // →提供エリア設定で管理するように変更
    if (val) {
      // let zc2 = val.slice(0,2)
      // if (10 > zc2 || zc2 > 20) {
      //   this.errors[`${prop}`] = true
      //   this.errors[`${prop}_notarea`] = true
      //   result = false
      // }

      let areaCheck = false
      const planInfos = params.user_plan.plan_id.split('_')
      const isOnetime = planInfos[0] == 'tx' ? true : false
      areainfos.forEach(info => {
        if (val.slice(0, 5) == info.zipcode) {
          if (isOnetime && info.is_valid_onetime == 1) {
            areaCheck = true
            return
          } else if (!isOnetime && info.is_valid_monthly == 1) {
            areaCheck = true
            return
          }
        }
      })
      if (!areaCheck) {
        this.errors[`${prop}`] = true
        this.errors[`${prop}_notarea`] = true
        result = false
      }
    }
  })

  zipcodeParams2.forEach(prop => {
    const val = params[prop]
    if (val && !val.match(/\d{7}/)) {
      this.errors[`${prop}`] = true
      this.errors[`${prop}_invalid`] = true
      result = false
    }
  })

  return result
}

function checkArea(params, areainfos) {
  let result = true

  // 郵便番号が取得できない場合はエラー
  if (!params.zipcode) {
    return false
  }
  // 郵便番号が7桁でない場合はエラー
  if (!params.zipcode.match(/\d{7}/)) {
    return false
  }

  const planInfos = params.plan_id.split('_')
  const isOnetime = planInfos[0] == 'tx' ? true : false
  areainfos.forEach(info => {
    if (params.zipcode.slice(0, 5) == info.zipcode) {
      if (isOnetime && info.is_valid_onetime == 0) {
        result = false
        return
      } else if (!isOnetime && info.is_valid_monthly == 0) {
        result = false
        return
      }
    }
  })

  return result
}

function checkValidPassword(pw) {
  const ret = {}
  const allowedReg = /^[a-zA-Z0-9-_!@#\$%^&\*\(\)+=~`\{\}\[\]'":;\/?,.<>]{8,}$/

  if (!pw.match(allowedReg)) {
    ret.password_invalid = true
  }

  return ret
}

export default {
  methods: {
    checkUserParams,
    checkArea,
    checkValidPassword,
  }
}
