<template>
<div class="contents">
  <admin adminType="staff" :id="id"/>
</div>
</template>
<script>
import Admin from './../Admin/AdminUserListDelete.vue'
export default {
  name: 'staff-user-list-delete',
  props: {
    id: {
      type: [String, Number],
      default: -1
    }
  },
  components: {
    Admin
  }
}
</script>