<template>
<transition name="modal">
<div class="modal-container">
  <div class="modal-backdrop"></div>
  <div class="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="title">対応済みにします</h2>
        </div>
        <div class="modal-body" ref="modal">
          <div v-show="isStep1">
            <div>
              <span>{{wreq.status}}　→　{{wreq.next_status}}</span>
            </div>
            <div>
              <input type="datetime-local" step="600" v-model="dtStr" value="dtStr">
              <button v-if="counter < 5" class="btn_sm"
                @click="addStaff()">
                担当者追加
              </button>
            </div>
            <table class="staff-list">
              <tr v-for="(form, index) in forms" :key="form.id" v-bind="form">
                <th class="form_line_bt">
                  <p>
                    <span v-if="index == 0" class="staff-name-label">{{loginUser.name}}</span>
                    <select v-else v-model="staffIdList[index]">
                      <option value="">選択してください</option>
                      <option v-for="elem in getStaffs" v-bind:value="elem.id">
                        {{elem.name1}} {{elem.name2}}
                      </option>
                    </select>
                  </p>
                </th>
                <td class="form_line_bt">
                  <div>
                    <p>
                      <select v-model="staffCountList[index]">
                        <option v-for="elem in getCountList" v-bind:value="elem">
                          {{elem}}
                        </option>
                      </select>
                    </p>
                    <div class="delete_form">
                      <button @click="deleteForm(index)" v-show="deleteForms[index]">×</button>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
            <div class="err-msg-area" v-show="hasError">
              <span class="iferror" v-show="errors.staff_required">
                ▲ スタッフを選択してください
              </span>
              <span class="iferror" v-show="errors.count_required">
                ▲ 個数を選択してください
              </span>
              <span class="iferror" v-show="errors.same_staff_set">
                ▲ スタッフが重複しています
              </span>
              <span class="iferror" v-show="errors.count_unmatch">
                ▲ 個数の合計がステータスと異なります
              </span>
            </div>
            <div class="modal-footer">
              <button class="submit1 bg_gray modal-btn"
                @click="$emit('dismiss')">
                キャンセル
              </button>
              <button class="submit1 bg_green modal-btn btn-primary"
                @click="nextStep()">
                {{btnText}}
              </button>
            </div>
          </div>
          <div v-show="isStep2">
            <div>
              <span>{{wreq.status}}　→　{{wreq.next_status}}</span>
              <p>{{dtStr | dtFormat('yyyy/mm/dd HH:MM')}}</p>
            </div>
            <table v-if="counter !== 0" class="staff-list">
              <tr v-for="n of counter" :key="n">
                <th class="staff-name-label">{{getStaffName(staffIdList[n -1])}}</th>
                <td>{{staffCountList[n -1]}}</td>
              </tr>
            </table>
            <table v-else class="staff-list">
              <tr>
                <th class="staff-name-label">{{loginUser.name}}</th>
                <td>{{wreq.count}}</td>
              </tr>
            </table>
            <div class="err-msg-area" v-show="hasError">
              <span class="iferror" v-show="errors.staff_required">
                ▲ スタッフを選択してください
              </span>
              <span class="iferror" v-show="errors.count_required">
                ▲ 個数を選択してください
              </span>
              <span class="iferror" v-show="errors.same_staff_set">
                ▲ スタッフが重複しています
              </span>
              <span class="iferror" v-show="errors.count_unmatch">
                ▲ 個数の合計がステータスと異なります
              </span>
            </div>
            <div class="modal-footer">
              <button class="submit1 bg_gray modal-btn"
                @click="prevStep()">
                戻る
              </button>
              <button class="submit1 bg_green modal-btn btn-primary"
                  @click="setDate()">
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</transition>
</template>

<script>
import Vue from 'vue'
export default {
  name: 'admin-wash-request-add-staff-modal',
  props: {
    staffs: {
      type: Array,
      default: []
    },
    wreq: {
      type: Object,
      default () {
        return {}
      }
    },
    loginUser: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data() {
    return {
      staff: [],
      dtStr: '',
      counter: 0,
      forms:[],
      staffIdList: [],
      staffCountList: [],
      deleteForms: [],
      btnText: '更新',

      currentStep: 1,
      maxStep: 2,
      isStep1: true,
      isStep2: false,
      errors: {},
    }
  },
  async mounted() {
    const dtFormat = Vue.filter('dtFormat')
    this.dtStr = dtFormat(new Date(), 'yyyy-mm-ddTHH:MM')
  },
  computed: {
    hasError() {
      return Object.keys(this.errors).length > 0
    },
    getCountList() {
      let countList = []
      for (let index = 0; index <= this.wreq.count; index++) {
        countList.push(index)
      }
      return countList
    },
    getStaffs() {
      // 集配メンバー以外を返却
      return this.staffs.filter(s => {
        if (s.role = 200 && s.role_type == 4) {
          return false
        }
        return true
      })
    }
  },
  methods: {
    async nextStep() {
      let result = await this.checkCurrentStep()
      if (!result) { return }
      this.currentStep = Math.min(this.currentStep + 1, this.maxStep)
      this.updateStep()
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 0)
    },
    prevStep() {
      this.currentStep = Math.max(this.currentStep - 1, 1)
      this.updateStep()
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 0)
    },
    updateStep() {
      this.$refs.modal.scrollTop = 0
      for (let i = 1; i <= this.maxStep; i++) {
        const flag = i === this.currentStep
        this[`isStep${i}`] = flag
      }
    },
    checkCurrentStep() {
      return this[`checkStep${this.currentStep}`]()
    },
    async checkStep1() {
      this.errors = {}
      let result = true

      if (this.counter !== 0) {
        let total_count = 0
        for (let i = 0; i < this.counter; i++) {
          let id = this.staffIdList[i]
          let count = this.staffCountList[i]
          if (id === "") {
            this.errors['staff_required'] = true
            result = false
          }
          if (count === 0) {
            this.errors['count_required'] = true
            result = false
          }
          total_count += count
        }
        if (new Set(this.staffIdList).size !== this.staffIdList.length) {
          this.errors['same_staff_set'] = true
          result = false
        }
        if (total_count !== this.wreq.count) {
          this.errors['count_unmatch'] = true
          result = false
        }
      }

      return result
    },
    checkStep2() {
      return true
    },
    addStaff() {
      this.btnText = '確認画面'
      if (this.counter == 0) {
        this.forms = [{
          id:0,name:0
        }]
        this.staffIdList.push(this.loginUser.id)
        this.staffCountList.push(this.wreq.count)
        this.deleteForms.push(false)
        this.counter = this.counter + 1
      }
      let max = this.forms.reduce(function(a,b) {
        return a > b.id ? a : b.id
      },0)
      this.forms[this.counter] = {
        id:max + 1,
        name:max + 1,
      }
      this.staffIdList[this.counter] = ""
      this.staffCountList[this.counter] = 0
      this.deleteForms[this.counter] = true
      this.deleteForms[this.counter -1] = false

      this.counter = this.counter + 1
    },
    deleteForm (index) {
      this.forms.splice(index, 1)
      this.deleteForms[this.counter] = false

      delete this.staffIdList[this.counter -1]
      delete this.staffCountList[this.counter -1]

      this.counter = this.counter - 1

      if (this.counter === 1) {
        this.staffIdList = [this.loginUser.id]
        this.staffCountList = [this.wreq.count]
      } else {
        this.deleteForms[this.counter -1] = true
      }
    },
    setDate() {
      let staffList = []
      if (this.counter !== 0) {
        for (let index = 0; index < this.counter; index++) {
        let staff = {
          id: this.staffIdList[index],
          count: this.staffCountList[index],
        }
        staffList.push(staff)
        }
      } else {
        let staff = {
          id: this.loginUser.id,
          count: this.wreq.count
        }
        staffList.push(staff)
      }
      this.$emit('setDate', staffList, this.dtStr)
    },
    getStaffName(id) {
      let staff = this.getStaffs.find(s => s.id == id)
      if (staff) {
        return staff.name1 + ' ' + staff.name2
      }
      return ''
    },
  }
}
</script>

<style lang="scss" scoped>
  .modal-container {
    font-size: 18px;
  }
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    background-color: #000000;
    opacity: 0.3;
  }
  .modal {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
  }
  .modal-dialog {
    background-color: #ffffff;
    margin: 1.75rem auto;
    max-width: 90%;
    max-height: 90%;
    border-radius: 4px;
    overflow: hidden;
  }
  @media screen and (min-width: 800px){
    .modal-dialog {
      max-width: 70%;
    }
  }
  .modal-header {
    text-align: center;
    .title {
      padding: 0;
      line-height: 3.4;
      font-size: 1.2rem;
    }
  }
  .modal-body {
    padding: 16px 24px;
  }
  .modal-footer {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 8px;
  }
  .modal-btn {
    width: 160px;
    margin: 0 4px;
    padding: 8px 4px;
    height: 42px;
  }
  .modal-enter {
    opacity: 0;
  }
  .modal-enter-active {
    transition: opacity .0s ease;
  }
  .modal-enter-to {
    opacity: 1.0;
  }
  .modal-leave-active {
    opacity: 0;
  }

  .btn_sm {
    border-radius: 4px;
    background-color: #ec6d71;
    color: #ffffff;
    font-size: 12px;
    padding: 2px 6px;
    cursor: pointer;
    margin-left: 10px;
  }
  .staff-name-label {
    font-weight:normal;
    padding-left: 5px;
  }
  .staff {
    font-weight: normal;
  }
  .staff-list {
    width: 300px;
    margin-top: 10px;
  }
  .staff-list select {
    width: 100%;
  }
  .staff-list tr {
    width: 200px;
  }
  .staff-list th {
    width: 70%;
  }
  .staff-list td {
    width: 30%;
  }
  .staff-list td div {
    display: flex;
  }
  .staff-list td p {
    width: 70%;
  }
  .delete_form{
    text-align: right;
    width: 30%;
  }
  .delete_form button {
    font-size: 20px;
    background: none;
    align-items: center;
    margin-bottom: 10px;
  }
  .err-msg-area {
    margin: 30px auto 0;
    text-align: center;
    width: 90%;
  }

</style>
