export default {
  methods: {
    isGuestPage() {
      const arr = [
        'login',
        'register',
        'password-forgot',
        'password-reset',
        'register-complete',
        'onetime-register-complete',
        'maintenance'
      ]
      return arr.indexOf(this.$route.name) !== -1
    },
    isAdminPage() {
      return this.$route.name.match(/admin/)
    },
    isStaffPage() {
      return this.$route.name.match(/staff/)
    },
  }
}
