<template>
<div class="contents">
  <admin adminType="staff" :id="id" :searchPrm="searchPrm"/>
</div>
</template>
<script>
import Admin from './../Admin/AdminPlanEdit.vue'
export default {
  name: 'staff-plan-edit',
  props: {
    id: {
      type: [String, Number],
      default: -1
    },
    searchPrm: {
      type: Object,
      'default': () => ({
        registerDtFrom: '',
        registerDtTo: '',
        userId: '',
        name: '',
        kana: '',
        billAddrZipcode: '',
        phoneNumber: '',
        email: '',
        status: '',
      })
    }
  },
  components: {
    Admin
  }
}
</script>