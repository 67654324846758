export const monthOptions = [
  1,2,3,4,5,6,7,8,9,10,11,12
]
export const dateOptions = [
  1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,
  21,22,23,24,25,26,27,28,29,30,31
]
export const hourOptions = [
  {val: 10, disp: '10'},
  {val: 11, disp: '11'},
  {val: 12, disp: '12'},
  {val: 13, disp: '13'},
  {val: 14, disp: '14'},
  {val: 15, disp: '15'},
  {val: 16, disp: '16'},
  {val: 17, disp: '17'},
  {val: 18, disp: '18'},
  {val: 19, disp: '19'},
  {val: 20, disp: '20'},
  {val: 21, disp: '21'},
  {val: 22, disp: '22'},
]
export const minuteOptions = [
  {val:  0, disp: '00'},
  {val: 15, disp: '15'},
  {val: 30, disp: '30'},
  {val: 45, disp: '45'},
]
