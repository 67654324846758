<template>
<transition name="modal">
<div class="modal-container">
  <div class="modal-backdrop"></div>
  <div class="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="title">業務報告</h2>
        </div>
        <div class="modal-body" ref="modal">
          <div v-show="isStep1">
            <table class="form_tab">
              <tr>
                <th><p class="item2">業務開始<span class="font_orange">*</span></p></th>
                <td class="date">
                  <vuejs-datepicker
                    v-model="report.start_date"
                    class="date_input"
                    placeholder="date"
                    :format="DatePickerFormat"
                    :clear-button="true" :class="{error_outline: this.errors.start_dt}">
                  </vuejs-datepicker>
                  <vue-timepicker
                    v-model="report.start_time"
                    class="time_input"
                    input-width="175px"
                    placeholder="time"
                    minute-interval="10"
                    close-on-complete
                    :class="{error_outline: this.errors.start_dt}">
                  </vue-timepicker>
                  <span class="iferror" v-show="errors.start_dt_required">
                    ▲ 必須項目です
                  </span>
                </td>
              </tr>
              <tr>
                <th><p class="item2">業務終了<span class="font_orange">*</span></p></th>
                <td class="date">
                  <vuejs-datepicker
                    v-model="report.end_date"
                    class="date_input"
                    placeholder="date"
                    :format="DatePickerFormat"
                    :clear-button="true" :class="{error_outline: this.errors.end_dt}">
                  </vuejs-datepicker>
                  <vue-timepicker
                    v-model="report.end_time"
                    class="time_input"
                    input-width="175px"
                    placeholder="time"
                    minute-interval="10"
                    close-on-complete
                    :class="{error_outline: this.errors.end_dt}">
                  </vue-timepicker>
                  <span class="iferror" v-show="errors.end_dt_required">
                    ▲ 必須項目です
                  </span>
                  <span class="iferror" v-show="errors.date_compare">
                    ▲ 終了日時は開始日時より後にしてください
                  </span>
                </td>
              </tr>
              <tr>
                <th><p class="item2">実稼働時間<span class="font_orange">*</span></p></th>
                <td class="date">
                  <vue-timepicker
                    v-model="report.work_time"
                    class="time_input"
                    input-width="175px"
                    placeholder="time"
                    minute-interval="10"
                    close-on-complete
                    :class="{error_outline: this.errors.work_time}">
                  </vue-timepicker>
                  <span class="iferror" v-show="!calculateSuccess">
                    ▲ 設定された日時が不正です
                  </span>
                  <span class="iferror" v-show="errors.work_time_required">
                    ▲ 必須項目です
                  </span>
                </td>
              </tr>
              <tr>
                <th><p class="item2">担当ステータス<span class="font_orange">*</span></p></th>
                <td>
                  <p>
                    <select v-model="report.status" class="w200"
                        :class="{error_outline: this.errors.status}">
                      <option value="">選択してください</option>
                      <option v-for="elem in getStatus" v-bind:value="elem.key">
                        {{elem.val}}
                      </option>
                    </select>
                  </p>
                  <p>
                    <span class="iferror" v-show="errors.status_required">
                      ▲ 必須項目です
                    </span>
                  </p>
                </td>
              </tr>
              <tr>
                <th><p class="item2">担当者<span class="font_orange">*</span></p></th>
                <td>
                  <p>
                    <select v-model="report.user_id" class="w200"
                        :class="{error_outline: this.errors.user_id}">
                      <option value="">選択してください</option>
                      <option v-for="elem in getStaffs" v-bind:value="elem.id">
                        {{elem.name1}} {{elem.name2}}
                      </option>
                    </select>
                  </p>
                  <p>
                    <span class="iferror" v-show="errors.user_id_required">
                      ▲ 必須項目です
                    </span>
                  </p>
                </td>
              </tr>
            </table>
            <div class="err-msg-area" v-show="hasError">
              <span class="font_orange">
                入力エラーがあります
              </span>
            </div>
            <div class="modal-footer">
              <button class="submit1 bg_yellow modal-btn btn-primary"
                @click="nextStep()">
                登録する
              </button>
              <button class="submit1 bg_gray modal-btn"
                @click="$emit('dismiss')">
                キャンセル
              </button>
            </div>
          </div>
          <div v-show="isStep2">
            <p class="mypage_notes mg30 mg_bt30 font_orange">
              下記の情報でよろしければ送信してください。
            </p>
            <table class="form_tab form_line_top">
              <tr>
                <th><p class="item2">業務開始<span class="font_orange">*</span></p></th>
                <td><p>{{report.start_date | dtFormat('m月d日（a）')}}{{report.start_time}}</p>
                </td>
              </tr>
              <tr>
                <th><p class="item2">業務終了<span class="font_orange">*</span></p></th>
                <td><p>{{report.end_date | dtFormat('m月d日（a）')}}{{report.end_time}}</p>
                </td>
              </tr>
              <tr>
                <th><p class="item2">実稼働時間<span class="font_orange">*</span></p></th>
                <td><p>{{report.work_dt | dtFormat('H時間MM分')}}</p>
                </td>
              </tr>
              <tr>
                <th><p class="item2">対象ステータス<span class="font_orange">*</span></p></th>
                <td><p>{{getDispStatus()}}</p>
                </td>
              </tr>
              <tr>
                <th><p class="item2">担当者<span class="font_orange">*</span></p></th>
                <td><p>{{getDispStaff()}}</p>
                </td>
              </tr>
            </table>
            <div class="modal-footer">
              <button class="submit1 bg_yellow modal-btn btn-primary"
                  @click="workReport()">
                OK
              </button>
              <button class="submit1 bg_gray modal-btn"
                @click="prevStep()">
                戻る
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</transition>
</template>

<script>
import Vue from 'vue'
import Datepicker from "vuejs-datepicker"
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
export default {
  name: 'admin-wash-request-work-report-modal',
  props: {
    staffs: {
      type: Array,
      default: []
    },
    status: {
      type: Array,
      default: []
    }
  },
  components: {
		'vuejs-datepicker':Datepicker,
		'vue-timepicker': VueTimepicker,
	},
  data() {
    return {
      report: {
				start_dt: '',
				start_date: new Date(),
				start_time: '',
				end_dt: '',
				end_date: new Date(),
				end_time: '',
        work_dt: '',
        work_time: '',
        work_minute: 0,
        status: '',
        user_id: ''
      },
			DatePickerFormat: 'yyyy-MM-dd',
      calculateSuccess: true,
      dtFormat: '',
      currentStep: 1,
      maxStep: 2,
      isStep1: true,
      isStep2: false,
      errors: {},
    }
  },
  async mounted() {
  },
  created() {
    this.dtFormat = Vue.filter('dtFormat')
  },
  computed: {
    hasError() {
      return Object.keys(this.errors).length > 0
    },
    getStatus() {
      // 洗い済、たたみ済のみを返却
      return this.status.filter(s => {
        if (['2', '4'].includes(s.key)) {
          return true
        }
        return false
      })
    },
    getStaffs() {
      // 管理者、リーダー、洗い・たたみメンバーのみを返却
      return this.staffs.filter(s => {
        if (s.role == 100 || (s.role = 200 && [2,3].includes(s.role_type))) {
          return true
        }
        return false
      })
    }
  },
  methods: {
    async nextStep() {
      let result = await this.checkCurrentStep()
      if (!result) { return }
      this.currentStep = Math.min(this.currentStep + 1, this.maxStep)
      this.updateStep()
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 0)
    },
    prevStep() {
      this.currentStep = Math.max(this.currentStep - 1, 1)
      this.updateStep()
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 0)
    },
    updateStep() {
      this.$refs.modal.scrollTop = 0
      for (let i = 1; i <= this.maxStep; i++) {
        const flag = i === this.currentStep
        this[`isStep${i}`] = flag
      }
    },
    checkCurrentStep() {
      return this[`checkStep${this.currentStep}`]()
    },
    getDate() {
			this.report.start_date = this.dtFormat(this.report.start_date, 'yyyy-mm-dd')
			this.report.end_date = this.dtFormat(this.report.end_date, 'yyyy-mm-dd')
      const start_dt = new Date(this.report.start_date + " " + this.report.start_time)
			const end_dt = new Date(this.report.end_date + " " + this.report.end_time)

      return [start_dt, end_dt]
    },
    async checkStep1() {
      this.errors = {}
      let result = true
      this.calculateSuccess = true

      let date = this.getDate()
			const now = new Date()
			const invalid_start_time = isNaN(date[0].getTime())
			const invalid_end_time = isNaN(date[1].getTime())
      const work_time = new Date(this.dtFormat(now, 'yyyy-mm-dd') + " " + this.report.work_time)
      this.report.work_dt = work_time

			const invalid_work_time = isNaN(work_time.getTime())

			if ((!this.report.start_date || !this.report.start_time)
        || (this.report.start_date && this.report.start_time && invalid_start_time)) {
				this.errors['start_dt'] = true
				this.errors['start_dt_required'] = true
				result = false
      }

      if ((!this.report.end_date || !this.report.end_time)
        || (this.report.end_date && this.report.end_time && invalid_end_time)) {
				this.errors['end_dt'] = true
				this.errors['end_dt_required'] = true
				result = false
			}

			if (this.report.start_date && this.report.end_date) {
				if (this.report.start_date > this.report.end_date) {
					this.errors['start_dt'] = true
					this.errors['end_dt'] = true
					this.errors['date_compare'] = true
					result = false
				}

				if ((this.report.start_date === this.report.end_date) && (this.report.start_time > this.report.end_time)) {
					this.errors['start_dt'] = true
					this.errors['end_dt'] = true
					this.errors['date_compare'] = true
					result = false
				}
			}

			if ((!this.report.work_time)
        || (this.report.work_time && invalid_work_time)) {
				this.errors['work_time'] = true
				this.errors['work_time_required'] = true
				result = false
      } else {
        let [hours, minutes] = this.report.work_time.split(':').map(Number);
        this.report.work_minute = (hours * 60) + minutes;
      }

      if (!this.report.status) {
        this.errors['status'] = true
        this.errors['status_required'] = true
        result = false
      }

      if (!this.report.user_id) {
        this.errors['user_id'] = true
        this.errors['user_id_required'] = true
        result = false
      }

      return result
    },
    checkStep2() {
      return true
    },
    getDispStatus() {
      if (!this.report.status) return ''
      // 対象のステータスを返却
      let status_info = this.status.find(s => s.key == this.report.status)
      return status_info.val
    },
    getDispStaff() {
      if (!this.report.user_id) return ''
      // 対象のスタッフ名を返却
      let staff_info = this.staffs.find(s => s.id == this.report.user_id)
      return staff_info.name1 + ' ' + staff_info.name2
    },
    workReport() {
      let date = this.getDate()
			const start_dt = this.dtFormat(this.report.start_date, 'yyyy-mm-dd') + " " + this.report.start_time
			const end_dt = this.dtFormat(this.report.end_date, 'yyyy-mm-dd') + " " + this.report.end_time
      const data = {
				start_dt: start_dt,
				end_dt: end_dt,
        work_time: this.report.work_time,
        work_minute: this.report.work_minute,
        status: this.report.status,
        user_id: this.report.user_id,
      }
      this.$emit('workReport', data)
    }
  }
}
</script>

<style lang="scss" scoped>
  .modal-container {
    font-size: 18px;
  }
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    background-color: #000000;
    opacity: 0.3;
  }
  .modal {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
  }
  .modal-dialog {
    background-color: #ffffff;
    margin: 1.75rem auto;
    max-width: 90%;
    max-height: 90%;
    border-radius: 4px;
    overflow: hidden;
  }
  @media screen and (min-width: 800px){
    .modal-dialog {
      max-width: 70%;
    }
  }
  .modal-header {
    text-align: center;
    .title {
      padding: 0;
      line-height: 3.4;
      font-size: 1.2rem;
    }
  }
  .modal-body {
    padding: 16px 24px;
  }
  .modal-footer {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 8px;
  }
  .modal-btn {
    width: 160px;
    margin: 0 4px;
    padding: 8px 4px;
    height: 42px;
  }
  .modal-enter {
    opacity: 0;
  }
  .modal-enter-active {
    transition: opacity .0s ease;
  }
  .modal-enter-to {
    opacity: 1.0;
  }
  .modal-leave-active {
    opacity: 0;
  }

  .err-msg-area {
    margin: 30px auto 0;
    text-align: center;
    width: 90%;
  }

  .date_input {
    position: unset;
  }

</style>
