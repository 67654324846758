<template>
<div class="contents_855">
  <h1 class="title2">{{type == 1 ? '集荷のキャンセル' : '集配の破棄'}}</h1>

  <div class="contents_box" v-show="!isCancelDone">
    <p class="mypage_notes mg30 mg_bt30">
      <span class="font_orange">
        {{type == 1 ? '下記の集荷をキャンセルしてよろしいですか？' : '下記の集配を破棄してよろしいですか？'}}
      </span>
    </p>

    <p class="title3">集配{{washReq.dispNum}}</p>
    <table class="form_tab form_line_top">
      <tr>
        <th class="form_line_l"><p class="item2">集配日時</p></th>
        <td class="form_line_r">
          <p>
            集荷：{{washReq.sched_pickup_dt | dtFormat('m月d日（a）HH：MM')}}
          </p>
          <span v-if="pdSameTime">
            <p class="mg10">
              <span class="font_orange">次回集荷時に併せて配達します。</span>
            </p>
          </span>
          <span v-else>
            <p class="mg10">
              配達：{{washReq.sched_delivery_dt | dtFormat('m月d日（a）HH：MM')}}
            </p>
          </span>
        </td>
      </tr>
    </table>
    <p class="submit_bx_l">
      <button class="submit1 bg_gray"
        @click="backToWreqList()">
        戻る
      </button>
    </p>
    <p class="submit_bx_r">
      <button @click="cancelWashRequest()" class="submit1 bg_green">
        OK
      </button>
    </p>
  </div>

  <div class="contents_box" v-show="isCancelDone">
    <p class="mypage_notes mg40 mg_bt40 font_orange">
      {{type == 1 ? '集荷をキャンセルしました。' : '集配を破棄しました。'}}
    </p>
    <p v-show="type == 1" class="mypage_notes mg40">
      ユーザーのメールアドレス①に確認のメールを送信しました。
    </p>
    <p class="submit_bx1">
      <button class="glnavi_btn_on"
        @click="backToWreqList()">
        集配一覧
      </button>
    </p>
  </div>
</div>
</template>

<script>
import Vue from 'vue'
import washRequestMixin from '@/mixin/washRequestMixin'
import adminWashRequestApi from '@/api/adminWashRequest'
import adminUserApi from '@/api/adminUser'
export default {
  name: 'admin-wash-request-cancel',
  props: {
    id: {
      type: [String, Number],
      default: -1
    },
    type: {
      type: [String, Number],
      default: -1
    },
    searchPrm: {
      type: Object,
      'default': () => ({
        staffId: '',
        createdDtFrom: '',
        createdDtTo: '',
        filterWreqCreated: false,
        filterPickedUp: false,
        filterWashed: false,
        filterFolded: false,
        filterDelivered: false,
        userId: '',
        bagSize:'',
      })
    },
    adminType: {
      type: String,
      default: 'admin'
    }
  },
  data() {
    return {
      washReq: {},
      isCancelDone: false,
      pdSameTime: false,
    }
  },
  beforeRouteLeave (to, from, next) {
    // ブラウザバック時にパラメータがないため追加
    to.params.searchPrm = this.searchPrm
    next()
  },
  mounted() {
    window.master.$promise.then(mst => {
      adminWashRequestApi.getWashRequest(this.id).then(({ data }) => {
        this.washReq = data
        adminUserApi.getUserCurrentPlan(this.washReq.user_id).then(({ data }) => {
          //plan_idを_で区切って３つ目の値が1なら集配同時
          //（M~LLサイズの集配別は３つ目にサイズ情報が入る）
          const planInfos = data.plan_id.split('_')
          if (planInfos.length > 2 && planInfos[2] == 1) {
            this.pdSameTime = true
          } else {
            this.pdSameTime = false
          }
        })
      })
    })
  },
  mixins: [washRequestMixin],
  methods: {
    cancelWashRequest() {
      if (this.type == 1) {
        adminWashRequestApi.cancelWashRequest(this.washReq.id).then(({ data }) => {
        this.isCancelDone = true
        })
      } else {
        adminWashRequestApi.deleteWashRequest(this.washReq.id).then(({ data }) => {
        this.isCancelDone = true
        })
      }
    },
    backToWreqList() {
      let name = 'admin-wash-requests'
      if (this.adminType === 'staff') {
        name = 'staff-wash-requests'
      }
      this.$router.push({
        name: name,
        params: {
          id: this.id,
          searchPrm: this.searchPrm,
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.err-msg-area {
  margin: 30px auto 0;
  text-align: center;
  width: 90%;
}
.glnavi_btn_on {
  font-size: 16px;
}
.submit_bx_l, .submit_bx_r {
  margin: 12px auto;
}
.form_tab {
  .col2 {
    width: 184px;
  }
}
</style>