<template>
<div class="contents_855">
	<div v-show="isStep1 && maintenance.maint_flg === 0">
		<h1 class="title2">メンテナンス管理</h1>
		<div class="contents_box">
			<table class="form_tab form_line_top">
				<tr>
					<th><p class="item3">メンテナンス時間</p></th>
					<td>
						<p>
							開始：
							<vuejs-datepicker
								v-model="maintenance.start_date"
								class="date_input"
								placeholder="date"
								:format="DatePickerFormat"
								:clear-button="true" :class="{error_outline: this.errors.start_dt}">
							</vuejs-datepicker>
							<vue-timepicker
								v-model="maintenance.start_time"
								input-width="175px"
								placeholder="time"
								minute-interval="5"
								close-on-complete
								:class="{error_outline: this.errors.start_dt}">
							</vue-timepicker>
						</p>
						<span class="iferror" v-show="errors.start_dt_required">
							▲ どちらか一方のみの入力はできません
						</span>
						<p class="pd10">
							終了：
							<vuejs-datepicker
								v-model="maintenance.end_date"
								class="date_input"
								placeholder="date"
								:format="DatePickerFormat"
								:clear-button="true" :class="{error_outline: this.errors.end_dt}">
							</vuejs-datepicker>
							<vue-timepicker
								v-model="maintenance.end_time"
								input-width="175px"
								placeholder="time"
								minute-interval="5"
								close-on-complete
								:class="{error_outline: this.errors.end_dt}">
							</vue-timepicker>
						</p>
						<span class="iferror" v-show="errors.end_dt_required">
							▲ どちらか一方のみの入力はできません
						</span>
						<span class="iferror" v-show="errors.date_compare">
							▲ 終了日時は開始日時より後にしてください
						</span>
						<span class="iferror" v-show="errors.date_compare_with_now">
							▲ 現在より前の日時を設定することはできません
						</span>
					</td>
				</tr>
				<tr>
					<th><p class="item3">タイプ<span class="font_orange">*</span></p></th>
					<td>
						<select v-model="maintenance.type" class="w250" :class="{error_outline: this.errors.type}">
							<option value="">選択してください</option>
							<option v-for="elem in lovs.maintenance_type" v-bind:value="elem.key">{{elem.val}}</option>
						</select>
						<span class="iferror" v-show="errors.type_required">
							▲ 必須項目です
						</span>
					</td>
				</tr>
				<tr>
					<th><p class="item3">内容</p></th>
					<td>
						<textarea type="text" class="form_basic" v-model="maintenance.content"></textarea>
					</td>
				</tr>
				<tr>
					<th class="form_line_l"></th>
					<td class="form_line_r">
						<maintenance-messages></maintenance-messages>
					</td>
				</tr>
			</table>

			<div class="err-msg-area" v-show="hasError">
				<span class="font_orange">
					入力エラーがあります
				</span>
			</div>

			<p class="submit_bx1">
				<button @click="nextStep()" class="submit1 bg_green">
					確認画面
				</button>
			</p>
		</div>
	</div>

	<div v-show="isStep2 && maintenance.maint_flg === 0">
		<h1 class="title2">メンテナンス管理</h1>
		<div class="contents_box">
			<p class="mypage_notes font_orange">
				下記の内容でよろしければ確定してください。
			</p>
			<table class="form_tab form_line_top">
				<tr>
					<th class="form_line_l"><p class="item2">メンテナンス時間</p></th>
					<td class="form_line_r">
						<p>開始：{{maintenance.start_date | dtFormat('m月d日（a）')}}{{maintenance.start_time}}</p>
						<p>終了：{{maintenance.end_date | dtFormat('m月d日（a）')}}{{maintenance.end_time}}</p>
					</td>
				</tr>
				<tr>
					<th><p class="item2">タイプ<span class="font_orange">*</span></p></th>
					<td>{{maintenancesTypeDisp}}</td>
				</tr>
				<tr>
					<th class="form_line_l"><p class="item2">内容</p></th>
					<td class="form_line_r">{{maintenance.content}}</td>
				</tr>
			</table>
			<p class="submit_bx_l">
				<button @click="prevStep()" class="submit1 bg_gray">
					戻る
				</button>
			</p>
			<p class="submit_bx_r">
				<button @click="startMaintenance()" class="submit1 bg_green">
					OK
				</button>
			</p>
		</div>
	</div>

	<div v-show="isStep3 && maintenance.maint_flg === 0">
		<h1 class="title2">メンテナンス管理</h1>
		<div class="contents_box">
			<p class="mypage_notes font_orange">
				メンテナンスを開始しました。
			</p>
		</div>
	</div>

	<div v-show="isStep1 && maintenance.maint_flg === 1">
		<h1 class="title2">メンテナンス管理</h1>
		<div class="contents_box">
			<div class="center notice_box">メンテナンス中</div>
			<p class="mypage_notes font_orange">
				下記のメンテナンスを終了します。<br>
				よろしければ確定してください。
			</p>
			<table class="form_tab form_line_top">
				<tr>
					<th class="form_line_l"><p class="item2">メンテナンス時間</p></th>
					<td class="form_line_r">
						<p>開始：{{maintenance.start_dt | dtFormat('yyyy年m月d日 HH:MM')}}</p>
						<p>終了：{{maintenance.end_dt | dtFormat('yyyy年m月d日 HH:MM')}}</p>
					</td>
				</tr>
				<tr>
					<th><p class="item2">タイプ</p></th>
					<td>{{maintenancesTypeDisp}}</td>
				</tr>
				<tr>
					<th class="form_line_l"><p class="item2">内容</p></th>
					<td class="form_line_r">{{maintenance.content}}</td>
				</tr>
			</table>
			<p class="submit_bx1">
				<button @click="endMaintenance()" class="submit1 bg_green">
					OK
				</button>
			</p>
		</div>
	</div>

	<div v-show="isStep2 && maintenance.maint_flg === 1">
		<h1 class="title2">メンテナンス管理</h1>
		<div class="contents_box">
			<p class="mypage_notes font_orange">
				メンテナンスを終了しました。
			</p>
		</div>
	</div>
</div>
</template>

<script>
import Vue from 'vue'
import Datepicker from "vuejs-datepicker"
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import masterApi from '@/api/master'


export default {
	name: 'admin-maintenance',
	props: {
    adminType: {
      type: String,
      default: 'admin'
    }
  },
	components: {
		'vuejs-datepicker':Datepicker,
		'vue-timepicker': VueTimepicker,
	},
	data() {
		return {
			currentStep: 1,
			maxStep: 3,
			isStep1: true,
			isStep2: false,
			isStep3: false,

			lovs: {},

			maintenance: {
				maint_flg: 0,
				start_dt: '',
				start_date: '',
				start_time: '',
				end_dt: '',
				end_date: '',
				end_time: '',
				type: '',
				content: '',
			},

			roleType: 0,
			errors: {},
			serverErrors: [],

			DatePickerFormat: 'yyyy-MM-dd',
		}
	},
	computed: {
		maintenancesTypeDisp() {
			const maintenancesType = this.lovs.maintenance_type
			if (!maintenancesType) { return '' }
			let ret = ''
			maintenancesType.forEach(e => {
				if (e.key === this.maintenance.type) {
					ret = e.val
				}
			})
			return ret
		},
		hasError() {
			return Object.keys(this.errors).length > 0
		},
	},
	mounted() {
		window.master.$promise.then(mst => {
			this.$store.dispatch('user/getMe').then(me => {
				this.roleType = me.role_type;
			})
			masterApi.maintenanceIndex().then(({ data }) => {
				if (data.maint_flg === 1) {
					// メンテナンス中は管理者とマネージャ以外アクセス不可
					if (this.adminType === 'staff' && this.roleType !== 1) {
						this.$router.push({name: 'maintenance'})
					}
					this.maintenance = data
				}
				// 管理者とマネージャー以外アクセス不可
				if (this.adminType === 'staff' && this.roleType !== 1) {
					this.$router.push({ name: 'staff-top' })
				}
			})
			this.lovs = mst.lovs
		})
	},
	methods: {
		nextStep() {
			if (this.maintenance.maint_flg === 0) {
				if (!this.checkCurrentStep()) { return }
			}
			this.currentStep = Math.min(this.currentStep + 1, this.maxStep)
			this.updateStep()
			setTimeout(() => {
				window.scrollTo(0, 0)
			}, 0)
		},
		prevStep() {
			this.currentStep = Math.min(this.currentStep - 1, this.maxStep)
			this.updateStep()
			setTimeout(() => {
				window.scrollTo(0, 0)
			}, 0)
		},
		updateStep() {
			for (let i = 1; i <= this.maxStep; i++) {
				const flag = i === this.currentStep
				this[`isStep${i}`] = flag
			}
		},
		checkCurrentStep() {
      return this[`checkStep${this.currentStep}`]()
    },
		checkStep1() {
			this.errors = {}
			let result = true
			const dtFormat = Vue.filter('dtFormat')
			this.maintenance.start_date = dtFormat(this.maintenance.start_date, 'yyyy-mm-dd')
			this.maintenance.end_date = dtFormat(this.maintenance.end_date, 'yyyy-mm-dd')
			const now = new Date()
			const start_dt = new Date(this.maintenance.start_date + " " + this.maintenance.start_time);
			const invalid_start_time = isNaN(start_dt.getTime())
			const end_dt = new Date(this.maintenance.end_date + " " + this.maintenance.end_time);
			const invalid_end_time = isNaN(end_dt.getTime())

			if ((!this.maintenance.start_date && this.maintenance.start_time) || (this.maintenance.start_date && !this.maintenance.start_time)) {
				this.errors['start_dt'] = true
				this.errors['start_dt_required'] = true
				result = false
			} else {
				if (this.maintenance.start_date  && start_dt < now) {
					this.errors['start_dt'] = true
					this.errors['date_compare_with_now'] = true
					result = false
				}
			}

			if ((!this.maintenance.end_date && this.maintenance.end_time) || (this.maintenance.end_date && !this.maintenance.end_time)) {
				this.errors['end_dt'] = true
				this.errors['end_dt_required'] = true
				result = false
			} else {
				if (!this.maintenance.start_date && this.maintenance.end_date && end_dt < now) {
					this.errors['end_dt'] = true
					this.errors['date_compare_with_now'] = true
					result = false
				}
			}

			if (this.maintenance.start_date && this.maintenance.start_time && invalid_start_time) {
				this.errors['start_dt'] = true
				this.errors['start_dt_required'] = true
				result = false
			}

			if (this.maintenance.end_date && this.maintenance.end_time && invalid_end_time) {
				this.errors['end_dt'] = true
				this.errors['end_dt_required'] = true
				result = false
			}

			if (this.maintenance.start_date && this.maintenance.end_date) {
				if (this.maintenance.start_date > this.maintenance.end_date) {
					this.errors['start_dt'] = true
					this.errors['end_dt'] = true
					this.errors['date_compare'] = true
					result = false
				}

				if ((this.maintenance.start_date === this.maintenance.end_date) && (this.maintenance.start_time > this.maintenance.end_time)) {
					this.errors['start_dt'] = true
					this.errors['end_dt'] = true
					this.errors['date_compare'] = true
					result = false
				}
			}

			if (!this.maintenance.type) {
				this.errors['type'] = true
				this.errors['type_required'] = true
        result = false
			}

			return result
		},
		checkStep2() {
      return true
    },
		startMaintenance() {
			const dtFormat = Vue.filter('dtFormat')
			const start_dt = dtFormat(this.maintenance.start_date, 'yyyy-mm-dd') + " " + this.maintenance.start_time
			const end_dt = dtFormat(this.maintenance.end_date, 'yyyy-mm-dd') + " " + this.maintenance.end_time
      const data = {
				start_dt: start_dt,
				end_dt: end_dt,
        type: this.maintenance.type,
        content: this.maintenance.content,
      }

			masterApi.maintenanceStart(data).then(({ data }) => {
				this.nextStep()
			}).catch(e => {
				const errRes = e.response.data.errors;
        let serverErrors = []
        Object.keys(errRes).forEach(k => {
          serverErrors = serverErrors.concat(errRes[k])
        })
        this.serverErrors = serverErrors;
			})
		},
		endMaintenance() {
			masterApi.maintenanceEnd().then(({ data }) => {
				this.nextStep()
			}).catch(e => {
				const errRes = e.response.data.errors;
        let serverErrors = []
        Object.keys(errRes).forEach(k => {
          serverErrors = serverErrors.concat(errRes[k])
        })
        this.serverErrors = serverErrors;
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.form_tab td{
	overflow: visible;
}
.notice_box {
	width: 35%;
	min-width: 300px;
  margin: 0px auto 10px auto;
  padding: 10px 0px;
  background-color: #e82c13;
  text-align:center;
  color: #ffffff;
  font-size: 16px;
  font-weight: normal;
  clear:both;
}
.err-msg-area {
  margin: 30px auto 0;
  text-align: center;
  width: 90%;
}
</style>