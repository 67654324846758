import axios from 'axios'
import * as utils from './api-utils'

export default {
  getAll() {
    return axios.get(utils.getApiUrl('/masters'))
      .then(({ data }) => {
        const lovs = data.lovs
        const lovsMap = {}
        Object.keys(lovs).forEach(k => {
          const arr = lovs[k]
          lovsMap[k] = arr.reduce((acc, e) => {
            acc[e.key] = e; return acc
          }, {})
        })
        data.lovsMap = lovsMap

        data.planMap = data.plans.reduce((acc, e) => {
          acc[e.plan_id] = e; return acc
        }, {})

        return data
      })
  },
  searchZipcode(zipcode) {
    return axios.get(
      utils.getApiUrl('/zipcode'),
      {params: {zc: zipcode}}
    ).then(({ data }) => {
      return data
    })
  },
  maintenanceIndex() {
    return axios.get(utils.getApiUrl('/maintenance'))
  },
  maintenanceStart(data) {
    return axios.post(utils.getApiUrl('/admin/maintenance/start'), data)
  },
  maintenanceEnd() {
    return axios.post(utils.getApiUrl('/admin/maintenance/end'))
  },
}
