<template>
<ul class="list">
  <li><p>サイズを変更する場合は当月末に残個数が破棄されます</p></li>
  <li><p>同時集配設定を変更する場合は曜日固定設定が解除されます</p></li>
  <li><p>クレジット払いから請求書払いに変更する場合はクレジットの定期購入停止手続きに進みます</p></li>
</ul>
</template>

<script>
export default {
  name: 'edit-plan-notice-messages',
  data() {
    return {}
  },
}
</script>
