<template>
<ul class="list">
  <li><p>宅配ボックスをご利用の場合は解除用のパスワードを記入ください</p></li>
  <li><p>集配時間の3時間前に確認メールが届きます</p></li>
  <li><p>最短で集荷時間から休業日を除く24時間後に配達可能です</p></li>
  <li><p>集配時間は最大で2時間ほど前後する可能性があります</p></li>
</ul>
</template>

<script>
export default {
  name: 'wash-request-onetime-notice-messages',
  props: {
    mongonType: {
      type: String
    }
  },
  data() {
    return {}
  },
}
</script>