<template>
<div class="contents_855">
  <header-bar active="plan"></header-bar>

  <div>
    <h1 class="title2">プラン変更完了</h1>
    <div class="contents_box mg20">
      <p class="mypage_notes mg30 mg_bt30 font_orange">
        プラン変更が完了しました。
      </p>
      <p class="mypage_notes mg40">
        ご登録のメールアドレス①に確認のメールを送信しましたのでご確認くださいませ。
      </p>
    </div>
  </div>

</div>
</template>

<script>
export default {
  name: 'plan-edit-complete',
}
</script>
