<template>
<transition name="modal">
<div class="modal-container">
  <div class="modal-backdrop"></div>
  <div class="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header" v-if="showHeader">
          <button class="close" v-if="showTopDismissButton"
            @click="$emit('dismiss')">{{topDismissButtonLabel}}</button>
          <slot name="header">
            <h2 class="title">{{title}}</h2>
          </slot>
        </div>
        <div class="modal-body">
          <slot name="body"></slot>
        </div>
        <div class="modal-footer" v-if="showFooter">
          <slot name="footer">
            <button class="submit1 bg_gray modal-btn"
              @click="$emit('dismiss')"
              v-if="showBottomDismissButton">
              {{bottomDismissButtonLabel}}
            </button>
            <button class="submit1 bg_green modal-btn"
                :class="bottomCloseButtonClass"
                @click="$emit('close')"
                v-if="showBottomCloseButton">
              <i class="fa" v-if="showBottomCloseButtonIcon"
                :class="bottomCloseButtonIconClass"></i>
              {{bottomCloseButtonLabel}}
            </button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</div>
</transition>
</template>

<script>
export default {
  name: 'modal',
  props: {
    title: {
      type: String,
      default: '',
    },

    showHeader: {
      type: Boolean,
      default: true,
    },
    showTopDismissButton: {
      type: Boolean,
      default: false,
    },
    topDismissButtonLabel: {
      type: String,
      default: '×',
    },

    showFooter: {
      type: Boolean,
      default: true,
    },
    showBottomCloseButton: {
      type: Boolean,
      default: true,
    },
    bottomCloseButtonLabel: {
      type: String,
      default: 'OK',
    },
    bottomCloseButtonClass: {
      type: String,
      default: 'btn-primary',
    },

    showBottomCloseButtonIcon: {
      type: Boolean,
      default: false,
    },
    bottomCloseButtonIconClass: {
      type: String,
      default: 'fa-trash',
    },

    showBottomDismissButton: {
      type: Boolean,
      default: true,
    },
    bottomDismissButtonLabel: {
      type: String,
      default: 'キャンセル',
    },
  },
}
</script>

<style lang="scss" scoped>
  .modal-container {
    font-size: 18px;
  }
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    background-color: #000000;
    opacity: 0.3;
  }
  .modal {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
  }
  .modal-dialog {
    background-color: #ffffff;
    margin: 1.75rem auto;
    max-width: 70%;
    border-radius: 4px;
    overflow: hidden;
  }
  @media screen and (max-width: 640px) {
    .modal-dialog {
      max-width: 90vw;
    }
  }
  .modal-header {
    text-align: center;
    position: relative;
    .title {
      padding: 0;
      line-height: 3.4;
      font-size: 1.2rem;
    }
    .close {
      position: absolute;
      top: 4px;
      right: 4px;
      font-size: 1.4rem;
      color: #4d4d4d;
      background-color: #ffffff;
    }
  }
  .modal-body {
    padding: 16px 24px;
  }
  .modal-footer {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 8px;
    border-top: 1px solid #dddddd;
  }

  .modal-btn {
    width: 160px;
    margin: 0 4px;
    padding: 8px 4px;
    height: 42px;
  }

  .modal-enter {
    opacity: 0;
  }
  .modal-enter-active {
    transition: opacity .0s ease;
  }
  .modal-enter-to {
    opacity: 1.0;
  }
  .modal-leave-active {
    opacity: 0;
  }
</style>
