import axios from 'axios'
import * as utils from './api-utils'

export default {
  index(obj) {
    return axios.get(utils.getApiUrl('/admin/washRequest'), {
      params: obj || {}
    })
  },
  getWashRequest(id) {
    return axios.get(utils.getApiUrl(`/admin/washRequest/${id}`))
  },
  getUserWashRequests(id) {
    return axios.get(utils.getApiUrl(`/admin/washRequests/${id}`))
  },
  update(id, data) {
    return axios.put(utils.getApiUrl(`/admin/washRequests/${id}`), data)
  },
  updateCount(id, data) {
    return axios.post(utils.getApiUrl(`/admin/washRequests/${id}/setCount`), data)
  },
  assignStaff(id, data) {
    return axios.post(utils.getApiUrl(`/admin/washRequest/${id}/assignStaff`), data)
  },
  assignStaffs(data) {
    return axios.post(utils.getApiUrl(`/admin/washRequest/assignStaffs`), data)
  },
  sendAbsenceMail(id, data) {
    return axios.post(utils.getApiUrl(`/admin/washRequest/${id}/sendAbsenceMail`), data)
  },
  setDate(id, data) {
    return axios.post(utils.getApiUrl(`/admin/washRequest/${id}/setDate`), data)
  },
  delDate(id, data) {
    return axios.post(utils.getApiUrl(`/admin/washRequest/${id}/delDate`), data)
  },
  getProgress(obj) {
    return axios.get(utils.getApiUrl('/admin/washRequest/progress'), {
      params: obj || {}
    })
  },
  downloadCsv1(obj) {
    return axios.get(utils.getApiUrl('/admin/washRequest/downloadCsv1'), {
      params: obj || {}
    })
  },
  downloadCsv2(obj) {
    return axios.get(utils.getApiUrl('/admin/washRequest/downloadCsv2'), {
      params: obj || {}
    })
  },
  uploadCsv(data) {
    return axios.post(utils.getApiUrl('/admin/washRequest/uploadCsv'), data)
  },
  getWashRequestLimits(obj) {
    return axios.get(utils.getApiUrl(`/admin/washRequestLimits`), {
      params: obj || {}
    })
  },
  getWashRequestLimit(obj) {
    return axios.get(utils.getApiUrl(`/admin/washRequestLimit`), {
      params: obj || {}
    })
  },
  setWashRequestLimit(data) {
    return axios.post(utils.getApiUrl(`/admin/washRequestLimit`), data)
  },
  cancelWashRequestLimit(id) {
    return axios.delete(utils.getApiUrl(`/admin/washRequestLimit/${id}`))
  },
  cancelWashRequest(id) {
    return axios.delete(utils.getApiUrl(`/admin/washRequests/cancel/${id}`))
  },
  deleteWashRequest(id) {
    return axios.delete(utils.getApiUrl(`/admin/washRequests/delete/${id}`))
  },
  addBag(data) {
    return axios.post(utils.getApiUrl(`/admin/addBag`), data)
  },
  workReport(data) {
    return axios.post(utils.getApiUrl(`/admin/workReport`), data)
  }
}
