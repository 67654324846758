<template>
<div class="contents_855">
  <header-bar active="profile"></header-bar>

  <div>
    <h1 class="title2">お客様情報</h1>
    <div class="contents_box mg20">
      <table class="form_tab form_line_top">
        <tr>
          <th><p class="item2">お名前</p></th>
          <td><p>
            <span>{{name1}}</span>
            <span>{{name2}}</span>
          </p></td>
        </tr>
        <tr>
          <th><p class="item2">おなまえ（ひらがな）</p></th>
          <td><p>
            <span>{{kana1}}</span>
            <span>{{kana2}}</span>
          </p></td>
        </tr>
        <tr>
          <th><p class="item2">会社/店舗名</p></th>
          <td><p>{{companyName}}</p></td>
        </tr>
        <tr>
          <th><p class="item2">集配先住所</p></th>
          <td>
            <p>〒{{billAddrZipcodeDisp}}</p>
            <p>{{billAddrDetail1}}{{billAddrDetail2}}</p>
            <p>{{billAddrDetail3}}</p>
          </td>
        </tr>
        <tr>
          <th><p class="item2">電話番号</p></th>
          <td><p>{{phoneNumber}}</p></td>
        </tr>
        <tr>
          <th><p class="item2">メールアドレス①</p></th>
          <td><p>{{email}}</p></td>
        </tr>
        <tr>
          <th><p class="item2">メールアドレス②</p></th>
          <td><p>{{email2}}</p></td>
        </tr>
        <tr>
          <th><p class="item2">招待コード</p></th>
          <td><p>{{invitation_code}}</p></td>
        </tr>
        <tr>
          <th class="form_line_l"><p class="item2">備考</p></th>
          <td class="form_line_r"><p v-html="$options.filters.lineBreakToBR(bikou1)"></p></td>
        </tr>
      </table>
      <p class="submit_bx_l">
        <router-link :to="{name:'profile-edit'}" class="submit1 bg_green">
          お客様情報の変更
        </router-link>
      </p>
      <p class="submit_bx_r">
        <router-link :to="{name:'password-change'}" class="submit1 bg_green">
          パスワード変更
        </router-link>
      </p>
    </div>
  </div>
</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'profile',
  data() {
    return {
      invitation_code: '',
    }
  },
  computed: {
    ...mapState('user', {
      username: state => state.name,
      userid: state => state.id,
      email: state => state.email,
      email2: state => state.email2,
      name1: state => state.name1,
      name2: state => state.name2,
      kana1: state => state.kana1,
      kana2: state => state.kana2,
      companyName: state => state.company_name,
      billAddrZipcode: state => state.bill_addr_zipcode,
      billAddrDetail1: state => state.bill_addr_detail1,
      billAddrDetail2: state => state.bill_addr_detail2,
      billAddrDetail3: state => state.bill_addr_detail3,
      phoneNumber: state => state.phone_number,
      bikou1: state => state.bikou1,
    }),
    billAddrZipcodeDisp() {
      return this.getZipcodeDisp(this.billAddrZipcode)
    },
  },
  mounted() {
    const token = this.$store.getters['localStorage/serverToken']
    if (!token) {
      this.$router.push({name: 'login'})
    }
    this.$store.dispatch('user/getMe')
      .then(me => {
        this.invitation_code = me.invitation_code
      })
  },
  methods: {
    getZipcodeDisp(zc) {
      if (!zc || zc.length !== 7) { return zc }
      return zc.slice(0, 3) + '-' + zc.slice(3, 7)
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
