<template>
<div class="contents">
    <div class="search-box">
        <table>
          <tr>
            <th><p class="item2">ユーザー</p></th>
            <td>
              <select v-model="search.userId" style="width:150px;">
                <option value="">全て</option>
                <option v-for="user in search.users" v-bind:value="user.id">
                  {{user.name1}} {{user.name2}}
                </option>
              </select>
            </td>
          </tr>
          <tr>
            <th><p class="item2">ステータス</p></th>
            <td>
              <div>
                <input type="checkbox" id="undone" v-model="search.filterUndone"><label for="undone">未対応</label>
                <input type="checkbox" id="done" v-model="search.filterDone"><label for="done">対応済</label>
                <input type="checkbox" id="donothing" v-model="search.filterDoNothing"><label for="donothing">対応なし</label>
              </div>
            </td>
          </tr>
          <tr>
            <th><p class="item2">日付</p></th>
            <td>
              <my-calendar
                v-model="search.createdDtFrom"
                width="75px"
                :readonly="true"
              ></my-calendar>
              <span class="cal-tilda">〜</span>
              <my-calendar
                v-model="search.createdDtTo"
                width="75px"
                :readonly="true"
              ></my-calendar>
            </td>
          </tr>
        </table>
        <p class="submit_bx1">
          <button class="submit1 bg_blue" @click="searchManualPayments()">
            <i class="fa fa-search"></i>
            検索
          </button>
        </p>
    </div>

  <div class="list-box">
    <p class="count-box">該当件数：{{payments.length}}件</p>
    <table class="tbl">
      <colgroup>
        <col style="width:50px;">
        <col style="width:30px;">
        <col style="width:20px;">
        <col style="width:30px;">
        <col style="width:60px;">
        <col style="width:30px;">
        <col style="width:90px;">
        <col style="width:40px;">
        <col style="width:40px;">
        <col style="width:90px;">
        <col style="width:50px;">
      </colgroup>
      <tr>
        <th>登録日時</th>
        <th>金額</th>
        <th>追加個数</th>
        <th>請求月</th>
        <th>種別</th>
        <th>決済方法</th>
        <th>ID:お客様</th>
        <th>ステータス</th>
        <th>運営メモ</th>
        <th>ID:管理</th>
        <th>更新日時</th>
      </tr>
      <tr v-for="(payment, idx) in payments">
        <td>{{payment.created_at | dtFormat('yyyy/mm/dd HH:MM:SS')}}</td>
        <td>{{payment.price.toLocaleString()}}</td>
        <td>{{payment.count}}</td>
        <td>{{payment.month}}</td>
        <td>{{payment.page}}</td>
        <td>{{payment.payment_method}}</td>
        <td>{{payment.user_id}}：{{payment.users_name1}} {{payment.users_name2}} ({{payment.users_kana1}} {{payment.users_kana2}})</td>
        <td>
          <div v-show="!payment.input_status">
              {{payment.status}}
              <button class="btn btn_sm bg_blue fr"
                  @click="switchToInputMode(payment)">
                更新
              </button>
          </div>
          <div v-show="payment.input_status">
            <div>
              <select v-model="payment.input_status_str" style="width: 100px;">
                <option v-for="elem in lovs.payment_status" v-bind:value="elem.key">
                  {{elem.val}}
                </option>
              </select>
            </div>
            <div style="margin:4px 0 0 0;">
              <button class="btn btn_sm bg_danger"
                  @click="updateStatus(idx, payment)">
                確定
              </button>
              <button class="btn btn_sm bg_blue"
                  @click="switchToNonInputMode(payment)">
                キャンセル
              </button>
            </div>
          </div>
        </td>
        <td>
          <div class="bikou">
            <p v-if="payment.bikou == null">
              無し
            </p>
            <p v-else>
              有り
            </p>
            <button class="btn btn_sm bg_blue"
              @click="openBikouModal(payment)">
                編集
            </button>
          </div>
        </td>
        <td>
          <p v-show="payment.update_user_id">{{payment.update_user_id}}：{{payment.update_users_name1}} {{payment.update_users_name2}} ({{payment.update_users_kana1}} {{payment.update_users_kana2}})</p>
          <p v-show="payment.update_user_id == 0">自動決済</p>
        </td>
        <td>{{payment.updated_at | dtFormat('yyyy/mm/dd HH:MM:SS')}}</td>
      </tr>
    </table>
  </div>

    <modal
    val="paymentInfo"
    v-if="showBikouModal"
    title="備考"
    bottomCloseButtonLabel="更新"
    @close="updateBikou()"
    @dismiss="showBikouModal = false">
    <div slot="body">
      <p><textarea type="text" class="form_basic" v-model="paymentInfo.bikou"></textarea></p>
    </div>
  </modal>
  </div>
</template>

<script>
import Vue from 'vue'
import masterApi from '@/api/master'
import adminUserApi from '@/api/adminUser'
import paymentApi from '@/api/payment'

export default {
  name: 'admin-wash-request-list',
  props: {
    searchPrm: {
      type: Object,
      'default': () => ({
        userId: '',
        createdDtFrom: '',
        createdDtTo: '',
        filterDone: false,
        filterUndone: false,
        filterDoNothing: false,
      })
    },
    adminType: {
      type: String,
      default: 'admin'
    }
  },
  data() {
    return {
      search: {
        userId: '',
        users: [],
        createdDtFrom: '',
        createdDtTo: '',
        filterDone: false,
        filterUndone: false,
        filterDoNothing: false,
      },

      userId: '',
      lovs: {},
      lovsMap: {},
      planMap: {},
      paymentStatusMap: {},
      userMap: {},

      payments: [],
      showBikouModal: false,
      paymentInfo: {},

      roleType: 0,
    }
  },
  computed: {
    paymentsItemCount() {
      return this.payments.length
    },
  },
  async mounted() {
    await this.$store.dispatch('user/getMe').then(me => {
      this.roleType = me.role_type;
    })
    // メンテナンス中は管理者とマネージャ以外アクセス不可
    masterApi.maintenanceIndex().then(({ data }) => {
      const maint_flg = data.maint_flg
      if (maint_flg === 1 && this.adminType === 'staff' && this.roleType !== 1) {
        this.$router.push({name: 'maintenance'})
      }
    })
    // 管理者とマネージャー以外アクセス不可
    if (this.adminType === 'staff' && this.roleType !== 1) {
      this.$router.push({ name: 'staff-top' })
    }

    const mst = await window.master.$promise
    this.lovs = mst.lovs
    this.lovsMap = mst.lovsMap
    this.planMap = mst.planMap
    this.paymentStatusMap = mst.lovsMap.payment_status
    this.paymentMethodMap = mst.lovsMap.payment_method
    this.pageMap = mst.lovsMap.page

    this.setSearchPrm()
    const { data: users } = await adminUserApi.getAllUser()
    this.search.users = users
    this.userMap = users.reduce((acc, e) => {
      acc[e.id] = e; return acc
    }, {})
    if (this.search.createdDtTo === '' || this.search.createdDtFrom === '') {
      // 当日日付をデフォルトとしておく
      const dt = new Date()
      const dtFormat = Vue.filter('dtFormat')
      this.search.createdDtTo = dtFormat(dt, 'yyyy/mm/dd')
      this.search.createdDtFrom = dtFormat(dt, 'yyyy/mm/dd')
    }
    this.searchManualPayments()
  },
  methods: {
    convPayment(payment) {
      const ret = Object.assign({}, payment)

      ret.page = this.pageMap[payment.page_id].val
      ret.payment_method = null
      if (payment.payment_method_id) {
        ret.payment_method = this.paymentMethodMap[payment.payment_method_id].val
      }
      ret.status = this.paymentStatusMap[payment.status_id].val
      ret.month = null
      if (payment.month) {
        ret.month = `${payment.month}月分`
      }

      ret['input_status'] = false
      ret['input_status_str'] = null

      return ret
    },
    filterBySearchParams1(payment) {
      const search = this.search

      if (search.userId) {
        if (search.userId === "0") {
          if (payment.user_id) { return false }
        }
        else {
          if (payment.user_id != search.userId) { return false }
        }
      }

      return true
    },
    filterBySearchParams2(payment) {
      const search = this.search

      // 未対応
      if (search.filterUndone) {
        if (payment.status_id === "1") {
          return true
        }
      }
      // 対応済み
      if (search.filterDone) {
        if (payment.status_id === "2") {
          return true
        }
      }
      // 対応なし
      if (search.filterDoNothing) {
        if (payment.status_id === "3") {
          return true
        }
      }

      return false
    },
    setSearchPrm() {
      this.search = this.searchPrm
    },
    searchManualPayments() {
      const obj = {
        from: this.search.createdDtFrom.replace(/\//g, '-') + 'T00:00:00',
        to: this.search.createdDtTo.replace(/\//g, '-') + 'T23:59:59',
      }

      paymentApi.getManualPayment(obj).then(({ data }) => {
        this.payments = data
          .map(e => this.convPayment(e))
          .filter(e => this.filterBySearchParams1(e))
          .filter(e => this.filterBySearchParams2(e))
      })
    },
    switchToInputMode(payment) {
      payment.input_status = true
      payment.input_status_str = payment.status_id
    },
    switchToNonInputMode(payment) {
      payment.input_status = false
      payment.input_status_str = null
    },
    updateStatus(idx, payment) {
      const obj = {
        ['status_id']: payment.input_status_str
      }
      paymentApi.updateStatus(payment.id, obj).then(({ data }) => {
        payment.status_id = data["status_id"]
        payment.status = this.paymentStatusMap[payment.status_id].val

        payment.update_user_id = data["update_user_id"]
        payment.update_users_name1 = this.userMap[payment.update_user_id].name1
        payment.update_users_name2 = this.userMap[payment.update_user_id].name2
        payment.update_users_kana1 = this.userMap[payment.update_user_id].kana1
        payment.update_users_kana2 = this.userMap[payment.update_user_id].kana2

        Vue.set(this.payments, idx, payment)
        this.switchToNonInputMode(payment)
      })
    },
    openBikouModal(payment) {
      this.paymentInfo = payment
      this.showBikouModal = true
    },
    updateBikou(){
      const obj = {
        ['bikou']: this.paymentInfo.bikou
      }
      paymentApi.updateBikou(this.paymentInfo.id, obj).then((data) => {
        this.paymentInfo = {}
        this.showBikouModal = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.search-box{
  margin: 40px auto 0px auto;
  max-width: 855px;
}

.contents {
  width: 95%;
  margin: 20px auto 0;
  overflow-y: scroll;
  padding-bottom: 5rem;
}

.ilblk {
  display: inline-block;
  vertical-align: bottom;
}
.form-control {
  height: 24px;
  font-size: 12px;
  width: 96%;
}
.form-control-sm {
  font-size: 12px;
}
.btn {
  font-size: 16px;
  border-radius: 4px;
  background-color: #00a9ca;
  color: #ffffff;
  padding: 6px 12px;
  cursor: pointer;
}
.btn_sm {
  font-size: 12px;
  padding: 2px 6px;
}
.btn.bg_white {
  color: #555555;
  background-color: #ffffff;
  border: 1px solid #aaaaaa;
}
.btn.bg_danger {
  background-color: #d80a0a;
}
.fr {
  float: right;
}

.count-box{
  margin: 10px 10px 10px 10px;
}

.list-box {
  margin: 20px 0 0 0;

  .tbl {
    table-layout: fixed;
    font-size: 14px;
    white-space: nowrap;
    width: 1800px;
    border-collapse: collapse;
  }
  th, td {
    border: 1px solid #aaa;
    padding: 2px 4px;
  }
}

.bikou{
  display: flex;
  justify-content:space-between;
  flex-wrap: wrap;
}

</style>
