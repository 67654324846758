<template>
<div class="contents_855">
  <header-bar active="wash-request-currents"></header-bar>

  <h1 class="title2">集荷のキャンセル</h1>

  <div class="contents_box" v-show="!isCancelDone">
    <p class="mypage_notes mg30 mg_bt30">
      <span class="font_orange">
        下記の集荷をキャンセルしてよろしいですか？
      </span>
    </p>

    <p class="title3">集配{{washReq.dispNum}}</p>
    <table class="form_tab form_line_top">
      <tr>
        <th class="form_line_l"><p class="item2">集配日時</p></th>
        <td class="form_line_r">
          <p>
            集荷：{{washReq.sched_pickup_dt | dtFormat('m月d日（a）HH：MM')}}
          </p>
          <span v-if="pdSameTime">
            <p class="mg10">
              <span class="font_orange">次回集荷時に併せて配達します。</span>
            </p>
          </span>
          <span v-else>
            <p class="mg10">
              配達：{{washReq.sched_delivery_dt | dtFormat('m月d日（a）HH：MM')}}
            </p>
          </span>
        </td>
      </tr>
    </table>
    <div class="err-msg-area">
      <span class="font_orange" v-show="showErrMsg1">
        集荷まで2時間を切っているため、キャンセルできません
      </span>
      <span class="font_orange" v-show="showErrMsg2">
        このアカウントは退会済みです
      </span>
    </div>
    <p class="submit_bx_l">
      <router-link :to="{name:'wash-request-currents'}" class="submit1 bg_gray">
        戻る
      </router-link>
    </p>
    <p class="submit_bx_r">
      <button @click="cancelWashRequest()" class="submit1 bg_green">
        OK
      </button>
    </p>
  </div>

  <div class="contents_box" v-show="isCancelDone">
    <p class="mypage_notes mg40 mg_bt40 font_orange">
      集荷のキャンセルを承りました。
    </p>
    <p class="mypage_notes mg40">
      ご登録のメールアドレス①に確認のメールを送信しましたので、ご確認ください。
    </p>
    <p class="submit_bx1">
      <router-link :to="{name:'wash-request'}" class="glnavi_btn_on">
        集荷依頼
      </router-link>
    </p>
  </div>
</div>
</template>

<script>
import Vue from 'vue'
import washRequestMixin from '@/mixin/washRequestMixin'
import userApi from '@/api/user'

export default {
  name: 'wash-request-cancel',
  props: {
    id: {
      type: [String, Number],
      default: -1
    },
  },
  data() {
    return {
      washReq: {},
      showErrMsg1: false,
      showErrMsg2: false,
      isCancelDone: false,
      pdSameTime: false,
    }
  },
  mounted() {
    window.master.$promise.then(mst => {
      userApi.getMyWashRequests().then(({ data }) => {
        const washReqs = this.getWashRequestCurrents(data)
        washReqs.forEach(e => {
          if (parseInt(e.id) === parseInt(this.id)) {
            this.washReq = e
          }
        })
        userApi.getMyCurrentPlan().then(({ data }) => {
          //plan_idを_で区切って３つ目の値が1なら集配同時
          //（M~LLサイズの集配別は３つ目にサイズ情報が入る）
          const planInfos = data.plan_id.split('_')
          if (planInfos.length > 2 && planInfos[2] == 1) {
            this.pdSameTime = true
          } else {
            this.pdSameTime = false
          }
        })
      })
    })
  },
  mixins: [washRequestMixin],
  methods: {
    cancelWashRequest() {
      this.showErrMsg1 = false

      // 現在より2時間後以降でなければエラー　-> キャンセル期限無し
      // const dateStrToDate = Vue.filter('dateStrToDate')
      // const pickupMinDt = new Date(new Date().valueOf() + 3600*2*1000)
      // const pickupDt = dateStrToDate(this.washReq.sched_pickup_dt)
      // if (pickupDt < pickupMinDt) {
      //   this.showErrMsg1 = true
      //   return
      // }

      userApi.cancelWashRequest(this.washReq.id).then(({ data }) => {
        this.isCancelDone = true
        this.$store.dispatch(
          'user/getMe',
          {forceRefresh: true}
        )
      }).catch(err => {
        if (err.response.data.reason == 'status_withdrawn') {
          this.showErrMsg2 = true
          this.$store.dispatch('localStorage/set', {
            key: 'serverToken',
            val: null,
          })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.err-msg-area {
  margin: 30px auto 0;
  text-align: center;
  width: 90%;
}
.submit_bx_l, .submit_bx_r {
  margin: 12px auto;
}
.form_tab {
  .col2 {
    width: 184px;
  }
}
</style>
