<template>
<div class="contents_855">
  <header-bar active="wash-history"></header-bar>

  <h1 class="title2">ご利用履歴</h1>

  <div class="contents_box">
    <table class="mp_tb wash_history">
      <tbody>
        <tr>
          <td bgcolor="#e5f5fa"><p>プラン</p></td>
          <td bgcolor="#e5f5fa"><p>日時</p></td>
        </tr>
        <tr v-for="wreq in histories">
          <th>
            <p>
            <span class="wreq_mst_name p1">{{wreq.mst_name_p1}}</span>
            <span class="wreq_mst_name sl">/</span>
            <br class="wreq_mst_name br">
            <span class="wreq_mst_name p2">{{wreq.mst_name_p2}}</span>
            </p>
          </th>
          <td>
            <p class="mp_tb_con">
              集荷：{{wreq.sched_pickup_dt | dtFormat('m月d日(a) HH：MM')}}
            </p>
            <p class="mp_tb_con">
              <span v-if="wreq.pdSameTime">
                配達：<span class="font_orange">次回集荷時に併せて配達</span>
              </span>
              <span v-else>
                配達：{{wreq.sched_delivery_dt | dtFormat('m月d日(a) HH：MM')}}
              </span>
            </p>
            <p class="mp_tb_con">
              詳細：{{wreq.mst_size}}／{{wreq.count}}個
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
</template>

<script>
import Vue from 'vue'
import userApi from '@/api/user'

export default {
  name: 'wash-history',
  data() {
    return {
      histories: []
    }
  },
  mounted() {
    const token = this.$store.getters['localStorage/serverToken']
    if (!token) {
      this.$router.push({name: 'login'})
    }
    window.master.$promise.then(mst => {
      // Business Rule No.28 履歴は最大6か月前まで確認できる
      const oldestDt = new Date(new Date().valueOf() - 86400*180*1000)
      const dateStrToDate = Vue.filter('dateStrToDate')
      // Business Rule No.29 履歴は1ページに10件まで表示⇛60件に変更
      const maxHistoryCount = 60

      userApi.getMyWashRequests().then(({ data }) => {
        this.histories = data.filter(e => {
          //plan_idを_で区切って３つ目の値が1なら集配同時
          //（M~LLサイズの集配別は３つ目にサイズ情報が入る）
          let planInfos = e.plan_id.split('_')
          if (planInfos.length > 2 && planInfos[2] == 1) {
            e.pdSameTime = true
             // 既に集荷が終わってるやつ
            return !!e.result_pickup_dt
          } else {
            e.pdSameTime = false
            // 既に配達が終わってるやつ
            return !!e.result_delivery_dt
          }
        }).filter(e => {
          if (e.pdSameTime) {
            return dateStrToDate(e.result_pickup_dt) > oldestDt
          } else {
            return dateStrToDate(e.result_delivery_dt) > oldestDt
          }
        }).map(e => {
          e.mst_name = mst.planMap[e.plan_id].name
          const planInfos = e.plan_id.split('_')
          if (planInfos[0] == 'tx') {
            e.mst_size = '単発（45L）'
          } else {
            e.mst_size = mst.lovsMap['size'][mst.planMap[e.plan_id].size].val + 'サイズ'
          }
          const arr = e.mst_name.split('/')
          e.mst_name_p1 = arr[0]
          e.mst_name_p2 = arr[1]
          return e
        }).slice(0, maxHistoryCount)
      })
    })
  },
}
</script>

<style lang="scss" scoped>
</style>
