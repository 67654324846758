<template>
<div class="contents_855">
  <div>
    <FullCalendar ref="fullCalendar" :options="calendarOptions" />
  </div>
  <div class="err-msg-area">
    <p><span class="font_orange" v-for="e in serverErrors">
      {{e}}
    </span></p>
  </div>

  <modal
    v-if="showSetModal"
    title="設定"
    bottomCloseButtonLabel="設定"
    @close="exec()"
    @dismiss="dismiss()">
    <div slot="body">
      <div>
        <select v-model="action" class="w200">
          <option value="0">休業日</option>
          <option value="1">集配依頼数制限</option>
        </select>
        <p>
          <span class="iferror" v-show="errors.action_required">
            ▲ 未選択です
          </span>
        </p>
      </div>
      <div v-show="action == '1'">
        <input type="text" v-model="limit" class="form_basic form_limit">件まで
        <p>
          <span class="iferror" v-show="errors.limit_required">
            ▲ 入力値が不正です
          </span>
        </p>
      </div>
    </div>
  </modal>

</div>
</template>

<script>
import Vue from 'vue'
import masterApi from '@/api/master'
import adminBusinessHolidayApi from '@/api/adminBusinessHoliday'
import adminWashRequestApi from '@/api/adminWashRequest'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'

export default {
  name: 'admin-business-holiday-setting',
  props: {
    adminType: {
      type: String,
      default: 'admin'
    }
  },
  components: {
    FullCalendar // make the <FullCalendar> tag available
  },
  computed: {
    trimLimit() {
      return this.zenkakuToHankaku(this.limit)
    }
  },
  data() {
    return {
      calendarOptions: {
        plugins: [ dayGridPlugin, interactionPlugin ],
        initialView: 'dayGridMonth',
        locale: 'ja',
        contentHeight: 'auto',
        weekends: true, // 土日の表示非表示
        hiddenDays: [0], // 日曜日のみ非表示
        selectable: false,
        selectMirror: true, // ドラッグ中のプレースホルダー表示
        selectOverlap: false, // 期間重複
        eventStartEditable: false, // ドラッグによる期間変更
        eventDurationEditable: false, // サイズ変更による期間変更

        dateClick: this.handleDateClick,
        eventClick: this.handleEventClick,
      },

      showSetModal: false,
      businessHolidays: [],
      washRequestLimits: [],
      action: "0",
      limit: "",
      clickInfo: {},
      errors: {},

      roleType: 0,
      serverErrors: [],
    }
  },
  async mounted() {
    await this.$store.dispatch('user/getMe').then(me => {
      this.roleType = me.role_type;
    })
    // メンテナンス中は管理者とマネージャ以外アクセス不可
    masterApi.maintenanceIndex().then(({ data }) => {
      const maint_flg = data.maint_flg
      if (maint_flg === 1 && this.adminType === 'staff' && this.roleType !== 1) {
        this.$router.push({name: 'maintenance'})
      }
    })
    // 管理者とマネージャー以外アクセス不可
    if (this.adminType === 'staff' && this.roleType !== 1) {
      this.$router.push({ name: 'staff-top' })
    }
    this.getBusinessHolidays()
    this.getWashRequestLimits()
  },
  methods: {
    getBusinessHolidays() {
      adminBusinessHolidayApi.index().then(({ data }) => {
        this.businessHolidays = data
        this.businessHolidays.forEach(businessHoliday => {
          this.setHolidayEventCalendar(businessHoliday)
        })
      })
    },
    setHolidayEventCalendar(businessHoliday) {
      let calendarApi = this.$refs.fullCalendar.getApi()
      calendarApi.addEvent({
        id: 'holiday' + businessHoliday.id,
        title: '休業日',
        textColor: 'white',
        color: 'red',
        start: businessHoliday.date,
        allDay: true
      })
    },
    getWashRequestLimits() {
      adminWashRequestApi.getWashRequestLimits().then(({ data }) => {
        this.washRequestLimits = data
        this.washRequestLimits.forEach(washRequestLimit => {
          this.setLimitEventCalendar(washRequestLimit)
        })
      })
    },
    setLimitEventCalendar(washRequestLimit) {
      let calendarApi = this.$refs.fullCalendar.getApi()
      let oldEvent = calendarApi.getEventById('limit')
      if (oldEvent) {
        oldEvent.remove()
      }
      calendarApi.addEvent({
        id: 'limit' + washRequestLimit.id,
        title: washRequestLimit.limit + '件',
        textColor: 'white',
        color: 'blue',
        start: washRequestLimit.date,
        allDay: true
      })
    },
    exec() {
      if (!this.check()) { return }

      if (this.action == "0") {
        this.setBusinessHoliday(this.clickInfo)
      } else if (this.action == "1") {
        this.setWashRequestLimit(this.clickInfo)
      }

      this.dismiss()
    },
    dismiss() {
      this.showSetModal = false
      this.clickInfo = {}
      this.errors = {}
    },
    check() {
      this.errors = {}
      let result = true

      if (!this.action) {
        this.errors['action_required'] = true
        result = false
      }

      if (this.action == "1" && (!this.trimLimit || (this.trimLimit && !this.trimLimit.match(/^[0-9]+$/)))) {
        this.errors['limit_required'] = true
        result = false
      }

      return result
    },
    handleDateClick(clickInfo) {
      this.serverErrors = []
      const obj = {
        ['date']: clickInfo.dateStr,
      }
      adminWashRequestApi.getWashRequestLimit(obj).then(({ data }) => {
        if (data) {
          this.limit = data.limit
        }
        this.action = "0"
        this.showSetModal = true
        this.clickInfo = clickInfo
      })
      .catch(e => {
        const errRes = e.response.data.errors;
        let serverErrors = []
        Object.keys(errRes).forEach(k => {
          serverErrors = serverErrors.concat(errRes[k])
        })
        this.serverErrors = serverErrors;
      })
    },
    handleEventClick(clickInfo) {
      if (clickInfo.event.id.indexOf('limit') != -1) {
        if (confirm(`'${clickInfo.event.startStr}'の制限を解除しますか？`)) {
          this.cancelWashRequestLimit(clickInfo)
        }
      } else if (clickInfo.event.id.indexOf('holiday') != -1) {
        if (confirm(`'${clickInfo.event.startStr}'の休業日を解除しますか？`)) {
          this.cancelBusinessHoliday(clickInfo)
        }
      }
    },
    setBusinessHoliday(clickInfo) {
      this.serverErrors = []
      const obj = {
        ['date']: clickInfo.dateStr
      }
      adminBusinessHolidayApi.setBusinessHoliday(obj).then(({ data }) => {
        this.addHolidayEventCalendar(clickInfo, data)
      })
      .catch(e => {
        const errRes = e.response.data.errors;
        let serverErrors = []
        Object.keys(errRes).forEach(k => {
          serverErrors = serverErrors.concat(errRes[k])
        })
        this.serverErrors = serverErrors;
      })
    },
    setWashRequestLimit(clickInfo) {
      this.serverErrors = []
      const obj = {
        ['date']: clickInfo.dateStr,
        ['limit']: this.trimLimit,
      }
      adminWashRequestApi.setWashRequestLimit(obj).then(({ data }) => {
        this.addLimitEventCalendar(clickInfo, data)
      })
      .catch(e => {
        const errRes = e.response.data.errors;
        let serverErrors = []
        Object.keys(errRes).forEach(k => {
          serverErrors = serverErrors.concat(errRes[k])
        })
        this.serverErrors = serverErrors;
      })
    },
    addHolidayEventCalendar(clickInfo, data) {
      let calendarApi = clickInfo.view.calendar
      calendarApi.addEvent({
        id: 'holiday' + data.id,
        title: '休業日',
        textColor: 'white',
        color: 'red',
        start: clickInfo.dateStr,
        allDay: true
      })
    },
    addLimitEventCalendar(clickInfo, data) {
      let calendarApi = clickInfo.view.calendar
      let oldEvent = calendarApi.getEventById('limit' + data.id)
      if (oldEvent) {
        oldEvent.remove()
      }

      calendarApi.addEvent({
        id: 'limit' + data.id,
        title: data.limit + '件',
        textColor: 'white',
        color: 'blue',
        start: clickInfo.dateStr,
        allDay: true
      })
    },
    cancelBusinessHoliday(clickInfo) {
      this.serverErrors = []
      adminBusinessHolidayApi.cancelBusinessHoliday(clickInfo.event.id).then(() => {
        clickInfo.event.remove()
      })
      .catch(e => {
        const errRes = e.response.data.errors;
        let serverErrors = []
        Object.keys(errRes).forEach(k => {
          serverErrors = serverErrors.concat(errRes[k])
        })
        this.serverErrors = serverErrors;
      })
    },
    cancelWashRequestLimit(clickInfo) {
      this.serverErrors = []
      adminWashRequestApi.cancelWashRequestLimit(clickInfo.event.id).then(() => {
        clickInfo.event.remove()
      })
      .catch(e => {
        const errRes = e.response.data.errors;
        let serverErrors = []
        Object.keys(errRes).forEach(k => {
          serverErrors = serverErrors.concat(errRes[k])
        })
        this.serverErrors = serverErrors;
      })
    },
    zenkakuToHankaku(str) {
    str = String(str)
      return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
          return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.err-msg-area {
  margin: 30px auto 0;
  text-align: center;
  width: 90%;
}
.submit_bx_l, .submit_bx_r {
  margin: 12px auto;
}
.form_limit {
  width: 120px;
}
.modal {
  z-index: 5;
}
</style>
