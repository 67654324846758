<template>
<footer class="foot_wrap">
  <div class="contents_wrap">
    <p class="foot_logo">
      <a href="https://awesome-wash.com">
        <img src="/static/images/logo_hd.gif" alt="AWESOME WASH">
      </a>
    </p>
    <p class="foot_info pc_br_none"><span class="bold">洗濯代行サービス <span class="pc_br_none tab_br_none sp_br_yes"><br></span>AWESOME WASH オーサムウォッシュ</span>　<br>HIPSTER株式会社　<br>東京都新宿区市谷田町3-25 <span class="pc_br_none tab_br_none sp_br_yes"><br></span>パームハウスB2号室　<span class="pc_br_none tab_br_none sp_br_yes"><br></span>TEL:050-1807-1227</p>
  </div>
  <p class="copyright">Copyright HIPSTER Co., Ltd. All rights reserved.</p>
</footer>
</template>

<script>
export default {
  name: 'no-login-header',
}
</script>
