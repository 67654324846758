<template>
<div v-show="role === 200" class="contents_855">
  <div>
    <router-link v-show="this.roleType === 1" :to="{name:'staff-progress-management'}">
      進捗管理
    </router-link>
  </div>
  <div>
    <router-link v-show="[1,2].includes(this.roleType)" :to="{name:'staff-users'}">
      ユーザー一覧
    </router-link>
  </div>
  <div>
    <router-link v-show="[1,2].includes(this.roleType)" :to="{name:'staff-onetime-users'}">
      単発プランユーザー一覧
    </router-link>
  </div>
  <div>
    <router-link :to="{name:'staff-wash-requests'}">
      集配一覧
    </router-link>
  </div>
  <div>
    <router-link v-show="this.roleType === 1" :to="{name:'staff-business-holiday-setting'}">
      休業日管理
    </router-link>
  </div>
  <div>
    <router-link v-show="this.roleType === 1" :to="{name:'staff-manual-payments'}">
      手動決済管理
    </router-link>
  </div>
  <div>
    <router-link v-show="this.roleType === 1" :to="{name:'staff-maintenance'}">
    メンテナンス
    </router-link>
  </div>
  <div>
    <router-link :to="{name:'staff-operation-histories'}">
      操作履歴
    </router-link>
  </div>
  <div>
    <router-link v-show="this.roleType === 1" :to="{name:'staff-area-infos'}">
      提供エリア
    </router-link>
  </div>
  <div>
    <router-link v-show="this.roleType === 1" :to="{name:'admin-onetime-payments'}">
      単発料金管理
    </router-link>
  </div>
  <div>
    <router-link v-show="this.roleType === 1" :to="{name:'staff-members'}">
      運営アカウント管理
    </router-link>
  </div>
  <div>
  <a href="#" @click="logout">
      ログアウト
    </a>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import masterApi from '@/api/master'
export default {
  name: 'staff-top',
    data() {
    return {
      role: 0,
      roleType: 0
    }
  },
  computed: {
    ...mapGetters('user', [
      'isLoggedIn'
    ])
  },
  async mounted() {
    await this.$store.dispatch('user/getMe').then(me => {
      if (this.isLoggedIn) {
        this.role = me.role;
        this.roleType = me.role_type;
      }
    })

    // メンテナンス中は管理者とマネージャ以外アクセス不可
    masterApi.maintenanceIndex().then(({ data }) => {
      const maint_flg = data.maint_flg
      if (maint_flg === 1 && this.roleType !== 1) {
        this.$router.push({name: 'maintenance'})
      }
    })
  },
   methods: {
    logout() {
      this.$store.dispatch('user/logout')
        .then(() => {
          this.$store.dispatch('localStorage/set', {
            key: 'serverToken',
            val: null,
          })
          location.href = '/login'
        })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
