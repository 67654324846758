import axios from 'axios'
import * as utils from './api-utils'

export default {
  index() {
    return axios.get(utils.getApiUrl('/admin/users'))
  },
  register(data) {
    return axios.post(utils.getApiUrl('/admin/register'), data)
  },
  updatePassword(data) {
    return axios.post(utils.getApiUrl('/admin/password'), data)
  },
  bulkDelete(data) {
    return axios.post(utils.getApiUrl('/admin/bulkDelete'), data)
  },
  myOfficeStaffs() {
    return axios.get(utils.getApiUrl('/admin/users/myOfficeStaffs'))
  },
  getAllUser() {
    return axios.get(utils.getApiUrl('/admin/users/getAllUser'))
  },
  getIsAdmin() {
    return axios.get(utils.getApiUrl('/admin/users/getIsAdmin'))
  },
  getUser(id) {
    return axios.get(utils.getApiUrl(`/admin/users/${id}`))
  },
  update(id, data) {
    return axios.post(utils.getApiUrl(`/admin/users/${id}`), data)
  },
  updateAvailableRequestCount(id, data) {
    return axios.post(utils.getApiUrl(`/admin/users/${id}/availableRequestCount`), data)
  },
  getReceipts() {
    return axios.get(utils.getApiUrl('/admin/users/receipts'))
  },
  updateReceipt(id, data) {
    return axios.post(utils.getApiUrl(`/admin/users/${id}/receipt`), data)
  },
  updateManagementNotes(id, data) {
    return axios.post(utils.getApiUrl(`/admin/users/${id}/managementNotes`), data)
  },
  updateSat(id, data) {
    return axios.post(utils.getApiUrl(`/admin/users/${id}/sat`), data)
  },
  updateWashType(id, data) {
    return axios.post(utils.getApiUrl(`/admin/users/${id}/washType`), data)
  },
  updateFoldType(id, data) {
    return axios.post(utils.getApiUrl(`/admin/users/${id}/foldType`), data)
  },
  updateStatus(id, data) {
    return axios.post(utils.getApiUrl(`/admin/users/${id}/status`), data)
  },
  updateRole(id, data) {
    return axios.post(utils.getApiUrl(`/admin/users/${id}/role`), data)
  },
  updateWeight(id, data) {
    return axios.post(utils.getApiUrl(`/admin/users/${id}/weight`), data)
  },
  delete(id) {
    return axios.delete(utils.getApiUrl(`/admin/users/${id}`))
  },
  getUserCurrentPlan(id) {
    return axios.get(utils.getApiUrl(`/admin/users/${id}/currentUserPlan`))
  },
  getUserCurrentPlanTemp(id) {
    return axios.get(utils.getApiUrl(`/admin/users/${id}/currentUserPlanTemp`))
  },
  updateUserPlan(id, data) {
    return axios.post(utils.getApiUrl(`/admin/users/${id}/userPlan`), data)
  },
}
