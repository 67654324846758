import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const adminPathPrefix = '/admin-rok1ChahEeS4nejk'
const staffPathPrefix = '/staff-nH6xTYgbhnm5wbGa'

const urlBase = `${location.protocol}//${location.host}`

const router = new Router({
  mode: 'history',
  scrollBehavior (/*to, from, savedPosition*/) {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/login',
      name: 'login',
      component: require('@/components/Login').default,
      meta: {
        title: 'ログイン',
        description: 'オーサムウォッシュ会員様用ログインページです。',
        canonical: `${urlBase}/login`
      }
    },
    {
      path: '/register',
      name: 'register',
      component: require('@/components/Register/Register').default,
      meta: {
        title: '新規お申込み',
        description: 'オーサムウォッシュへご登録をご希望される方はこちらの新規お申込みフォームへご入力ください。',
        canonical: `${urlBase}/register`
      }
    },
    {
      path: '/register-complete/:id',
      name: 'register-complete',
      component: require('@/components/RegisterComplete').default
    },
    {
      path: '/onetime-register-complete/:id',
      name: 'onetime-register-complete',
      component: require('@/components/OnetimeRegisterComplete').default
    },
    {
      path: '/wash-request',
      name: 'wash-request',
      component: require('@/components/WashRequest').default
    },
    {
      path: '/onetime-wash-request-complete',
      name: 'onetime-wash-request-complete',
      component: require('@/components/OnetimeWashRequestComplete').default
    },
    {
      path: '/wash-request-currents',
      name: 'wash-request-currents',
      component: require('@/components/WashRequestCurrents').default
    },
    {
      path: '/wash-request-change/:id',
      name: 'wash-request-change',
      component: require('@/components/WashRequestChange').default,
      props: true
    },
    {
      path: '/wash-request-cancel/:id',
      name: 'wash-request-cancel',
      component: require('@/components/WashRequestCancel').default,
      props: true
    },
    {
      path: '/buy-wash-request',
      name: 'buy-wash-request',
      component: require('@/components/BuyWashRequest').default
    },
    {
      path: '/wash-history',
      name: 'wash-history',
      component: require('@/components/WashHistory').default
    },
    {
      path: '/profile',
      name: 'profile',
      component: require('@/components/Profile').default
    },
    {
      path: '/profile/edit',
      name: 'profile-edit',
      component: require('@/components/ProfileEdit').default
    },
    {
      path: '/plan',
      name: 'plan',
      component: require('@/components/Plan').default
    },
    {
      path: '/plan/edit',
      name: 'plan-edit',
      component: require('@/components/PlanEdit').default
    },
    {
      path: '/plan/edit-complete',
      name: 'plan-edit-complete',
      component: require('@/components/PlanEditComplete').default
    },
    {
      path: '/contact',
      name: 'contact',
      component: require('@/components/Contact').default
    },
    {
      path: '/notice',
      name: 'notice',
      component: require('@/components/Notice').default
    },
    {
      path: '/password/change',
      name: 'password-change',
      component: require('@/components/PasswordChange').default
    },
    {
      path: '/password/forgot',
      name: 'password-forgot',
      component: require('@/components/PasswordForgot').default
    },
    {
      path: '/password/reset/:token',
      name: 'password-reset',
      component: require('@/components/PasswordReset').default
    },
    {
      path: '/withdrawal',
      name: 'withdrawal',
      component: require('@/components/Withdrawal').default
    },
    {
      path: '/withdrawal-complete',
      name: 'withdrawal-complete',
      component: require('@/components/WithdrawalComplete').default
    },
    {
      path: '/maintenance',
      name: 'maintenance',
      component: require('@/components/Maintenance').default
    },
    {
      path: `${adminPathPrefix}`,
      name: 'admin-top',
      component: require('@/components/Admin/AdminTop').default
    },
    {
      path: `${adminPathPrefix}/users`,
      name: 'admin-users',
      component: require('@/components/Admin/AdminUserList').default,
      props: true
    },
    {
      path: `${adminPathPrefix}/onetime-users`,
      name: 'admin-onetime-users',
      component: require('@/components/Admin/AdminOnetimeUserList').default,
      props: true
    },
    {
      path: `${adminPathPrefix}/users-delete`,
      name: 'admin-users-delete',
      component: require('@/components/Admin/AdminUserListDelete').default,
      props: true
    },
    {
      path: `${adminPathPrefix}/plan/edit/:id`,
      name: 'admin-plan-edit',
      component: require('@/components/Admin/AdminPlanEdit').default,
      props: true
    },
    {
      path: `${adminPathPrefix}/plan/edit-complete`,
      name: 'admin-plan-edit-complete',
      component: require('@/components/Admin/AdminPlanEditComplete').default,
      props: true
    },
    {
      path: `${adminPathPrefix}/wash-requests`,
      name: 'admin-wash-requests',
      component: require('@/components/Admin/AdminWashRequestList/index.vue').default,
      props: true
    },
    {
      path: `${adminPathPrefix}/manual-payments`,
      name: 'admin-manual-payments',
      component: require('@/components/Admin/AdminManualPaymentsList').default,
      props: true
    },
    {
      path: `${adminPathPrefix}/wash-requests-change/:id`,
      name: 'admin-wash-requests-change',
      component: require('@/components/Admin/AdminWashRequestChange/index.vue').default,
      props: true
    },
    {
      path: `${adminPathPrefix}/wash-request-date-change/:id`,
      name: 'admin-wash-request-date-change',
      component: require('@/components/Admin/AdminWashRequestDateChange').default,
      props: true
    },
    {
      path: `${adminPathPrefix}/wash-request-cancel/:id`,
      name: 'admin-wash-request-cancel',
      component: require('@/components/Admin/AdminWashRequestCancel').default,
      props: true
    },
    {
      path: `${adminPathPrefix}/progress-management`,
      name: 'admin-progress-management',
      component: require('@/components/Admin/AdminProgressManagement').default,
      props: true
    },
    {
      path: `${adminPathPrefix}/business-holiday-setting`,
      name: 'admin-business-holiday-setting',
      component: require('@/components/Admin/AdminBusinessHolidaySetting').default,
      props: true
    },
    {
      path: `${adminPathPrefix}/maintenance`,
      name: 'admin-maintenance',
      component: require('@/components/Admin/AdminMaintenance').default,
      props: true
    },
    {
      path: `${adminPathPrefix}/operation-histories`,
      name: 'admin-operation-histories',
      component: require('@/components/Admin/AdminOperationHistory').default,
      props: true
    },
    {
      path: `${adminPathPrefix}/area-infos`,
      name: 'admin-area-infos',
      component: require('@/components/Admin/AdminAreaInfo').default,
      props: true
    },
    {
      path: `${adminPathPrefix}/onetime-payments`,
      name: 'admin-onetime-payments',
      component: require('@/components/Admin/AdminOnetimePayment').default,
      props: true
    },
    {
      path: `${adminPathPrefix}/members`,
      name: 'admin-members',
      component: require('@/components/Admin/AdminMemberList').default,
      props: true
    },
    {
      path: `${staffPathPrefix}`,
      name: 'staff-top',
      component: require('@/components/Staff/StaffTop').default
    },
    {
      path: `${staffPathPrefix}/users`,
      name: 'staff-users',
      component: require('@/components/Staff/StaffUserList').default,
      props: true
    },
    {
      path: `${staffPathPrefix}/users-delete`,
      name: 'staff-users-delete',
      component: require('@/components/Staff/StaffUserListDelete').default,
      props: true
    },
    {
      path: `${staffPathPrefix}/onetime-users`,
      name: 'staff-onetime-users',
      component: require('@/components/Staff/StaffOnetimeUserList').default,
      props: true
    },
    {
      path: `${staffPathPrefix}/plan/edit/:id`,
      name: 'staff-plan-edit',
      component: require('@/components/Staff/StaffPlanEdit').default,
      props: true
    },
    {
      path: `${staffPathPrefix}/plan/edit-complete`,
      name: 'staff-plan-edit-complete',
      component: require('@/components/Staff/StaffPlanEditComplete').default,
      props: true
    },
    {
      path: `${staffPathPrefix}/wash-requests`,
      name: 'staff-wash-requests',
      component: require('@/components/Staff/StaffWashRequestList').default,
      props: true
    },
    {
      path: `${staffPathPrefix}/manual-payments`,
      name: 'staff-manual-payments',
      component: require('@/components/Staff/StaffManualPaymentsList').default,
      props: true
    },
    {
      path: `${staffPathPrefix}/wash-requests-change/:id`,
      name: 'staff-wash-requests-change',
      component: require('@/components/Staff/StaffWashRequestChange').default,
      props: true
    },
    {
      path: `${staffPathPrefix}/wash-request-date-change/:id`,
      name: 'staff-wash-request-date-change',
      component: require('@/components/Staff/StaffWashRequestDateChange').default,
      props: true
    },
    {
      path: `${staffPathPrefix}/wash-request-cancel/:id`,
      name: 'staff-wash-request-cancel',
      component: require('@/components/Staff/StaffWashRequestCancel').default,
      props: true
    },
    {
      path: `${staffPathPrefix}/progress-management`,
      name: 'staff-progress-management',
      component: require('@/components/Staff/StaffProgressManagement').default,
      props: true
    },
    {
      path: `${staffPathPrefix}/business-holiday-setting`,
      name: 'staff-business-holiday-setting',
      component: require('@/components/Staff/StaffBusinessHolidaySetting').default,
      props: true
    },
    {
      path: `${staffPathPrefix}/maintenance`,
      name: 'staff-maintenance',
      component: require('@/components/Staff/StaffMaintenance').default,
      props: true
    },
    {
      path: `${staffPathPrefix}/operation-histories`,
      name: 'staff-operation-histories',
      component: require('@/components/Staff/StaffOperationHistory').default,
      props: true
    },
    {
      path: `${staffPathPrefix}/area-infos`,
      name: 'staff-area-infos',
      component: require('@/components/Staff/StaffAreaInfo').default,
      props: true
    },
    {
      path: `${staffPathPrefix}/onetime-payments`,
      name: 'staff-onetime-payments',
      component: require('@/components/Staff/StaffOnetimePayment').default,
      props: true
    },
    {
      path: `${staffPathPrefix}/members`,
      name: 'staff-members',
      component: require('@/components/Staff/StaffMemberList').default,
      props: true
    },
    // catch all
    {
      path: '*',
      redirect: {name: 'wash-request'},
    },
  ]
})


const defaultTitle = '洗濯代行サービスのオーサムウォッシュ'
const metaDescriptionElement = document.getElementById('head-meta-description')
const canonicalUrlElement = document.getElementById('head-canonical-url')

router.afterEach((to) => {
  document.title = to.meta.title
    ? `${to.meta.title} | ${defaultTitle}`
    : defaultTitle

  if (to.meta.description) {
    metaDescriptionElement.content = to.meta.description
  } else {
    metaDescriptionElement.remove()
  }

  if (to.meta.canonical) {
    canonicalUrlElement.href = to.meta.canonical
  } else {
    canonicalUrlElement.remove()
  }
})

export default router;
