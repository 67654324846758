<template>
<header class="contents_wrap">
  <div class="head_l"><a href="https://awesome-wash.com"><img src="/static/images/logo_awesomewash.svg" alt="洗濯代行サービス『オーサムウォッシュ』AWESOME WASH"></a></div>
  <div class="head_r tab_none sp_none">
    <a href="https://awesome-wash.com" target="_blank" class="btn_home link_head radius4">
      <p>AWESOME WASH ホーム</p>
    </a>
  </div>
</header>
</template>

<script>
export default {
  name: 'no-login-header',
}
</script>
