<template>
<div class="contents">

  <div class="list-box">
    <table class="tbl">
      <colgroup>
        <col style="width:5%;">
        <col style="width:15%;">
        <col style="width:20%;">
        <col style="width:15%;">
        <col style="width:25%;">
        <col style="width:20%;">
      </colgroup>
      <tr>
        <th></th>
        <th>作成日</th>
        <th>名前</th>
        <th>種別</th>
        <th>メールアドレス①</th>
        <th>パスワード（半角英数混合で8桁）</th>
      </tr>
      <tr v-for="(member, idx) in members">
        <td>
          <input type="checkbox" v-bind:value="member.id" v-model="memberIds">
        </td>
        <td>{{member.created_at | dtFormat('yyyy/mm/dd')}}
        </td>
        <td>{{member.name1}} {{member.name2}}</td>
        <td>{{member.role_type_str}}</td>
        <td>{{member.email}}</td>
        <td>
          ********
          <button class="btn btn_sm fr"
              @click="openPasswordChangeModal(member)">
            変更
          </button>
        </td>
      </tr>
    </table>
    <p class="submit_bx_l">
      <button class="submit1 bg_green"
        @click="openCreateModal">
        追加
      </button>
    </p>
    <p class="submit_bx_r">
      <button :disabled="memberIds.length == 0" v-bind:class="`submit1 ${[memberIds.length == 0 ? 'bg_gray' : 'bg_orange']}`"
        @click="deleteMember">
        削除
      </button>
    </p>
  </div>
  <modal
    val="newMember"
    v-if="showCreateModal"
    title="追加"
    bottomCloseButtonLabel="登録"
    bottomDismissButtonLabel="キャンセル"
    @close="addMember()"
    @dismiss="hideCreateModal()">
    <div slot="body">
      <table class="form_tab">
        <tr>
          <th><p class="item3">名前<span class="font_orange">*</span></p></th>
          <td>
            <p class="pc_br_none tab_br_none sp_br_yes">姓
              <input type="text" size="20" class="form_basic" v-model="newMember.name1"
                :class="{error_outline: this.errors.name}">　<br>名
              <input type="text" size="20" class="form_basic" v-model="newMember.name2"
                :class="{error_outline: this.errors.name}">
            </p>
            <p>
              <span class="iferror" v-show="errors.name_required">
                ▲ 必須項目です
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <th><p class="item2">種別<span class="font_orange">*</span></p></th>
          <td>
            <p>
              <select v-model="newMember.role_type" class="w200"
                  :class="{error_outline: this.errors.role_type}">
                <option value="">選択してください</option>
                <option v-for="elem in lovs.role_type" v-bind:value="elem.key">
                  {{elem.val}}
                </option>
              </select>
            </p>
            <p>
              <span class="iferror" v-show="errors.role_type_required">
                ▲ 必須項目です
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <th><p class="item3">メールアドレス①<span class="font_orange">*</span></p></th>
          <td>
            <p>
              <input type="text" class="form_basic form_70p" v-model="newMember.email" :class="{error_outline: this.errors.email}">
            </p>
            <p>
              <span class="iferror" v-show="errors.email_required">
                ▲ 必須項目です
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <th><p class="item3">メールアドレス①（確認）</p></th>
          <td>
            <p>
              <input type="text" class="form_basic form_70p" v-model="newMember.email_confirm" :class="{error_outline: this.errors.email_confirm}">
            </p>
            <p>
              <span class="iferror" v-show="errors.email_confirm_required">
                ▲ 必須項目です
              </span>
            </p>
            <p>
              <span class="iferror" v-show="errors.email_confirm_unmatch">
                ▲ 確認のため、メールアドレス①と同じ内容を入力してください
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <th><p class="item3">パスワード<span class="font_orange">*</span></p></th>
          <td>
            <p>
              <input type="password" maxlength="8" class="form_basic" v-model="newMember.password" :class="{error_outline: this.errors.password}"><span class="font_orange">　※半角英数混合で8桁</span>
            </p>
            <p>
              <span class="iferror" v-show="errors.password_required">
                ▲ 必須項目です
              </span>
            </p>
            <p>
              <span class="iferror" v-show="errors.password_invalid">
                ▲ 半角英数混合で8桁で入力してください
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <th><p class="item3">パスワード（確認）</p></th>
          <td>
            <p>
              <input type="password" maxlength="8" class="form_basic" v-model="newMember.password_confirm" :class="{error_outline: this.errors.password_confirm}"/>
            </p>
            <p>
              <span class="iferror" v-show="errors.password_confirm_required">
                ▲ 必須項目です
              </span>
            </p>
            <p>
              <span class="iferror" v-show="errors.password_confirm_unmatch">
                ▲ 確認のため、パスワードと同じ内容を入力してください
              </span>
            </p>
          </td>
        </tr>
      </table>
      <div class="err-msg-area">
        <p><span class="font_orange" v-for="e in serverErrors">
          {{e}}
        </span></p>
      </div>
    </div>
  </modal>
  <modal
    val="oneMember"
    v-if="showPasswordChangeModal"
    title="パスワード変更"
    bottomCloseButtonLabel="変更"
    bottomDismissButtonLabel="キャンセル"
    @close="updatePassword()"
    @dismiss="showPasswordChangeModal = false">
    <div slot="body">
      <table class="form_tab form_line_top">
        <tr>
          <th><p class="item3">パスワード<span class="font_orange">*</span></p></th>
          <td>
            <p>
              <input type="password" maxlength="8" class="form_basic"
                v-model="updateMember.password" :class="{error_outline: this.errors.password}"><span class="font_orange">　※半角英数混合で8桁</span>
            </p>
            <p>
              <span class="iferror" v-show="errors.password_required">
                ▲ 必須項目です
              </span>
            </p>
            <p>
              <span class="iferror" v-show="errors.password_invalid">
                ▲ 半角英数混合で8桁で入力してください
              </span>
            </p>
          </td>
        </tr>
        <tr>
          <th><p class="item3">パスワード（確認）</p></th>
          <td>
            <p>
              <input type="password" size="20" class="form_basic"
                v-model="updateMember.password_confirm" :class="{error_outline: this.errors.password_confirm}"/>
            </p>
            <p>
              <span class="iferror" v-show="errors.password_confirm_required">
                ▲ 必須項目です
              </span>
            </p>
            <p>
              <span class="iferror" v-show="errors.password_confirm_unmatch">
                ▲ 確認のため、パスワードと同じ内容を入力してください
              </span>
            </p>
          </td>
        </tr>
      </table>
      <div class="err-msg-area">
        <p><span class="font_orange" v-for="e in serverErrors">
          {{e}}
        </span></p>
      </div>
    </div>
  </modal>

</div>
</template>
<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import masterApi from '@/api/master'
import adminUserApi from '@/api/adminUser'
import adminUserMixin from '@/mixin/adminUserMixin'

export default {
  name: 'admin-members',
  props: {
    adminType: {
      type: String,
      default: 'admin'
    }
  },
  data() {
    return {
      members: [],
      memberIds: [],
      inputMode: false,
      lovs: {},
      roleTypeMap: {},
      newMember: {
        name1: '',
        name2: '',
        role_type: 0,
        email:  '',
        email_confirm:  '',
        password:  '',
        password_confirm:  ''
      },
      updateMember: {
        id: null,
        password:  '',
        password_confirm:  ''
      },
      showCreateModal: false,
      showPasswordChangeModal: false,

      roleType: 0,
      serverErrors: [],
      errors: {}
    }
  },
  async mounted() {
    await this.$store.dispatch('user/getMe').then(me => {
      this.roleType = me.role_type;
    })
    // メンテナンス中は管理者とマネージャ以外アクセス不可
    masterApi.maintenanceIndex().then(({ data }) => {
      const maint_flg = data.maint_flg
      if (maint_flg === 1 && this.adminType === 'staff' && this.roleType !== 1) {
        this.$router.push({name: 'maintenance'})
      }
    })
    // 管理者とマネージャー以外アクセス不可
    if (this.adminType === 'staff' && this.roleType !== 1) {
      this.$router.push({ name: 'staff-top' })
    }
    window.master.$promise.then(mst => {
      this.lovs = mst.lovs
      this.roleTypeMap = mst.lovsMap.role_type
      this.getMembers()
      this.resetNewInput()
    })
  },
  mixins: [adminUserMixin],
  methods: {
    getMembers() {
      adminUserApi.myOfficeStaffs().then(({ data }) => {
        this.members = data
          .map(e => this.convMember(e))
          .filter(e => e.role == 200)
      })
    },
    convMember(member) {
      const ret = Object.assign({}, member)
      ret['role_type_str'] = this.roleTypeMap[member.role_type] ? this.roleTypeMap[member.role_type].val : ''

      return ret
    },
    validate() {
      this.errors = {}
      let result = true

      if (!this.newMember.role_type) {
        this.errors['role_type'] = true
        this.errors['role_type_required'] = true
        result = false
      }

      if (!this.checkUserParams(this.newMember)) {
        result = false
      }

      return result
    },
    addMember() {
      if (!this.validate()) { return }
      this.serverErrors = []

      const data = Object.assign({}, this.newMember)
      adminUserApi.register(data)
        .then(({ data }) => {
          this.getMembers()
          this.resetNewInput()
          this.showCreateModal = false
        })
        .catch(e => {
          const errRes = e.response.data.errors
          let serverErrors = []
          Object.keys(errRes).forEach(k => {
            serverErrors = serverErrors.concat(errRes[k])
          })
          this.serverErrors = serverErrors
        })
    },
    validatePassword() {
      this.errors = {}
      let result = true

      if (!this.checkPassword(this.updateMember)) {
        result = false
      }

      return result
    },
    updatePassword() {
      if (!this.validatePassword()) { return }
      this.serverErrors = []

      adminUserApi.updatePassword(this.updateMember)
        .then(({ data }) => {
          this.getMembers()
          this.resetUpdateInput()
          this.showPasswordChangeModal = false
        }).catch(e => {
          const errRes = e.response.data.errors
          let serverErrors = []
          Object.keys(errRes).forEach(k => {
            serverErrors = serverErrors.concat(errRes[k])
          })
          this.serverErrors = serverErrors
        })
    },
    deleteMember() {
      if (this.memberIds.length === 0) return
      if (!confirm('選択したメンバーを削除します。')) {
        return
      }

      this.serverErrors = []

      const obj = {
        ids: this.memberIds
      }
      adminUserApi.bulkDelete(obj).then(() => {
        // なにもしない
      }).catch(e => {
        const errRes = e.response.data.errors
        this.serverErrors = 'メンバーの削除に失敗しました。(' + errRes.join(', ') + ')'
      }).finally(v=>{
        this.memberIds = []
        this.getMembers()
      })
    },
    openCreateModal() {
      this.resetNewInput()
      this.showCreateModal = true
    },
    openPasswordChangeModal(member) {
      this.resetUpdateInput()
      this.showPasswordChangeModal = true
      this.updateMember.id = member.id
    },
    hideCreateModal() {
      this.showCreateModal = false
      this.resetNewInput()
    },
    hidePasswordChangeModal() {
      this.showPasswordChangeModal = false
      this.resetUpdateInput()
    },
    resetNewInput() {
      this.newMember = {
        name1: '',
        name2: '',
        role_type: 0,
        email:  '',
        email_confirm:  '',
        password:  '',
        password_confirm:  ''
      }
    },
    resetUpdateInput() {
      this.updateMember = {
        id: null,
        password:  '',
        password_confirm:  ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.contents {
  width: 95%;
  margin: 20px auto 0;
  overflow-y: scroll;
  min-height: 400px;
}
.fr {
  float: right;
}
.btn {
  font-size: 16px;
  border-radius: 4px;
  background-color: #00a9ca;
  color: #ffffff;
  padding: 6px 12px;
  cursor: pointer;
}
.btn_sm {
  font-size: 12px;
  padding: 2px 6px;
}
::v-deep .modal-dialog {
  overflow: auto !important;
  max-height: 90%;
}
.list-box {
  margin: 20px 0 0 0;

  .tbl {
    table-layout: fixed;
    font-size: 14px;
    // white-space: nowrap;
    border-collapse: collapse;
    vertical-align: middle;
    width: 100%;
      span {
        word-break: break-all;
      }
  }
  th, td {
    border: 1px solid #aaa;
    padding: 2px 4px;
  }
}
</style>