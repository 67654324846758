<template>
<div class="contents">
  <admin adminType="staff" :searchPrm="searchPrm"/>
</div>
</template>
<script>
import Admin from './../Admin/AdminOperationHistory.vue'
export default {
  name: 'staff-operation-histories',
  props: {
    searchPrm: {
      type: Object,
      'default': () => ({
        operationDate: '',
        operationUserName: '',
        wreqId: '',
        userId: '',
        userName: '',
        companyName: '',
        operationType: '1',
        month: '',
      })
    }
  },
  components: {
    Admin
  }
}
</script>