<template>
<div class="contents_855">
  <header-bar active="wash-request"></header-bar>

  <!-- <h1 class="title2">集配依頼</h1> -->
  <div class="form_title">
    <button v-if="isOnetime" v-bind:class="activeClass01" class="title2">都度依頼</button>
    <button v-if="!isOnetime" @click="tabSelect1()" v-bind:class="activeClass01" class="title2">都度依頼</button>
    <button v-if="!isOnetime" @click="tabSelect2()" v-bind:class="activeClass02" class="title2">曜日固定</button>
  </div>

  <keep-alive>
    <component :is="currentTab" />
  </keep-alive>

  <modal
    v-if="showStatusStoppedModal"
    title="注意"
    bottomCloseButtonLabel="変更"
    :showBottomDismissButton=false
    @close="editCreditInfo()"
    @dismiss="showStatusStoppedModal = false">
    <div slot="body">
      <div>
        <p class="font_orange font_medium">
          {{stoppedMonth | dtFormat('m')}}月分の利用料金がクレジットカード決済エラーのため未払いとなっております。<br>
          マイページよりクレジットカードの変更を行なってください。<br><br>
          ●マイページ→プラン変更→現在と同じプランを選択→確認画面→OK<br><br>
          クレジットカードの変更と再決済が完了しだい一時停止が解除されます。
        </p>
      </div>
    </div>
  </modal>

</div>
</template>

<script>
import WashRequestEach from './WashRequestEach.vue'
import WashRequestWeek from './WashRequestWeek.vue'
import WashRequestOnetime from './WashRequestOnetime.vue'
import userApi from '@/api/user'

export default {
  name: 'wash-request',
  components: {
    WashRequestEach,
    WashRequestWeek,
    WashRequestOnetime,
  },
  data() {
    return {
      currentTab: 'WashRequestEach',
      activeClass01: 'title_active',
      activeClass02: 'title_inactive',
      showStatusStoppedModal: false,
      stoppedMonth: '',
      isOnetime: false,
    }
  },
  async mounted() {
    const token = this.$store.getters['localStorage/serverToken']
    if (!token) {
      this.$router.push({name: 'login'})
    }
    await this.getMe()
    userApi.getStoppedInfo().then(({ data }) => {
      const user = data['user'];
      if (user['status'] == 2 && user['payment_method_id'] == 1) {
        const stoppedUserInfos = data['stopped_infos']
        stoppedUserInfos.forEach(k => {
          if (k['type'] == 1 && k['solved'] == 0) {
            this.stoppedMonth = k['updated_at']
          }
        })
        if (!this.isOnetime) {
          this.showStatusStoppedModal = true
        }
      }
    })
  },
  methods: {
    async getMe() {
      await this.$store.dispatch('user/getMe')
        .then(me => {
          const planInfos = me.user_plan.plan_id.split('_')
          if (planInfos[0] == 'tx') {
            this.isOnetime = true
          }
          if (this.isOnetime) {
            this.currentTab = 'WashRequestOnetime'
          }
      })
    },
    tabSelect1() {
      this.activeClass01 = 'title_active'
      this.activeClass02 = 'title_inactive'
      this.currentTab = 'WashRequestEach'
    },
    tabSelect2() {
      this.activeClass01 = 'title_inactive'
      this.activeClass02 = 'title_active'
      this.currentTab = 'WashRequestWeek'
    },
    editCreditInfo() {
      this.showStatusStoppedModal = false
      this.$router.push({name: 'plan-edit'})
    },
  }
}
</script>

<style lang="scss" scoped>
.form_title{
    display:flex;
}
.title_active,.title_inactive{
  width: 100%;
  margin-bottom: 20px;
  padding: 12px 0;
  text-align:center;
  font-size: 16px;
  font-weight: normal;
  clear:both;
}
.title_active{
  background-color: #00a9ca;
  color: #fff;
}
.title_inactive{
  background-color: #b7e6f1;
  color: #00a9ca;
}
.pickup_quantity{
  width:100px;
}
</style>
