<template>
  <div id="app">
    <env-logo></env-logo>
    <global-header></global-header>
    <router-view></router-view>
    <global-footer></global-footer>
  </div>
</template>

<script>
import axios from 'axios'
import GlobalHeader from '@/components/lib/GlobalHeader'
import GlobalFooter from '@/components/lib/GlobalFooter'
import EnvLogo from '@/components/lib/EnvLogo'
import routeMixin from '@/mixin/routeMixin'
import masterApi from '@/api/master'

export default {
  name: 'app',
  created() {
    this.checkMaintenance()
    this.setAxios()
    this.setupMaster()
  },
  mixins: [routeMixin],
  methods: {
    setAxios() {
      // content type
      axios.defaults.headers['Content-Type'] = 'application/json'
      // token
      const token = this.$store.getters['localStorage/serverToken']
      axios.defaults.headers['Authorization'] = `Bearer ${token}`

      // go to login if 401 is returned
      axios.interceptors.response.use(response => {
        return response
      }, error => {
        const st = error.response.status
        if (st === 401 && !this.isGuestPage()) {
          setTimeout(() => {
            location.href = '/login'
          }, 0)
        }
        return Promise.reject(error)
      });

      if (!this.isGuestPage()) {
        this.$store.dispatch('user/getMe')
            .then(() => {
              // nothing to do
            })
            .catch(e => {
              this.$router.push({name: 'login'})
            })
      }
    },
    setupMaster() {
      window.master = {
        $promise: masterApi.getAll()
      }
      window.master.$promise.then(data => {
        const prms = window.master.$promise
        window.master = data
        window.master.$promise = prms
      })
    },
    checkMaintenance() {
      masterApi.maintenanceIndex().then(({ data }) => {
        const maint_flg = data.maint_flg
        if (maint_flg === 1 && !this.$route.name.match(/login/) && !this.isAdminPage() && !this.isStaffPage()) {
          this.$router.push({name: 'maintenance'})
        }
        if (maint_flg === 0 && this.$route.name.match(/maintenance/) && !this.isAdminPage() && !this.isStaffPage()) {
          this.$router.push({name: 'login'})
        }
      })
    }
  },
  components: { GlobalHeader, GlobalFooter, EnvLogo }
}
</script>

<style lang="scss">
@import '../style/global_vars.scss';
</style>

<style scoped lang="scss">
#app {
  position: relative;
}
</style>
