<template>
<div class="env-logo" v-if="showEnvLogo">
  <span :class="envNameClz">{{envNameDisp}}</span>
</div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: 'env-logo',
  data() {
    return {}
  },
  computed: {
    ...mapState('user', {
      envName: state => state.env,
    }),
    envNameDisp() {
      let ret = ''
      if (this.envName === 'local') {
        ret = '*DEV*'
      } else if (this.envName === 'staging') {
        ret = '*STG*'
      }
      return ret
    },
    envNameClz() {
      return this.envNameDisp.replace(/\*/g, '').toLowerCase()
    },
    showEnvLogo() {
      return this.envName !== 'production'
    }
  },
}
</script>

<style scoped lang="scss">
.env-logo {
  position: absolute;
  top: 4px;
  left: 4px;
  font-size: 14px;
  font-weight: bold;

  .dev {
    color: #27a31a;
  }
  .stg {
    color: orange;
  }
}
</style>
