<template>
<div class="contents">
  <div class="search-box">
    <div class="ilblk">
      <div>登録日</div>
      <div>
        <my-calendar
          v-model="search.registerDtFrom"
          width="75px"
        ></my-calendar>
        <span class="cal-tilda">〜</span>
        <my-calendar
          v-model="search.registerDtTo"
          width="75px"
        ></my-calendar>
      </div>
    </div>
    <div class="ilblk">
      <div>ユーザーID</div>
      <div>
        <input type="text" v-model="search.userId" class="form-control">
      </div>
    </div>
    <div class="ilblk">
      <div>名前</div>
      <div>
        <input type="text" v-model="search.name" class="form-control">
      </div>
    </div>
    <div class="ilblk">
      <div>かな</div>
      <div>
        <input type="text" v-model="search.kana" class="form-control">
      </div>
    </div>
    <div class="ilblk" style="width:100px;">
      <div>郵便番号</div>
      <div>
        <input type="text" v-model="search.billAddrZipcode" class="form-control">
      </div>
    </div>
    <div class="ilblk" style="width:110px;">
      <div>電話番号</div>
      <div>
        <input type="text" v-model="search.phoneNumber" class="form-control">
      </div>
    </div>
    <div class="ilblk">
      <div>メールアドレス①</div>
      <div>
        <input type="text" v-model="search.email" class="form-control">
      </div>
    </div>
    <div class="ilblk">
      <div>利用ステータス</div>
      <div>
        <select v-model="search.status" style="width:100px;">
          <option value="">全て</option>
          <option v-for="elem in lovs.status_type" :value="elem.key">
            {{elem.val}}
          </option>
        </select>
      </div>
    </div>
    <div class="ilblk">
      <button class="btn" @click="searchUsers()">
        <i class="fa fa-search"></i>
        検索
      </button>
    </div>
    <div class="ilblk" v-show="isAdmin">
      <button class="btn btn_yellow" @click="downloadReceiptCsv()">
        請求書CSV
      </button>
      <VueJsonToCsv :json-data="receiptCsvJsonData" :csv-title="receiptCsvTitle" :labels="receiptCsvLabels">
        <button class="dispnone" id="receiptCsvDownloadbtn"></button>
      </VueJsonToCsv>
    </div>
  </div>

  <div class="list-box">
    <table class="tbl">
      <colgroup>
        <col style="width:60px;">
        <col style="width:30px;">
        <col style="width:80px;">
        <col style="width:40px;">
        <col style="width:40px;">
        <col style="width:130px;">
        <col style="width:120px;">
        <col style="width:80px;">
        <col style="width:50px;">
        <col style="width:150px;">
        <col style="width:40px;">
        <col style="width:90px;">
        <col style="width:90px;">
        <col style="width:40px;">
        <col style="width:40px;">
        <col style="width:40px;">
        <col style="width:40px;">
        <col style="width:40px;">
        <col style="width:40px;">
        <col style="width:50px;">
        <col style="width:40px;">
      </colgroup>
      <div>アクティブユーザー件数:{{this.activeUsers.length}}</div>
      <tr>
        <th>登録日時</th>
        <th>ユーザーID</th>
        <th>名前 (かな)</th>
        <th>残個数</th>
        <th>重み(たたみ)</th>
        <th>現在プラン (開始日)</th>
        <th>翌月以降プラン (開始日)</th>
        <th>集配方法</th>
        <th>会社/店舗名</th>
        <th>住所1</th>
        <th>電話番号</th>
        <th>メールアドレス①</th>
        <th>メールアドレス②</th>
        <th>決済方法</th>
        <th>領収書</th>
        <th>運営メモ</th>
        <th>土曜集配</th>
        <th>洗い方指定</th>
        <th>たたみ方指定</th>
        <th>利用ステータス</th>
        <th>権限</th>
      </tr>
      <tr v-for="(user, idx) in users">
        <td>{{user.created_at | dtFormat('yyyy/mm/dd HH:MM:SS')}}</td>
        <td>{{user.id}}</td>
        <td>{{user.name1}} {{user.name2}} ({{user.kana1}} {{user.kana2}})</td>
        <td>
          <div v-show="!user.input_available_request_count">
            <span>{{user.available_request_count}}</span>
            <button v-show="isAdminAndManager" class="btn btn_sm bg_danger fr"
                v-if="Number(user.available_request_count) === 0"
                @click="switchToInputMode(user, 1)">
              減
            </button>
            <button v-show="isAdminAndManager" class="btn btn_sm bg_blue fr"
                @click="switchToInputMode(user, 0)">
              更新
            </button>
          </div>
          <div v-show="user.input_available_request_count">
            <div>
              <input type="text" class="form-control-sm" size="4"
                v-model="user.input_available_request_count_str">
            </div>
            <div style="margin:4px 0 0 0;">
              <button class="btn btn_sm bg_danger"
                  @click="updateAvailableRequestCount(idx, user)">
                確定
              </button>
              <button class="btn btn_sm bg_blue"
                  @click="switchToNonInputMode(user)">
                キャンセル
              </button>
            </div>
          </div>
        </td>
        <td>
          <div v-show="!user.input_weight">
            <span>{{user.weightDisp}}</span>
            <button v-show="isAdminAndManager" class="btn btn_sm bg_blue fr"
                @click="switchToInputModeWeight(user)">
              更新
            </button>
          </div>
          <div v-show="user.input_weight">
            <div>
              <select v-model="user.input_weight_str" style="width:100px;">
                <option v-for="elem in weightDispList" v-bind:value="elem.key">
                  {{elem.display}}
                </option>
              </select>
            </div>
            <div style="margin:4px 0 0 0;">
              <button class="btn btn_sm bg_danger"
                  @click="updateWeight(idx, user)">
                確定
              </button>
              <button class="btn btn_sm bg_blue"
                  @click="switchToNonInputModeWeight(user)">
                キャンセル
              </button>
            </div>
          </div>
        </td>
        <td>
          {{user.planPart1Disp}} ({{user.pStartDisp | dtFormat('yyyy/mm/dd')}}~)
            <button v-show="isAdminAndManager" @click="changePlan(user)" class="btn btn1 btn_sm bg_blue fr">
              編集
            </button>
        </td>
        <td>{{user.nextMonthPlanPart1Disp}} {{user.nextMonthPStartDisp}}</td>
        <td>{{user.pickupDeliveryTypeDisp}}</td>
        <td>{{user.company_name}}</td>
        <td style="white-space:normal;">〒{{user.bill_addr_zipcode}} {{user.bill_addr_detail1}} {{user.bill_addr_detail2}} {{user.bill_addr_detail3}}</td>
        <td>{{user.phone_number}}</td>
        <td>{{user.email}}</td>
        <td>{{user.email2}}</td>
        <td>{{paymentMethodMap[user.payment_method_id].val}}</td>
        <td>
          <div v-show="!user.input_receipt">
            <span>{{user.receiptDisp}}</span>
            <button v-show="isAdmin && user.payment_method_id == 1" class="btn btn_sm bg_blue fr"
                @click="switchToInputModeReceipt(user)">
              更新
            </button>
          </div>
          <div v-show="user.input_receipt">
            <div>
              <select v-model="user.input_receipt_str" style="width:100px;">
                <option value="1">あり</option>
                <option value="0">なし</option>
              </select>
            </div>
            <div style="margin:4px 0 0 0;">
              <button class="btn btn_sm bg_danger"
                  @click="updateReceipt(idx, user)">
                確定
              </button>
              <button class="btn btn_sm bg_blue"
                  @click="switchToNonInputModeReceipt(user)">
                キャンセル
              </button>
            </div>
          </div>
        </td>
        <td>
          <span v-if="user.management_notes">有り</span>
          <span v-else>無し</span>
          <button class="btn btn_sm bg_blue fr"
            @click="openManagementNotesModal(user)">
            編集
          </button>
        </td>
        <td>
          <div v-show="!user.input_sat">
            <span>{{user.satDisp}}</span>
            <button v-show="isAdminAndManager" class="btn btn_sm bg_blue fr"
                @click="switchToInputModeSat(user)">
              更新
            </button>
          </div>
          <div v-show="user.input_sat">
            <div>
              <select v-model="user.input_sat_str" style="width:100px;">
                <option value="1">可能</option>
                <option value="0">不可</option>
              </select>
            </div>
            <div v-show="!canChangeSatRequest[user.id]">
              <span class="iferror submit_text">
                ▲ 変更不可
              </span>
            </div>
            <div style="margin:4px 0 0 0;">
              <button class="btn btn_sm bg_danger"
                  @click="updateSat(idx, user)">
                確定
              </button>
              <button class="btn btn_sm bg_blue"
                  @click="switchToNonInputModeSat(user)">
                キャンセル
              </button>
            </div>
          </div>
        </td>
        <td>
          <div v-show="!user.input_wash_type">
            <span>{{user.washTypeDisp}}</span>
            <button v-show="isAdmin" class="btn btn_sm bg_blue fr"
                @click="switchToInputModeWashType(user)">
              更新
            </button>
          </div>
          <div v-show="user.input_wash_type">
            <div>
              <select v-model="user.input_wash_type_str" style="width:100px;">
                <option value="1">あり</option>
                <option value="0">なし</option>
              </select>
            </div>
            <div style="margin:4px 0 0 0;">
              <button class="btn btn_sm bg_danger"
                  @click="updateWashType(idx, user)">
                確定
              </button>
              <button class="btn btn_sm bg_blue"
                  @click="switchToNonInputModeWashType(user)">
                キャンセル
              </button>
            </div>
          </div>
        </td>
        <td>
          <div v-show="!user.input_fold_type">
            <span>{{user.foldTypeDisp}}</span>
            <button v-show="isAdmin" class="btn btn_sm bg_blue fr"
                @click="switchToInputModeFoldType(user)">
              更新
            </button>
          </div>
          <div v-show="user.input_fold_type">
            <div>
              <select v-model="user.input_fold_type_str" style="width:100px;">
                <option value="1">あり</option>
                <option value="0">なし</option>
              </select>
            </div>
            <div style="margin:4px 0 0 0;">
              <button class="btn btn_sm bg_danger"
                  @click="updateFoldType(idx, user)">
                確定
              </button>
              <button class="btn btn_sm bg_blue"
                  @click="switchToNonInputModeFoldType(user)">
                キャンセル
              </button>
            </div>
          </div>
        </td>
        <td>
          <div v-show="!user.input_status">
            <span>{{statusMap[user.status]}}</span>
            <button v-show="isAdminAndManager" class="btn btn_sm bg_blue fr"
              @click="switchToInputModeStatus(user)">
              更新
            </button>
            <button v-show="user.is_admin === 0 && isAdminAndManager" class="btn btn_sm bg_danger fr"
              @click="deleteUser(user.id)">
              削除
            </button>
          </div>
          <div v-show="user.input_status">
            <div>
              <select v-model="user.input_status_str" style="width:100px;">
                <option v-for="elem in statusMapEdit" v-bind:value="elem.key">
                  {{elem.val}}
                </option>
              </select>
            </div>
            <div style="margin:4px 0 0 0;">
              <button class="btn btn_sm bg_danger"
                  @click="updateStatus(idx, user)">
                確定
              </button>
              <button class="btn btn_sm bg_blue"
                  @click="switchToNonInputModeStatus(user)">
                キャンセル
              </button>
            </div>
          </div>
        </td>
        <td>
          <div v-show="!user.input_role">
            <span>{{user.roleDisp}}</span>
            <button v-show="isAdminAndManager" class="btn btn_sm bg_blue fr"
                @click="switchToInputModeRole(user)">
              更新
            </button>
          </div>
          <div v-show="user.input_role">
            <div>
              <select v-model="user.input_role_str" style="width:100px;">
                <option v-for="elem in filterRole" v-bind:value="elem.key">
                  {{elem.val}}
                </option>
              </select>
            </div>
            <div style="margin:4px 0 0 0;">
              <button class="btn btn_sm bg_danger"
                  @click="updateRole(idx, user)">
                確定
              </button>
              <button class="btn btn_sm bg_blue"
                  @click="switchToNonInputModeRole(user)">
                キャンセル
              </button>
            </div>
          </div>
        </td>
      </tr>
    </table>
  </div>

  <modal
    val="userInfo"
    v-if="showManagementNotesModal"
    title="運営メモ"
    bottomCloseButtonLabel="更新"
    @close="updateManagementNotes()"
    @dismiss="showManagementNotesModal = false">
    <div slot="body">
      <p><textarea type="text" class="form_basic" v-model="userInfo.management_notes"></textarea></p>
      <div class="err-msg-area">
        <p><span class="font_orange" v-for="e in serverErrors">
          {{e}}
        </span></p>
      </div>
    </div>
  </modal>

  <loading
    v-if="showLoadingModal"
    title="CSV生成中">
  </loading>

</div>
</template>

<script>
import Vue from 'vue'
import VueJsonToCsv from "vue-json-to-csv";
import masterApi from '@/api/master'
import adminUserApi from '@/api/adminUser'

export default {
  components: {
    VueJsonToCsv,
  },
  name: 'admin-user-list',
  props: {
    searchPrm: {
      type: Object,
      'default': () => ({
        registerDtFrom: '',
        registerDtTo: '',
        userId: '',
        name: '',
        kana: '',
        billAddrZipcode: '',
        phoneNumber: '',
        email: '',
        status: '',
      })
    },
    adminType: {
      type: String,
      default: 'admin'
    }
  },
  data() {
    return {
      search: {
        registerDtFrom: '',
        registerDtTo: '',
        userId: '',
        name: '',
        kana: '',
        billAddrZipcode: '',
        phoneNumber: '',
        email: '',
        status: '',
      },

      lovs: {},
      planMap: {},
      pdTypeMap: {},
      statusMap: {},
      statusMapEdit: {},
      roleMap: {},
      weightMap: {},
      weightPointMap: {},
      weightDispList: {},
      optionMap: {},
      paymentMethodMap: {},
      users: [],
      activeUsers: [],
      canChangeSatRequest: [],

      showManagementNotesModal: false,
      userInfo: {},

      receiptCsvJsonData: [],
      csvData: [],
      receiptCsvLabels: {},
      receiptCsvTitle: '請求書データ',
      showLoadingModal: false,

      roleType: 0,
      serverErrors: [],
    }
  },
  async mounted() {
    await this.$store.dispatch('user/getMe').then(me => {
      this.roleType = me.role_type;
    })
    // メンテナンス中は管理者とマネージャ以外アクセス不可
    masterApi.maintenanceIndex().then(({ data }) => {
      const maint_flg = data.maint_flg
      if (maint_flg === 1 && this.adminType === 'staff' && this.roleType !== 1) {
        this.$router.push({name: 'maintenance'})
      }
    })
    // 管理者とマネージャー以外アクセス不可
    if (this.adminType === 'staff' && ![1,2].includes(this.roleType)) {
      this.$router.push({ name: 'staff-top' })
    }

    window.master.$promise.then(mst => {
      this.lovs = mst.lovs
      this.planMap = mst.planMap
      this.pdTypeMap = mst.lovsMap.pd_type
      this.roleMap = mst.lovsMap.role
      this.weightMap = mst.lovsMap.weight
      this.weightPointMap =  mst.lovsMap.weight_point
      this.optionMap = mst.lovsMap.user_plan_option
      this.paymentMethodMap = mst.lovsMap.payment_method

      this.weightDispList = this.lovs.weight.reduce((acc, e) => {
        e.display = e.val + '(' + this.lovs.weight_point[Number(e.key) -1].val + ')'
        acc[e.key] = e;

        return acc
      }, {})

      this.setSearchPrm()

      const statusMap = {}
      const statusMapEdit = []
      this.lovs.status_type.forEach(e => {
        statusMap[parseInt(e.key)] = e.val
        if (e.key != 0) {
          statusMapEdit.push(e)
        }
      })
      this.statusMap = statusMap
      this.statusMapEdit = statusMapEdit

      this.searchUsers()
    })
  },
  computed: {
    isAdmin() {
      return this.adminType === 'admin'
    },
    isAdminAndManager() {
      return this.adminType === 'admin' || (this.adminType === 'staff' && this.roleType === 1)
    },
    filterRole() {
      return this.lovs.role.filter(e => e.key != 200)
    }
  },
  methods: {
    convUser(user) {
      const ret = Object.assign({}, user)

      this.canChangeSatRequest[user.id] = true
      ret.receiptDisp = this.convReceipt(user.receipt)
      ret.satDisp = this.convSat(user.is_saturday_available)
      ret.washTypeDisp = this.convWashType(user.wash_type_setting)
      ret.foldTypeDisp = this.convFoldType(user.fold_type_setting)
      ret.roleDisp = this.convRole(user.role)
      ret.weightDisp = this.convWeight(user.weight)
      ret.planPart1Disp = this.convPlanPart1(user.plan_id, user.user_plan_option)
      ret.nextMonthPlanPart1Disp = this.convPlanPart1(user.next_month_plan_id, user.next_month_user_plan_option)

      // 現在プランの開始日を月初に変更
      let pStartDisp = new Date(user.p_start)
      if (user.changed_plan_type_dt) {
        pStartDisp = new Date(user.changed_plan_type_dt)
      } else if (user.is_first_plan == 1) {
        pStartDisp.setDate(1)
      } else {
        pStartDisp.setDate(1)
        pStartDisp.setMonth(pStartDisp.getMonth() + 1)
      }

      ret.pStartDisp = pStartDisp

      if (user.next_month_p_start) {
        // 翌月からプランが変わる場合
        const dtFormat = Vue.filter('dtFormat')
        const nextMonthPStartDisp = new Date(user.next_month_p_start)
        // 翌月の月初に変換
        nextMonthPStartDisp.setDate(1)
        nextMonthPStartDisp.setMonth(nextMonthPStartDisp.getMonth() + 1)
        ret.nextMonthPStartDisp = '(' + dtFormat(nextMonthPStartDisp, 'yyyy/mm/dd') + '~)'
      }
      else {
        // 翌月もプランが変わらない場合
        ret.nextMonthPStartDisp = '同左'
      }

      ret.pickupDeliveryTypeDisp = this.convPickupDeliveryType(user.pickup_delivery_type)

      ret['input_available_request_count'] = false
      ret['input_available_request_count_str'] = null
      ret['input_receipt'] = false
      ret['input_receipt_str'] = null
      ret['input_sat'] = false
      ret['input_sat_str'] = null
      ret['input_wash_type'] = false
      ret['input_wash_type_str'] = null
      ret['input_fold_type'] = false
      ret['input_fold_type_str'] = null
      ret['input_status'] = false
      ret['input_status_str'] = null
      ret['input_role'] = false
      ret['input_role_str'] = null
      ret['input_weight'] = false
      ret['input_weight_str'] = null
      ret['decreaseFlg'] = 0

      return ret
    },
    convReceipt(receipt) {
      let obj = ''
      if (receipt == 1) {
        obj = 'あり'
      } else {
        obj = 'なし'
      }
      return obj
    },
    convSat(isSaturdayAvailable) {
      let obj = ''
      if (isSaturdayAvailable == 1) {
        obj = '可能'
      } else {
        obj = '不可'
      }
      return obj
    },
    convWashType(wash_type) {
      let obj = ''
      if (wash_type == 1) {
        obj = 'あり'
      } else {
        obj = 'なし'
      }
      return obj
    },
    convFoldType(fold_type) {
      let obj = ''
      if (fold_type == 1) {
        obj = 'あり'
      } else {
        obj = 'なし'
      }
      return obj
    },
    convStatus(status) {
      const obj = this.statusMap[status] || {}
      return obj.val
    },
    convRole(role) {
      const obj = this.roleMap[role] || {}
      return obj.val
    },
    convWeight(weight) {
      const obj = this.weightMap[weight] || {}
      const dispObj = this.weightPointMap[weight] || {}
      return obj.val + '(' + dispObj.val + ')'
    },
    convPlanPart1(planId, option) {
      let planPart1Disp = ''
      const plan = this.planMap[planId]
      if (plan) { planPart1Disp = plan.name + this.optionDisp(option) }
      return planPart1Disp
    },
    optionDisp(option) {
      const obj = this.optionMap[option]
      return obj ? '/' + obj.val : ''
    },
    convPickupDeliveryType(pickupDeliveryType) {
      const obj = this.pdTypeMap[pickupDeliveryType] || {}
      return obj.val
    },
    filterBySearchParams(user) {
      const search = this.search
      if (search.registerDtFrom) {
        const dtStrConv = search.registerDtFrom.replace(/\//g, '-')
        const dt = new Date(`${dtStrConv}T00:00:00`)
        const createdAt = new Date(user.created_at.replace(' ', 'T'))
        if (dt > createdAt) { return false }
      }
      if (search.registerDtTo) {
        const dtStrConv = search.registerDtTo.replace(/\//g, '-')
        const dt = new Date(`${dtStrConv}T23:59:59`)
        const createdAt = new Date(user.created_at.replace(' ', 'T'))
        if (createdAt > dt) { return false }
      }
      if (search.userId) {
        if (user.id.toString() !== search.userId) { return false }
      }
      if (search.name) {
        const nameStr = `${user.name1} ${user.name2}`
        const reg = new RegExp(search.name)
        if (!nameStr.match(reg)) { return false }
      }
      if (search.kana) {
        const kanaStr = `${user.kana1} ${user.kana2}`
        const reg = new RegExp(search.kana)
        if (!kanaStr.match(reg)) { return false }
      }
      if (search.billAddrZipcode) {
        const zcStr = user.bill_addr_zipcode.replace(/-/g, '')
        const reg = new RegExp(search.billAddrZipcode.replace(/-/g, ''))
        if (!zcStr.match(reg)) { return false }
      }
      if (search.phoneNumber) {
        const pnStr = user.phone_number.replace(/-/g, '')
        const reg = new RegExp(search.phoneNumber.replace(/-/, ''))
        if (!pnStr.match(reg)) { return false }
      }
      if (search.email) {
        const reg = new RegExp(search.email)
        if (!user.email.match(reg)) { return false }
      }
      if (search.status) {
        if (user.status !== parseInt(search.status)) { return false }
      }

      return true
    },
    searchUsers() {
      adminUserApi.index().then(({ data }) => {
        this.users = data
          .map(e => this.convUser(e))
          .filter(e => e.role !== 200)
          .filter(e => e.plan_id.split('_')[0] !== 'tx')
          .filter(e => this.filterBySearchParams(e))
        this.activeUsers = this.users.filter(e =>
        e.status === 1 && e.role === 900)
      })
    },
    setSearchPrm() {
      this.search = this.searchPrm
    },
    switchToInputMode(user, flg) {
      user.input_available_request_count = true
      user.input_available_request_count_str = user.available_request_count
      user.decreaseFlg = flg
    },
    switchToNonInputMode(user) {
      user.input_available_request_count = false
      user.input_available_request_count_str = null
    },
    updateAvailableRequestCount(idx, user) {
      const count = user.input_available_request_count_str || ''
      const flg = user.decreaseFlg
      const pattern = /^[1-9]\d?$|^0$|^100$/
      if (!pattern.test(count)) { return }

      const obj = {
        ['available_request_count']: count,
        ['decreaseFlg']: flg
      }
      adminUserApi.updateAvailableRequestCount(user.id, obj).then(() => {
        if (user.decreaseFlg === 1) {
          user.available_request_count = 0
        } else {
          user.available_request_count = count
        }
        Vue.set(this.users, idx, user)
        this.switchToNonInputMode(user)
      })
    },
    openManagementNotesModal(user) {
      this.userInfo = user
      this.showManagementNotesModal = true
    },
    updateManagementNotes() {
      this.serverErrors = []

      const obj = {
        ['management_notes']: this.userInfo.management_notes
      }
      adminUserApi.updateManagementNotes(this.userInfo.id, obj).then(() => {
        this.userInfo = {}
        this.showManagementNotesModal = false
      }).catch(e => {
        const errRes = e.response.data.errors;
        let serverErrors = []
        Object.keys(errRes).forEach(k => {
          serverErrors = serverErrors.concat(errRes[k])
        })
        this.serverErrors = serverErrors;
      })
    },
    deleteUser(userId) {
      let name = 'admin-users-delete'
      if (this.adminType === 'staff') {
        name = 'staff-users-delete'
      }
      this.$router.push({
        name: name,
        params: {
          id: userId
        }
      })
    },
    switchToInputModeReceipt(user) {
      user.input_receipt = true
      user.input_receipt_str = user.receipt
    },
    switchToNonInputModeReceipt(user) {
      user.input_receipt = false
      user.input_receipt_str = null
    },
    switchToInputModeSat(user) {
      user.input_sat = true
      user.input_sat_str = user.is_saturday_available
    },
    switchToNonInputModeSat(user) {
      user.input_sat = false
      user.input_sat_str = null
      this.canChangeSatRequest[user.id] = true
    },
    switchToInputModeWashType(user) {
      user.input_wash_type = true
      user.input_wash_type_str = user.wash_type_setting
    },
    switchToNonInputModeWashType(user) {
      user.input_wash_type = false
      user.input_wash_type_str = null
    },
    switchToInputModeFoldType(user) {
      user.input_fold_type = true
      user.input_fold_type_str = user.fold_type_setting
    },
    switchToNonInputModeFoldType(user) {
      user.input_fold_type = false
      user.input_fold_type_str = null
    },
    switchToInputModeStatus(user) {
      user.input_status = true
      user.input_status_str = user.status
    },
    switchToNonInputModeStatus(user) {
      user.input_status = false
      user.input_status_str = null
    },
    switchToInputModeRole(user) {
      user.input_role = true
      user.input_role_str = user.role
    },
    switchToNonInputModeRole(user) {
      user.input_role = false
      user.input_role_str = null
    },
    switchToInputModeWeight(user) {
      user.input_weight = true
      user.input_weight_str = user.weight
    },
    switchToNonInputModeWeight(user) {
      user.input_weight = false
      user.input_weight_str = null
    },
    updateReceipt(idx, user) {
      const obj = {
        ['receipt']: user.input_receipt_str
      }
      adminUserApi.updateReceipt(user.id, obj).then(() => {
        user.receipt = user.input_receipt_str
        user.receiptDisp = this.convReceipt(user.receipt)
        Vue.set(this.users, idx, user)
        this.switchToNonInputModeReceipt(user)
      })
    },
    updateSat(idx, user) {
      const obj = {
        ['is_saturday_available']: user.input_sat_str
      }
      adminUserApi.updateSat(user.id, obj).then(() => {
        user.is_saturday_available = user.input_sat_str
        user.satDisp = this.convSat(user.is_saturday_available)
        Vue.set(this.users, idx, user)
        this.switchToNonInputModeSat(user)
      }).catch(err => {
        if (err.response.data.reason === 'have_data') {
          this.canChangeSatRequest[user.id] = false
          user.input_sat = true
          Vue.set(this.users, idx, user)
        }
      })
    },
    updateWashType(idx, user) {
      const obj = {
        ['wash_type_setting']: user.input_wash_type_str
      }
      adminUserApi.updateWashType(user.id, obj).then(() => {
        user.wash_type_setting = user.input_wash_type_str
        user.washTypeDisp = this.convWashType(user.wash_type_setting)
        Vue.set(this.users, idx, user)
        this.switchToNonInputModeWashType(user)
      })
    },
    updateFoldType(idx, user) {
      const obj = {
        ['fold_type_setting']: user.input_fold_type_str
      }
      adminUserApi.updateFoldType(user.id, obj).then(() => {
        user.fold_type_setting = user.input_fold_type_str
        user.foldTypeDisp = this.convFoldType(user.fold_type_setting)
        Vue.set(this.users, idx, user)
        this.switchToNonInputModeFoldType(user)
      })
    },
    updateStatus(idx, user) {
      //退会済からのステータス変更は不可
      if (user.status == 90) {
        alert('すでに退会済のユーザーは他のステータスに変更できません')
        return
      }
      //同じステータスには変更できない
      if (user.status == user.input_status_str) {
        alert('ステータスが変更されていません')
        return
      }

      let isStopped = 0
      //月額クレジット決済の場合
      if (user.latest_payment_method_id == 1) {
        // 継続決済失敗で一時停止中のユーザー
        if (user.user_id) {
          isStopped = 1
          if (user.input_status_str == 1) {
            alert('課金エラーが発生しているためステータスの変更ができません')
            return
          } else if (user.input_status_str == 2) {
            //ここには入らない想定
          } else if (user.input_status_str == 90) {
            if (!confirm('継続課金を無効にします')) {
              return
            }
          }
        } else {
          if (user.input_status_str == 1) {
            if (!confirm('継続課金を再開します')) {
              return
            }
          } else if (user.input_status_str == 2) {
            if (!confirm('継続課金を停止します')) {
              return
            }
          } else if (user.input_status_str == 90) {
            if (!confirm('継続課金を無効にします')) {
              return
            }
          }
        }
      //請求書決済、単発の場合
      } else {
        //なにもしない
      }

      const obj = {
        ['status']: user.input_status_str,
        ['payment_method_id']: user.latest_payment_method_id,
        ['is_stopped']: isStopped
      }
      adminUserApi.updateStatus(user.id, obj).then(() => {
        user.status = user.input_status_str
        user.statusDisp = this.convStatus(user.status)
        Vue.set(this.users, idx, user)
        this.switchToNonInputModeStatus(user)
      })
    },
    updateRole(idx, user) {
      const obj = {
        ['role']: user.input_role_str
      }
      adminUserApi.updateRole(user.id, obj).then(() => {
        user.role = user.input_role_str
        user.roleDisp = this.convRole(user.role)
        Vue.set(this.users, idx, user)
        this.switchToNonInputModeRole(user)
      })
    },
    updateWeight(idx, user) {
      const obj = {
        ['weight']: user.input_weight_str
      }
      adminUserApi.updateWeight(user.id, obj).then(() => {
        user.weight = user.input_weight_str
        user.weightDisp = this.convWeight(user.weight)
        Vue.set(this.users, idx, user)
        this.switchToNonInputModeWeight(user)
      })
    },
    changePlan(user) {
      let name = 'admin-plan-edit'
      if (this.adminType === 'staff') {
        name = 'staff-plan-edit'
      }
      this.$router.push({
        name: name,
        params: {
          id: user.id,
          searchPrm: this.search,
        }
      })
    },
    downloadReceiptCsv() {
      this.csvData = []
      this.showLoadingModal = true
      adminUserApi.getReceipts().then(({ data }) => {
        if (data.length == 0) {
          alert("CSV出力対象データが取得されませんでした。")
        }
        this.receiptCsvLabels = {
          item1: { title: '受注まとめ番号' },
          item2: { title: '案件コード' },
          item3: { title: '案件名称' },
          item4: { title: '顧客注文No.' },
          item5: { title: '受注日' },
          item6: { title: '顧客名称' },
          item7: { title: '売上予定日' },
          item8: { title: '請求の登録方法' },
          item9: { title: '請求予定日' },
          item10: { title: '請求書テンプレート' },
          item11: { title: '請求書件名' },
          item12: { title: '請求書の備考欄に掲載する内容' },
          item13: { title: '請求先名称' },
          item14: { title: '入金予定日' },
          item15: { title: '入金方法' },
          item16: { title: '入金元名称' },
          item17: { title: '社内担当者' },
          item18: { title: '会計計上部門' },
          item19: { title: '社内メモ' },
          item20: { title: '摘要' },
          item21: { title: '数量' },
          item22: { title: '単位' },
          item23: { title: '単価' },
          item24: { title: '明細取引タイプ' },
          item25: { title: '会計品目タグ' },
          item26: { title: 'メモタグ1' },
          item27: { title: 'メモタグ2' },
          item28: { title: 'メモタグ3' }
        }
        Object.keys(data).forEach(e => {
          let record = data[e]
          let pushData = {}
          Object.keys(this.receiptCsvLabels).forEach(lk => {
            pushData[lk] = ''
          })
          Object.keys(record).forEach(e2 => {
            pushData[e2] = record[e2]
          })
          this.csvData.push(pushData)
          pushData = null
        });

        //ダウンロード
        this.receiptCsvJsonData = this.csvData
        setTimeout(() => {
          document.getElementById('receiptCsvDownloadbtn').click();
        }, 1000)
        this.showLoadingModal = false
      }).catch(e => {
        this.showLoadingModal = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.contents {
  width: 95%;
  margin: 20px auto 0;
  overflow-y: scroll;
  min-height: 400px;
}
.ilblk {
  display: inline-block;
}
.form-control {
  height: 24px;
  font-size: 12px;
  width: 96%;
}
.form-control-sm {
  font-size: 12px;
}
.btn {
  font-size: 16px;
  border-radius: 4px;
  background-color: #00a9ca;
  color: #ffffff;
  padding: 6px 12px;
  cursor: pointer;
}
.btn_yellow {
  background-color: #fc9d07;
  margin-left: 0.8rem;
}
.btn_sm {
  font-size: 12px;
  padding: 2px 6px;
}
.btn.bg_white {
  color: #555555;
  background-color: #ffffff;
  border: 1px solid #aaaaaa;
}
.btn.bg_danger {
  background-color: #d80a0a;
}
.fr {
  float: right;
}

td {
  white-space: normal;
}

.search-box {
  width: 1700px;
}
.list-box {
  margin: 20px 0 0 0;

  .tbl {
    table-layout: fixed;
    font-size: 14px;
    white-space: nowrap;
    width: 4000px;
    border-collapse: collapse;
  }
  th, td {
    border: 1px solid #aaa;
    padding: 2px 4px;
  }
}
</style>
