<template>
<div class="contents">
  <admin adminType="staff" :searchPrm="searchPrm"/>
</div>
</template>
<script>
import Admin from './../Admin/AdminManualPaymentsList.vue'
export default {
  name: 'staff-wash-request-list',
  props: {
    searchPrm: {
      type: Object,
      'default': () => ({
        userId: '',
        createdDtFrom: '',
        createdDtTo: '',
        filterDone: false,
        filterUndone: false,
        filterDoNothing: false,
      })
    }
  },
  components: {
    Admin
  }
}
</script>