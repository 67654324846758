<template>
<div>
  <div id="header" v-if="!hideHeader">
    <header class="contents_wrap">
      <div class="head_l">
        <a href="https://awesome-wash.com"><img src="/static/images/logo_awesomewash.svg" alt="洗濯代行サービス『オーサムウォッシュ』AWESOME WASH"></a>
      </div>
      <div class="head_r tab_none sp_none">
        <a href="#" @click="logout" class="btn_logout link_head radius4">
          ログアウト
        </a>
        <router-link :to="{name:'contact'}"
            class="btn_mail link_head radius4">
          お問い合わせ
        </router-link>
        <router-link :to="{name:'notice'}"
            class="btn_notes link_head radius4">
          集配に関する注意事項
        </router-link>
        <a href="https://awesome-wash.com" target="_blank" class="btn_home link_head radius4">
          <p>AWESOME WASH ホーム</p>
        </a>
      </div>
    </header>

    <div class="contents_box2">
      <div class="pagetitle_mp">マイページ</div>
    </div>

    <div id="acMenu" class="toggle_box pc_none">
      <dt @click="toggleMenu()">
        <p class="menu">MENU</p>
        <p class="ico_wrap"><span class="ico-menu">menu</span></p>
      </dt>
      <dd>
        <transition name="slide-toggle">
        <ul class="glnavi" v-show="displayMenu">
          <li>
            <router-link :to="{name:'wash-request'}" class="navi_btn"
                @click.native="reloadPage">
              集配依頼
            </router-link>
          </li>
          <li>
            <router-link :to="{name:'wash-request-currents'}" class="navi_btn"
                @click.native="closeMenu">
              変更/キャンセル
            </router-link>
          </li>
          <li>
            <router-link :to="{name:'wash-history'}" class="navi_btn"
                @click.native="closeMenu">
              ご利用履歴
            </router-link>
          </li>
          <li>
            <router-link :to="{name:'profile'}" class="navi_btn"
                @click.native="closeMenu">
              お客様情報
            </router-link>
          </li>
          <li>
            <router-link :to="{name:'plan'}" class="navi_btn"
                @click.native="closeMenu">
              プラン変更
            </router-link>
          </li>
          <li>
            <router-link :to="{name:'contact'}" class="navi_btn"
                @click.native="closeMenu">
              お問い合わせ
            </router-link>
          </li>
          <li>
            <router-link :to="{name:'notice'}" class="navi_btn"
                @click.native="closeMenu">
              集配に関する注意事項
            </router-link>
          </li>
          <li>
            <a href="https://awesome-wash.com"
              target="_blank" class="navi_btn">AWSOME WASH ホーム</a>
          </li>
          <li>
            <a href="#" @click="logout" class="navi_btn">ログアウト</a>
          </li>
        </ul>
        </transition>
      </dd>
    </div>
  </div>
  <div id="header" v-if="isAdminPage()">
    <header class="contents_wrap">
      <div class="head_l">
        <a href="https://awesome-wash.com"><img src="/static/images/logo_awesomewash.svg" alt="洗濯代行サービス『オーサムウォッシュ』AWESOME WASH"></a>
      </div>
      <div v-show="this.role === 100" class="head_r" style="text-align:right;">
        <router-link :to="{name:'admin-progress-management'}" class="mr12">
          進捗管理
        </router-link>
        <router-link :to="{name:'admin-users'}" class="mr12">
          ユーザー一覧
        </router-link><br>
        <router-link :to="{name:'admin-onetime-users'}" class="mr12">
          単発ユーザー一覧
        </router-link>
        <router-link :to="{name:'admin-wash-requests'}">
          集配一覧
        </router-link>
        <router-link :to="{name:'admin-business-holiday-setting'}">
          休業日管理
        </router-link><br>
        <router-link :to="{name:'admin-manual-payments'}">
          手動決済管理
        </router-link>
        <router-link :to="{name:'admin-maintenance'}">
          メンテナンス
        </router-link>
        <router-link :to="{name:'admin-operation-histories'}">
          操作履歴
        </router-link>
        <router-link :to="{name:'admin-area-infos'}">
          提供エリア
        </router-link><br>
        <router-link :to="{name:'admin-onetime-payments'}" class="mr12">
          単発料金管理
        </router-link>
        <router-link :to="{name:'admin-members'}" class="mr12">
          運営アカウント管理
        </router-link>
        <a href="#" @click="logout">
          ログアウト
        </a>
      </div>
    </header>
    <div class="contents_box2">
      <div class="pagetitle_ap">管理画面</div>
    </div>
  </div>
  <div id="header" v-if="isStaffPage()">
    <header class="contents_wrap">
      <div class="head_l">
        <a href="https://awesome-wash.com"><img src="/static/images/logo_awesomewash.svg" alt="洗濯代行サービス『オーサムウォッシュ』AWESOME WASH"></a>
      </div>
      <div v-show="this.role === 200" class="head_r" style="text-align:right;">
        <router-link v-show="this.roleType === 1" :to="{name:'staff-progress-management'}" class="mr12">
          進捗管理
        </router-link>
        <router-link v-show="[1,2].includes(this.roleType)" :to="{name:'staff-users'}" class="mr12">
          ユーザー一覧
        </router-link>
        <router-link v-show="this.roleType === 1" :to="{name:'staff-onetime-users'}" class="mr12">
          単発ユーザー一覧
        </router-link>
        <router-link :to="{name:'staff-wash-requests'}">
          集配一覧
        </router-link>
        <router-link v-show="this.roleType === 1" :to="{name:'staff-business-holiday-setting'}">
          休業日管理
        </router-link><br>
        <router-link v-show="this.roleType === 1" :to="{name:'staff-manual-payments'}">
          手動決済管理
        </router-link>
        <router-link v-show="this.roleType === 1" :to="{name:'staff-maintenance'}">
          メンテナンス
        </router-link>
        <router-link :to="{name:'staff-operation-histories'}">
          操作履歴
        </router-link>
        <router-link v-show="this.roleType === 1" :to="{name:'staff-area-infos'}">
          提供エリア
        </router-link><br>
        <router-link v-show="this.roleType === 1" :to="{name:'admin-onetime-payments'}" class="mr12">
          単発料金管理
        </router-link>
        <router-link v-show="this.roleType === 1" :to="{name:'staff-members'}" class="mr12">
          運営アカウント管理
        </router-link>
        <a href="#" @click="logout">
          ログアウト
        </a>
      </div>
    </header>
    <div class="contents_box2">
      <div class="pagetitle_ap">管理画面</div>
    </div>
  </div>
</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import routeMixin from '@/mixin/routeMixin'

export default {
  name: 'global-header',
  data() {
    return {
      displayMenu: false,
      role: 0,
      roleType: 0
    }
  },
  computed: {
    ...mapState('user', {
      username: state => state.name,
    }),
    ...mapGetters('user', [
      'isLoggedIn'
    ]),
    routeName() {
      return this.$route.name
    },
    hideHeader() {
      return !this.isLoggedIn || this.isGuestPage() || this.isAdminPage() || this.isStaffPage()
    }
  },
  mounted() {
    this.$store.dispatch('user/getMe').then(me => {
      if (this.isLoggedIn) {
        this.role = me.role;
        this.roleType = me.role_type;
      }
    })
  },
  mixins: [routeMixin],
  methods: {
    toggleMenu() {
      this.displayMenu = !this.displayMenu;
    },
    closeMenu() {
      this.displayMenu = false
    },
    reloadPage() {
      this.displayMenu = false
      window.location.reload();
    },
    logout() {
      this.$store.dispatch('user/logout')
        .then(() => {
          this.$store.dispatch('localStorage/set', {
            key: 'serverToken',
            val: null,
          })
          location.href = '/login'
        })
    }
  }
}
</script>

<style scoped>
.slide-toggle-enter-active {
  transition: height .4s ease-out;
  height: 370px;
}
.slide-toggle-enter {
  height: 0;
}
.slide-toggle-leave-active {
  transition: height .4s ease-out;
  height: 0;
}
.slide-toggle-leave {
  height: 370px;
}
</style>
