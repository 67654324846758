import axios from 'axios'
import * as utils from './api-utils'

export default {
  getSiteInfo() {
    return axios.get(utils.getApiUrl('/payments/siteinfo'))
  },
  getManualPayment(obj) {
    return axios.get(utils.getApiUrl('/payments/manualpayment'),{
      params: obj || {}
    })
  },
  updateStatus(id, data) {
    return axios.post(utils.getApiUrl(`/payments/${id}/status`), data)
  },
  updateBikou(id, data) {
    return axios.post(utils.getApiUrl(`/payments/${id}/bikou`), data)
  },
}