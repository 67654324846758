<template>
<ul class="list">
  <li v-if="!isOnetime"><p>月額制ではない単発プランもございますので、マイページの「プラン変更」から変更をご検討ください</p></li>
  <li><p>当月末までに退会手続きを行うと、当月末で退会になります</p></li>
  <li><p>登録初月は退会手続きを行うことができません</p></li>
  <li><p>退会手続きを行なうと、翌月以降の「集配依頼」は自動的にキャンセルになります</p></li>
  <li><p>万が一、ご利用個数が残っていた場合でも返金等の対応は出来かねます</p></li>
  <li><p>専用バッグにつきましては、お客様にて破棄をお願いいたします</p></li>
</ul>
</template>

<script>
export default {
  name: 'withdrawal-notice-messages',
  props: {
    planPart1: {
      type: String
    }
  },
  data() {
    return {}
  },
  computed: {
    isOnetime() {
      return this.planPart1 === 'tx'
    }
  },
}
</script>
