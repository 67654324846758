<template>
<div>
  <no-login-header></no-login-header>
  <h1 class="pagetitle">メンテナンス</h1>
  <div class="contents_855 center mb80">
    <div class="maintenance_msg mt30">
      <font-awesome-icon class="font_80 font_orange maintenance_msg_icon" icon="fa-solid fa-circle-exclamation" />
      <p class="font_45 bold" id="big">ただいま<span></span>メンテナンス中です</p><br>
    </div>
    <div v-show="date_disp === 1" class="maintenance_time">
      <p class="font_big bold mb15">【メンテナンス期間】</p>
      <p class="font_orange bold font_30 mb15" id="date">
        {{maintenance.start_dt | dtFormat('yyyy年m月d日 HH:MM')}}
        <span>〜 </span>{{maintenance.end_dt | dtFormat('yyyy年m月d日 HH:MM')}}
      </p>
    </div>
    <div v-show="date_disp === 2">
      <p class="font_big bold mb15">【メンテナンス終了予定】</p>
      <p class="font_orange bold font_30 mb15" id="date">
        {{maintenance.end_dt | dtFormat('yyyy年m月d日 HH:MM')}}
      </p>
    </div>
    <p class="maintenance_msg_sm">
      <span class="font_big line_height1">ご利用の皆様にはご迷惑をおかけし、誠に申し訳ございません。<br>
        メンテナンス終了までしばらくお待ち下さい。</span>
    </p>
  </div>
  <no-login-footer></no-login-footer>
</div>
</template>

<script>
import Vue from 'vue'
import App from './App'
import masterApi from '@/api/master'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'

library.add(faCircleExclamation)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.config.productionTip = false

export default {
  name: 'maintenance',
  data() {
    return {
      maintenance: {
				maint_flg: 0,
				start_dt: '',
				end_dt: '',
				type: '',
				content: '',
			},
      date_disp: 0,
    }
  },
  components: { App },
  mounted() {
    masterApi.maintenanceIndex().then(({ data }) => {
      this.maintenance = data
      this.dateDisp(data)
    })
	},
  methods: {
    dateDisp(mData) {
      if (mData.start_dt && mData.end_dt) {
        this.date_disp = 1;
      } else if (!mData.start_dt && mData.end_dt) {
        this.date_disp = 2;
      }
    }
  },
  template: '<App/>'
}
</script>

<style lang="scss" scoped>
.font_30{
  font-size: 30px
}
.font_45{
  font-size: 45px
}
.font_80{
  font-size: 80px
}
.maintenance_msg {
  margin-bottom: 75px;
}
.maintenance_msg_icon {
  margin-bottom: 60px;
}
.maintenance_msg_sm {
  margin-bottom: 80px;
}

// スマホレスポンシブ
@media only screen and (max-width: 800px) {
  .maintenance_msg p span {
      display: block;
  }
  .maintenance_time span {
    display: block;;
  }
  .font_45{
    font-size: 40px
  }
  .maintenance_msg {
    margin-bottom: 15px;
  }
  .maintenance_msg_icon {
    margin-bottom: 30px;
  }
  .maintenance_msg_sm {
    margin-bottom: 20px;
  }
}
</style>