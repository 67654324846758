import axios from 'axios'
import * as utils from './api-utils'

export default {
  index(obj) {
    return axios.get(utils.getApiUrl('/businessHolidays'), {
      params: obj || {}
    })
  },
  setBusinessHoliday(data) {
    return axios.post(utils.getApiUrl(`/admin/businessHolidays`), data)
  },
  cancelBusinessHoliday(id) {
    return axios.delete(utils.getApiUrl(`/admin/businessHolidays/${id}`))
  },
}
